import STTypo from 'core/shared/typo';
import { ColorOptions, TypeOptions } from 'core/shared/typo/typo.models';
import type React from 'react';
import { useTranslation } from 'react-i18next';
import { CLASS_NAME_PREFIX } from './area-day-info.events-annotations';

interface InfoTagsProps {
  tags?: string[];
}

export const InfoTags: React.FC<InfoTagsProps> = ({ tags }) => {
  const [t] = useTranslation();
  if (!tags?.length) return null;

  return (
    <div data-testid='info-tags-container' className={`${CLASS_NAME_PREFIX}__container__info-annotation__tags-container`}>
      {tags.length &&
        tags.map(tag => (
          <div
            data-testid={`info-tag-${tag.split(' ').join('-').toLowerCase()}`}
            className={`${CLASS_NAME_PREFIX}__container__info-annotation__tags-container__tag-item`}
            key={tag}>
            <STTypo type={TypeOptions.P1} color={ColorOptions.PURPLE_70} maxlength={50}>
              {t(tag, { default: tag })}
            </STTypo>
          </div>
        ))}
    </div>
  );
};
