import type { Variety } from 'entities/crop/crop.models';
import type { Dictionary } from 'config/types';
import type { Observable } from 'rxjs';
import { concat, forkJoin, timer } from 'rxjs';
import { finalize, mergeMap, toArray } from 'rxjs/operators';
import type { Product } from '../entities/product/product.models';
import type { UUID } from './utils/basic.models';

export const createReduxPage = (content: any[]) => {
  return {
    content,
    empty: !content.length,
    first: true,
    last: true,
    number: 0,
    number_of_elements: content.length,
    size: content.length,
    total_elements: content.length,
    total_pages: 1
  };
};

export const createReduxEntities = (content: any[]) => {
  return content.reduce<Dictionary<any>>((acc, c) => {
    return {
      ...acc,
      [c.id]: c
    };
  }, {});
};

export const GenericRetryStrategy = () => (attempts: Observable<any>) => {
  const suggestedTiming = [2, 5, 10, 20, 30];
  const maxRetryAttempts = suggestedTiming.length;
  const scalingDuration = 1000;

  return attempts.pipe(
    mergeMap((error, i) => {
      const retryAttempt = i + 1;
      // if maximum number of retries have been met
      // or response is a status code we don't wish to retry, throw error
      if (retryAttempt > maxRetryAttempts || error.status < 500 || error.response?.status < 500) {
        throw error;
      }
      console.log(`Tentativa ${retryAttempt}: tentando novamente em ${suggestedTiming[retryAttempt - 1]} segundos`);
      // retry after 1s, 2s, etc...

      return timer(suggestedTiming[retryAttempt - 1] * scalingDuration);
    }),
    finalize(() => console.log('Todas as tentativas realizadas!'))
  );
};

export const getObservables = <T>(observables: Observable<T>[], useAsync: boolean): Observable<T[]> => {
  return useAsync ? forkJoin(observables) : concat(...observables).pipe(toArray());
};

export const shouldReloadProducts = (products: Dictionary<Product>, companyId: UUID, loadedCompanyId?: UUID) => {
  return !Object.keys(products).length || companyId !== loadedCompanyId;
};

export const shouldReloadVarieties = (varieties: Variety[] | undefined, companyId: UUID, loadedCompanyId?: UUID): boolean => {
  if (!varieties) return true;

  return !varieties.length || companyId !== loadedCompanyId;
};

export const is5xxStatus = (status: string) => {
  return /^5\d{2}$/.test(status);
};
