import type { Dictionary } from 'config/types';
import type { UUID } from '../../../core/utils/basic.models';

export interface BorerRiskParameter {
  id: UUID;
  name: string;
  description?: string;
  custom?: boolean;
  weight: number;
  type?: string;
  order?: number;
  levels?: Dictionary<string>;
}

export interface UpdateParametersRequest {
  pid: UUID;
  sid: UUID;
  pr: ConfigurationParameterDTO[];
}

export enum ConfigurationParameterType {
  STRIP = 'strip',
  BINARY = 'binary',
  TERNARY = 'dict',
  LIST = 'list',
  DATE = 'date',
  CUSTOMIZED = 'dict'
}

export interface ConfigurationParameterDTO {
  id: UUID;
  name: string;
  description?: string;
  used: boolean;
  custom?: boolean;
  type: ConfigurationParameterType;
  weight: number;
  levels?: Dictionary<string>;
}

export interface ConfigurationParameter extends ConfigurationParameterDTO {
  visible: boolean;
  initialValue: string | undefined;
  endValue: string | undefined;
  yesOrNot: boolean | undefined;
}

export interface ParametersDataDTO {
  property_id: UUID;
  season_id: UUID;
  areas_pr: Dictionary<string>[];
}

export interface ParametersDataPage {
  content: ParametersDataDTO;
  cursor: string | null;
  is_last: boolean;
}

export type RiskDTO = Dictionary<Dictionary<number | null> | string>;

export interface pageRiskDTO {
  content: RiskDTO;
  size: number;
  cursor: null | string;
  is_last: boolean;
}

export interface LimitValues {
  areas: Dictionary<LimitValue>;
  regions: Dictionary<LimitValue>;
}

interface LimitValue {
  min: number;
  max: number;
}

export interface BorerRiskExtraColumn {
  columnName: string;
  columnData: Dictionary<string | number>;
}

export enum BorerRiskParameterId {
  INFESTATION_HISTORY = 'cd3c9c92-470b-440e-88e2-482c271b9410',
  INFESTATION_SEASON = 'ad350390-3a86-40aa-a037-cf363be4464c',
  VINASSE_AREA = '86171f70-47de-4f55-84c7-af0dbc5031d6',
  FLIGHT_RESTRICTION = 'e3fb3fce-87d9-41e9-9aba-17c442694ff0',
  IRRIGATED_AREA = '1f5347a8-d8fc-412c-92f3-ec183361f612',
  SUSCEPTIBLE_VARIETY = '97fb8e92-19c0-4a9a-8d98-e7506316c0f5',
  SUSCEPTIBLE_VARIETY_NUMERICAL = 'a843eec6-e8f0-45f2-b9df-d7100e7cde98',
  PRODUCTIVITY_ESTIMATE = '3bcec9f3-d8dd-49b5-b585-8f554657aed6',
  CANE_CUT = '5e052a22-066e-4bb8-a02c-915e2f6d314f',
  APP_PROXIMITY = '266ec030-61db-4382-952b-c186d2aa7238',
  HOST_CULTURES_PROXIMITY = '62c8f097-bbaf-4536-9a94-3c488bbed40b',
  PLANTING_DATE = '2c8e2c03-19ba-4740-90d3-e9771e8af6db',
  LAST_CUT_DATE = '870bf61a-8765-41a5-a528-9b468a9ce8bd',
  ESTIMATED_CUT_DATE = 'c7c561c0-e057-46c9-a115-30b0ec458445',
  NEXT_ACTIVITY_DATE = '5bc55ef5-0d57-4a7a-a218-9e78142c1076',
  SUGARCANE_STATUS = 'a724ba49-a3f6-456e-9703-b704cc757272'
}

export enum BorerRiskError {
  AREA_ERROR = 'area_error'
}

export enum ViewMode {
  REGION = 'REGION',
  AREA = 'AREA'
}

export enum BorerRiskValue {
  LOW = 1,
  MEDIUM = 2,
  HIGH = 3
}

export enum BorerRiskState {
  CONFIGURATION = 'CONFIGURATION',
  DOWNLOAD = 'DOWNLOAD',
  VISUALIZATION = 'VISUALIZATION'
}

export enum BorerRiskUploadState {
  SELECT = 'SELECT',
  CHECK = 'CHECK',
  VERIFIED = 'VERIFIED',
  ERROR = 'ERROR'
}

export enum TableType {
  PLANT = 'plant',
  RATOON = 'ratoon'
}

export interface InfestationTablesContextProps {
  plantTable: (string | number)[][];
  ratoonTable: (string | number)[][];
  updateTable?: (type: TableType, data: (string | number)[][]) => void;
}
