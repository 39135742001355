import { Tooltip } from 'antd';
import classNames from 'classnames';
import type React from 'react';

import { parseMaxlengthString } from './typo.helpers';
import type { FontFamily, STTypoProps } from './typo.models';
import { ColorOptions, TypeOptions } from './typo.models';
import './typo.styles.less';

const STTypo = <T extends FontFamily>({
  type = TypeOptions.P1,
  boldness = false,
  lightWeight = false,
  italic = false,
  color = 'inherit',
  nowrap = false,
  uppercase = false,
  capitalize = false,
  lowercase = false,
  tagged = false,
  forcewrap = false,
  borderedFont = false,
  shouldHideTooltip = false,
  align = 'inherit',
  maxlength,
  children,
  lineHeight,
  fontFamily,
  fontWeight,
  horizontalPadding = false,
  ...props
}: React.PropsWithChildren<STTypoProps<T>>): JSX.Element => {
  const textIsBig = !!(maxlength && typeof children === 'string' && children.length > maxlength + 3);
  const resultNode = parseMaxlengthString(children, maxlength!, textIsBig);

  const typoClasses = classNames({
    'st-typo': true,
    'st-typo--uppercase': uppercase,
    'st-typo--capitalize': capitalize,
    'st-typo--lowercase': lowercase,
    'st-typo--tagged': tagged,
    'st-typo--boldness': boldness,
    'st-typo--light-weight': lightWeight,
    'st-typo--italic': italic,
    'st-typo--primary': color === ColorOptions.PRIMARY.toString(),
    'st-typo--secondary': color === ColorOptions.SECONDARY.toString(),
    'st-typo--nowrap': nowrap,
    'st-typo--forcewrap': forcewrap,
    'st-typo--bordered-font': borderedFont,
    'st-typo--horizontal-padding': horizontalPadding,
    [type]: true
  });

  const fontFamilyWithFallback = fontFamily ? `${fontFamily}, sans-serif` : undefined;
  const style: React.HTMLAttributes<HTMLSpanElement>['style'] = {
    color,
    textAlign: align,
    lineHeight,
    fontFamily: fontFamilyWithFallback,
    fontWeight,
    overflowWrap: forcewrap ? 'break-word' : undefined
  };

  return (
    <span className={typoClasses} style={style} {...props}>
      {textIsBig && !shouldHideTooltip ? <Tooltip title={children}>{resultNode}</Tooltip> : resultNode}
    </span>
  );
};

export default STTypo;
