import { CropTypesEnum } from 'core/core.models';
import type { Crop } from 'core/utils/basic.models';
import { slugify } from 'core/utils/strings';
import _ from 'lodash';

export function isAnyWheatCrop(crop: Crop | undefined | null): boolean {
  if (!crop?.wk_slug) {
    return false;
  }
  const cropType = _.snakeCase(crop.wk_slug.toLocaleLowerCase());

  /**
   * This casting was necessary to work with the generic Crop model
   * which doesn't know the crop names, and the CropTypesEnum internally
   */
  return [CropTypesEnum.WINTER_WHEAT, CropTypesEnum.HARD_WHEAT, CropTypesEnum.WHEAT].includes(cropType as CropTypesEnum);
}

export function isAnyBarleyCrop(crop: Crop | undefined | null, shouldIncludeSpringBarley = false): boolean {
  if (!crop?.wk_slug) {
    return false;
  }
  const cropType = _.snakeCase(crop.wk_slug.toLocaleLowerCase());
  /**
   * This casting was necessary to work with the generic Crop model
   * which doesn't know the crop names, and the CropTypesEnum internally
   */
  return _.compact<CropTypesEnum>([
    CropTypesEnum.WINTER_BARLEY,
    shouldIncludeSpringBarley && CropTypesEnum.SPRING_BARLEY,
    CropTypesEnum.BARLEY
  ]).includes(cropType as CropTypesEnum);
}

export const isAnyCerealCrop = (crop: Crop | undefined | null, shouldIncludeSpringBarley = false): boolean =>
  isAnyBarleyCrop(crop, shouldIncludeSpringBarley) || isAnyWheatCrop(crop);

export function isGrapeCrop(crop: Crop | undefined | null): boolean {
  if (!crop?.wk_slug) {
    return false;
  }
  const cropType = _.snakeCase(crop.wk_slug.toLocaleLowerCase());
  return cropType === CropTypesEnum.GRAPE.toString();
}

export function isPotatoCrop(crop: Crop | undefined | null): boolean {
  if (!crop?.wk_slug) {
    return false;
  }
  const cropType = slugify(crop.wk_slug);
  return cropType === CropTypesEnum.POTATO.toString();
}
