import type { Crop } from 'core/utils/basic.models';
import { isAnyBarleyCrop, isAnyWheatCrop, isGrapeCrop, isPotatoCrop } from 'entities/crop/crop.domain';
import CropBarley from 'st-protector-icons/Major/CropBarley';
import CropGrape from 'st-protector-icons/Major/CropGrape';
import CropPotato from 'st-protector-icons/Major/CropPotato';
import CropWheat from 'st-protector-icons/Major/CropWheat';
import { ColorOptions } from '../typo/typo.models';
import { getSFCropIconName } from './crop-icon.helpers';

interface CropIconUnit {
  cropInfo: Crop | undefined;
  customIcon: React.ReactElement | undefined;
  background: boolean | undefined;
}

const CropIconUnit = ({ cropInfo, customIcon, background }: CropIconUnit) => {
  if (cropInfo && !customIcon) {
    const color = background ? ColorOptions.WHITE : undefined;

    switch (true) {
      case isAnyWheatCrop(cropInfo):
        return <CropWheat height={14} color={color} />;

      case isPotatoCrop(cropInfo):
        return <CropPotato height={14} fill={color} />;

      case isAnyBarleyCrop(cropInfo, true):
        return <CropBarley height={14} color={color} />;

      case isGrapeCrop(cropInfo):
        return <CropGrape height={14} color={color} />;

      default:
    }
  }

  const cropIcon = getSFCropIconName(cropInfo);

  return <i data-testid='crop-icon' className={`sf sf-${cropIcon}`} />;
};

export default CropIconUnit;
