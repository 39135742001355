import type { UUID } from 'core/utils/basic.models';
import { getNameByCurrentLanguage } from 'core/utils/functions';
import { getPhenomena } from 'entities/phenomenon/phenomenon.service';
import { getMethodologiesByIds, getMethodologyIdsForPropertySeasonAndAreas } from 'entities/property/property.service';
import type { Region } from 'entities/region/region.models';
import type { StaticPoint } from 'entities/static-points/static-points.models';
import _ from 'lodash';
import moment from 'moment';
import type { TaskSprayPayload } from 'pages/tasks/task-create/tasks-create.models';
import type { Phenomenon } from '../../entities/phenomenon/phenomenon.models';
import type { IndicatorAverage } from './control-strategy-card-list/control-strategy-card-region.model';
import type { ControlStrategySuggestion, ControlStrategySuggestionCard, SelectedSuggestionsPerStrategy } from './control-strategy-model';
import { ControlStrategyOptionsEnum } from './control-strategy-model';
import { getStaticPointTemplates } from './control-strategy.service';

interface ControlStrategyFrameColor {
  colorFill: string;
  colorStroke: string;
  colorBorder: string;
  colorBorderStroke: string;
}

export const controlStrategyFrameColors: ControlStrategyFrameColor[] = [
  {
    colorFill: '#F6F2FE',
    colorStroke: '#E7DDFC',
    colorBorder: '#9664F0',
    colorBorderStroke: '#9664F0'
  },
  {
    colorFill: '#FFF3DD',
    colorStroke: '#FFE4AE',
    colorBorder: '#744A0B',
    colorBorderStroke: '#744A0B'
  },
  {
    colorFill: '#E0F9F7',
    colorStroke: '#A9EFE8',
    colorBorder: '#2B9C92',
    colorBorderStroke: '#2B9C92'
  },
  {
    colorFill: '#FFF1ED',
    colorStroke: '#FFDACE',
    colorBorder: '#FF785A',
    colorBorderStroke: '#FF785A'
  },
  {
    colorFill: '#EAF6FF',
    colorStroke: '#C6E6FF',
    colorBorder: '#82CFFF',
    colorBorderStroke: '#82CFFF'
  },
  {
    colorFill: '#FFF1F3',
    colorStroke: '#FFD8DF',
    colorBorder: '#E85D78',
    colorBorderStroke: '#E85D78'
  }
];

export const groupSuggestionsPerZone = (
  suggestionsPerZone: Record<string, Record<string, ControlStrategySuggestionCard>>,
  suggestion: ControlStrategySuggestion
): Record<string, Record<string, ControlStrategySuggestionCard>> => {
  const regionDayKey = `${suggestion.group_id}_${suggestion.events_day}`;
  const isFirstInput = !suggestionsPerZone[regionDayKey]?.[suggestion.control_strategy_id];

  const targets = isFirstInput ? [] : suggestionsPerZone[regionDayKey][suggestion.control_strategy_id].targets;
  const previousSelectedTargets = isFirstInput ? [] : suggestionsPerZone[regionDayKey][suggestion.control_strategy_id].selectedTargets;
  const suggestions = isFirstInput ? [] : suggestionsPerZone[regionDayKey][suggestion.control_strategy_id].suggestions_id;
  const highlighted = isFirstInput ? false : suggestionsPerZone[regionDayKey][suggestion.control_strategy_id].highlighted;

  const selected = isFirstInput ? false : suggestionsPerZone[regionDayKey][suggestion.control_strategy_id].selected;
  const selectedTargets = suggestion.selected ? [suggestion.target_id] : [];
  return {
    ...suggestionsPerZone,
    [regionDayKey]: {
      ...suggestionsPerZone[regionDayKey],
      [suggestion.control_strategy_id]: {
        suggestions_id: [...suggestions, suggestion.id],
        targets: suggestion.forced ? targets : [...new Set([...targets, suggestion.target_id])],
        selectedTargets: [...new Set([...previousSelectedTargets, ...selectedTargets])],
        valid_start_date: suggestion.valid_start_date,
        valid_end_date: suggestion.valid_end_date,
        products: [],
        group_id: suggestion.group_id,
        control_strategy_id: suggestion.control_strategy_id,
        acknowledged: suggestion.acknowledged,
        created_at: suggestion.created_at,
        updated_at: suggestion.updated_at,
        updated_by: suggestion.updated_by,
        deleted_at: suggestion.deleted_at,
        selected: selected || suggestion.selected,
        events_day: suggestion.events_day,
        highlighted: highlighted || !!suggestion.highlighted
      }
    }
  };
};

const mergeIndicatorsByPhenomenon = (
  indicatorsByPhenomenon: Record<string, string[]>,
  phenomenonIds: string[],
  indicatorIds: string[]
): Record<string, string[]> => {
  return {
    ...indicatorsByPhenomenon,
    ...phenomenonIds.reduce((phenomenaAcc: Record<string, string[]>, phenomenonId: string) => {
      let idsToAppend = indicatorIds;
      if (phenomenonId in phenomenaAcc) {
        idsToAppend = [...idsToAppend, ...phenomenaAcc[phenomenonId]];
      }
      if (phenomenonId in indicatorsByPhenomenon) {
        idsToAppend = [...idsToAppend, ...indicatorsByPhenomenon[phenomenonId]];
      }
      return {
        ...phenomenaAcc,
        [phenomenonId]: [...new Set(idsToAppend)]
      };
    }, {})
  };
};

const getPhenomenonIdsByStaticPoint = (propertyId: UUID): Promise<Record<string, string[]>> => {
  return getStaticPointTemplates(propertyId)
    .toPromise()
    .then(response => {
      const templates: StaticPoint[] = response.data;
      return templates.reduce((indicatorsByPhenomenon, template) => {
        const indicatorIds = template.static_point.analytic_context?.custom_indicator_dtos.map(indicator => indicator.indicator_id) || [];
        const phenomenonIds =
          template.static_point.inspection_layout?.ordered_inspection_groups.flatMap(group =>
            group.ordered_categories.flatMap(category => category.ordered_phenomenons.map(phenomenon => phenomenon.id))
          ) || [];
        return mergeIndicatorsByPhenomenon(indicatorsByPhenomenon, phenomenonIds, indicatorIds);
      }, {});
    });
};

const getPhenomenonIdsByMethodology = (propertyId: UUID, seasonIds: UUID[], areaIds: UUID[]): Promise<Record<string, string[]>> => {
  return getMethodologyIdsForPropertySeasonAndAreas(propertyId, seasonIds, areaIds)
    .toPromise()
    .then(methodologyIds => {
      return getMethodologiesByIds(methodologyIds)
        .toPromise()
        .then(methodologies => {
          return methodologies.reduce((indicatorsByPhenomenon, methodology) => {
            const indicatorIds = methodology.analytic_context_dto.custom_indicator_dtos.map(indicator => indicator.indicator_id);
            const phenomenonIds = methodology.inspection_layout.ordered_inspection_groups.flatMap(group =>
              group.ordered_categories.flatMap(category => category.ordered_phenomenons.map(phenomenon => phenomenon.component_id))
            );
            return mergeIndicatorsByPhenomenon(indicatorsByPhenomenon, phenomenonIds, indicatorIds);
          }, {});
        });
    });
};

export const getControlStrategyPhenomena = (
  companyId: UUID,
  propertyId: UUID,
  seasonIds: UUID[],
  areaIds: UUID[]
): Promise<Phenomenon[]> => {
  return Promise.all([
    getPhenomena(companyId).then(response => {
      return response;
    }),
    getPhenomenonIdsByMethodology(propertyId, seasonIds, areaIds),
    getPhenomenonIdsByStaticPoint(propertyId)
  ]).then(([phenomena, methodologyIndicatorsByPhenomenon, staticPointIndicatorsByPhenomenon]): Phenomenon[] => {
    const indicatorsByPhenomenon = Object.entries(staticPointIndicatorsByPhenomenon).reduce((acc, [phenomenonId, indicatorIds]) => {
      const accIndicators = acc[phenomenonId] ? [...acc[phenomenonId]] : [];
      const newIndicatorIds = [...new Set([...indicatorIds, ...accIndicators])];
      return {
        ...acc,
        [phenomenonId]: newIndicatorIds
      };
    }, methodologyIndicatorsByPhenomenon);
    const allPhenomena = phenomena.reduce((acc: Phenomenon[], phenomenon) => {
      if (phenomenon.id in indicatorsByPhenomenon) {
        const indicatorIds = indicatorsByPhenomenon[phenomenon.id];
        const filteredIndicators = phenomenon.indicators_dto
          .filter(indicator => indicatorIds.includes(indicator.id))
          .sort((indicatorA, indicatorB) =>
            getNameByCurrentLanguage(indicatorA.name).localeCompare(getNameByCurrentLanguage(indicatorB.name))
          );
        return [
          ...acc,
          {
            ...phenomenon,
            indicators: filteredIndicators.map(indicator => indicator.id),
            indicators_dto: filteredIndicators
          }
        ];
      }
      return acc;
    }, []);

    return [...allPhenomena].sort((indicatorA, indicatorB) =>
      getNameByCurrentLanguage(indicatorA.name).localeCompare(getNameByCurrentLanguage(indicatorB.name))
    );
  });
};

export const wasSuggestionSelected = (
  suggestion: ControlStrategySuggestionCard,
  selectedSuggestions: Record<string, SelectedSuggestionsPerStrategy>
): boolean => {
  const regionKey = `${suggestion.group_id}_${suggestion.events_day}`;
  return (
    !!selectedSuggestions?.[regionKey] &&
    !!selectedSuggestions[regionKey][suggestion.control_strategy_id] &&
    (!!selectedSuggestions[regionKey][suggestion.control_strategy_id].suggestionsIds.length ||
      !!selectedSuggestions[regionKey][suggestion.control_strategy_id].forcedTargetsIds.length)
  );
};

const colorDamageMap = {
  '#696F88': -1,
  '#19A04B': 0,
  '#F0C355': 1,
  '#EB4B4B': 2
};

export const sortResultsByDamage = (indicatorA: IndicatorAverage, indicatorB: IndicatorAverage): number => {
  return colorDamageMap[indicatorB.color] - colorDamageMap[indicatorA.color];
};

export const isAfterToday = (date: moment.Moment | null): boolean => {
  return !!date && date > moment();
};

export const sortAveragesBySeverity = (indicatorA: IndicatorAverage[], indicatorB: IndicatorAverage[]): number => {
  return Math.max(...indicatorB.map(o => colorDamageMap[o.color])) - Math.max(...indicatorA.map(o => colorDamageMap[o.color]));
};

export const getForceSugestionOption = (
  hideForceSuggestion: boolean,
  controlStrategyForceSuggestion: ControlStrategyOptionsEnum
): ControlStrategyOptionsEnum => {
  if (!hideForceSuggestion) {
    return ControlStrategyOptionsEnum.ALWAYS_ASK;
  }
  return controlStrategyForceSuggestion;
};

export const getForcedTargets = (region: Region, suggestion: ControlStrategySuggestionCard): string[] => {
  return (region.children as string[]).filter(areaId => !suggestion.targets.includes(areaId));
};

export const getSuggestionIds = (
  selectedSuggestions: Record<string, SelectedSuggestionsPerStrategy>,
  suggestion: ControlStrategySuggestionCard
): string[] => {
  const regionEventDay = selectedSuggestions[`${suggestion.group_id}_${suggestion.events_day}`];
  if (!!regionEventDay && !!regionEventDay[suggestion.control_strategy_id]) {
    return [...regionEventDay[suggestion.control_strategy_id].suggestionsIds, ...suggestion.suggestions_id];
  }
  return suggestion.suggestions_id;
};

const groupSpraysByProductUsage = (spray: TaskSprayPayload) => {
  return [...spray.spray_definition.product_usages]
    .sort((productA, productB) => productA.product_id.localeCompare(productB.product_id))
    .reduce((producutUsageKey, productUsage) => {
      return `${producutUsageKey}-${productUsage.concentration}_${productUsage.product_id}_${spray.start_at.substring(0, 10)}`;
    }, '');
};

const mapGroupedSprays = (sprays: TaskSprayPayload[]) => {
  return {
    ...sprays[0],
    spray_definition: {
      ...sprays[0].spray_definition,
      sprayed_areas: sprays.flatMap(s => s.spray_definition.sprayed_areas)
    }
  };
};
export const mergeSpraysWithSameProductUsage = (sprays: TaskSprayPayload[] | undefined): TaskSprayPayload[] => {
  if (!sprays) {
    return [];
  }
  const spraysGrouped = Object.values(_.groupBy(sprays, groupSpraysByProductUsage));
  return spraysGrouped.map(mapGroupedSprays);
};
