import { ColorOptions } from 'core/shared/typo/typo.models';
import styled from 'styled-components';

export const StyledParameterContainer = styled.div`
  display: flex;
  flex-direction: column;
  row-column: 10px;
`;

export const StyledBorerRiskContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const StyledClusteringRulesContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

export const StyledClusteringRulesTitle = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: 10px;
  margin: 10px 0px;
`;

export const StyledAcceptanceLevelContainer = styled.div`
  background-color: ${ColorOptions.BLUE_10};
  border: 1px solid ${ColorOptions.BLUE_20};
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  padding: 15px;
`;

export const StyledAcceptanceLevel = styled.div`
  width: 100$;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;
`;

export const StyledNumericInput = styled.div<{ addAfter?: boolean }>`
  width: 40px;
  margin-right: ${({ addAfter }) => (addAfter ? '10px' : '0px')};
`;

export const StyledTip = styled.div`
  margin-top: 10px;
  padding: 15px;
  border-radius: 4px;
  background: ${ColorOptions.BLUE_20};
  color: ${ColorOptions.BLUE_60};
`;

export const StyledListParameters = styled.div`
  position: relative;
`;

export const StyledConfirmGroupsFooter = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
`;
