import { ColorOptions } from '../shared/typo/typo.models';

export const colorPalette = [
  ColorOptions.BLUE_60,
  ColorOptions.GREEN_40,
  ColorOptions.PURPLE_70,
  ColorOptions.TEAL_30,
  ColorOptions.ORANGE_40,
  ColorOptions.PURPLE_50,
  ColorOptions.PINK_30,
  ColorOptions.BLUE_70,
  ColorOptions.YELLOW_50,
  ColorOptions.TEAL_50,
  ColorOptions.YELLOW_30,
  ColorOptions.BLUE_20,
  ColorOptions.GREEN_70,
  ColorOptions.PURPLE_90,
  ColorOptions.TEAL_20,
  ColorOptions.ORANGE_60,
  ColorOptions.PURPLE_30,
  ColorOptions.PINK_60,
  ColorOptions.BLUE_90,
  ColorOptions.YELLOW_80,
  ColorOptions.TEAL_70,
  ColorOptions.YELLOW_60
] as const;

export const teamColorPalette = [
  ColorOptions.YELLOW_50,
  ColorOptions.PURPLE_70,
  ColorOptions.GREEN_40,
  ColorOptions.BLUE_60,
  ColorOptions.TEAL_30,
  ColorOptions.PINK_30,
  ColorOptions.ORANGE_40,
  ColorOptions.TEAL_50,
  ColorOptions.PURPLE_50,
  ColorOptions.YELLOW_30,
  ColorOptions.BLUE_70,
  ColorOptions.GREEN_70,
  ColorOptions.RED_70,
  ColorOptions.BLUE_20,
  ColorOptions.ORANGE_60,
  ColorOptions.PURPLE_90,
  ColorOptions.TEAL_20,
  ColorOptions.YELLOW_80,
  ColorOptions.PURPLE_30,
  ColorOptions.PINK_60,
  ColorOptions.YELLOW_60,
  ColorOptions.TEAL_70
] as const;

export const getColor = (indexColor: number, colors = colorPalette): string => {
  return colors[indexColor % colorPalette.length];
};
