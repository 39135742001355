import { PlusOutlined } from '@ant-design/icons';
import STTypo from 'core/shared/typo';
import { ColorOptions } from 'core/shared/typo/typo.models';
import type React from 'react';
import { useTranslation } from 'react-i18next';
import { StyledButtonWrapper, StyledContainer, StyledGroupContainer, StyledHeader } from './group-input-list.styles';

interface GroupInputListProps {
  onAdd: () => void;
  containerWidth?: string;
}

const GroupInputList: React.FC<GroupInputListProps> = ({ onAdd, containerWidth, children }) => {
  const [t] = useTranslation();

  return (
    <StyledGroupContainer data-testid='group-input-list-component' containerWidth={containerWidth}>
      {children}
      <StyledContainer data-testid='add-group'>
        <StyledHeader>
          <STTypo color={ColorOptions.BLUE_60} fontWeight={500}>
            {t('general.add_group')}
          </STTypo>
          <StyledButtonWrapper>
            <PlusOutlined data-testid='add-group-btn' size={18} color={ColorOptions.BLUE_60} onClick={onAdd} />
          </StyledButtonWrapper>
        </StyledHeader>
      </StyledContainer>
    </StyledGroupContainer>
  );
};

export default GroupInputList;
