import { selectSelectedCompany } from 'core/core.reducer';
import { selectAllProperties } from 'entities/property/property.reducer';
import type { AppState } from 'redux/app-state';
import { createSelector } from 'reselect';

export const filterProperties = createSelector(
  (state: AppState) => selectAllProperties(state.entities.property),
  (state: AppState) => selectSelectedCompany(state.uiState.global),
  (properties, selectedCompany) => properties.filter(property => property.company_id === selectedCompany)
);

export const getPropertiesIndexedNameByCurrentCompany = createSelector(
  (state: AppState) => selectAllProperties(state.entities.property),
  (state: AppState) => selectSelectedCompany(state.uiState.global),
  (properties, selectedCompany) =>
    properties.filter(property => property.company_id === selectedCompany).map(p => ({ id: p.id, name: p.name }))
);
