import { CoreActionsTypes } from 'core/core.actions';
import { LoadProperties, LoadPropertiesAreaInfo, LoadPropertiesFailure } from 'entities/property/property.actions';
import { selectAllProperties } from 'entities/property/property.reducer';
import { ofType } from 'redux-observable';
import type { AppState } from 'redux/app-state';
import type { Observable } from 'rxjs';
import { concat, of } from 'rxjs';
import { exhaustMap, map, withLatestFrom } from 'rxjs/operators';
import { FakeLoadAll } from './services/analytics/widgets/widgets.actions';

export const requestLoadCompanyData = (action$, state$) =>
  action$.pipe(
    ofType(CoreActionsTypes.SELECT_COMPANY),
    withLatestFrom(
      state$.pipe(
        map((state: AppState) => {
          return state.uiState.global.selectedCompany;
        })
      ),
      state$.pipe(map((state: AppState) => (state$.entities ? selectAllProperties(state$.entities.property) : [])))
    ),
    exhaustMap(([, companyId, allProperties]): Observable<any> => {
      sessionStorage.setItem('company_id', companyId);
      if (!companyId) {
        return of(LoadPropertiesFailure("There isn't a selected Company"));
      }
      if (state$.entities && allProperties.length > 0) {
        return of(LoadPropertiesFailure('Properties already looaded'));
      }
      return concat([
        LoadProperties({
          pageable: {
            page: 0,
            size: 10
          },
          query: {
            params: new Map([['company_id', companyId]])
          }
        }),
        LoadPropertiesAreaInfo({
          pageable: {
            page: 0,
            size: 10
          },
          query: {
            params: new Map([['company_id', companyId]])
          }
        }),
        FakeLoadAll()
      ]);
    })
  );
