import { C_LEVEL_REPORT } from 'authentication/contants';
import { getIsLogged } from 'core/services/auth/auth.selectors';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import useFirstLoad from './events/useFirstLoad.hook';
import useIdentifyUser from './events/useIdentifyUser.hook';
import useInitMonitoring from './monitoring/useInitMonitoring.hook';
import useTags from './monitoring/useTags.hook';
import useInitPlatform from './platform/useInitPlatform.hook';
import useAntdLocale from './third-party/useAntdLocale.hook';
import useUpdateMomentLocale from './third-party/useUpdateMomentLocale.hook';

const useInfra = () => {
  useInitPlatform();

  useIdentifyUser();
  useFirstLoad();

  useInitMonitoring();
  useTags();

  useUpdateMomentLocale();
  const locale = useAntdLocale();

  const isLogged = useSelector(getIsLogged);

  const { pathname } = useLocation();

  if (pathname.includes(C_LEVEL_REPORT) && !isLogged) {
    sessionStorage.setItem(C_LEVEL_REPORT, pathname);
  }

  return useMemo(
    () => ({
      locale
    }),
    [locale]
  );
};

export default useInfra;
