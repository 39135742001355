import { TaskUiActionTypes } from './task.actions';
import type { TaskUiState } from './ui.models';

const initialState: TaskUiState = {
  selectedAreaFromTimeline: undefined
};

export default (state = initialState, action): TaskUiState => {
  switch (action.type) {
    case TaskUiActionTypes.SELECT_AREA_FROM_TIMELINE:
      return {
        ...state,
        selectedAreaFromTimeline: action.payload
      };
    default:
      return state;
  }
};

export const getSelectedAreaFromTimeline = (state: TaskUiState) => state.selectedAreaFromTimeline;
