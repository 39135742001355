import { getSelectedSeasons } from 'core/core.reducer';
import { type Selector } from 'core/core.selectors';
import type { UUID } from 'core/utils/basic.models';
import type { I18nMap } from 'core/utils/language.models';
import type { Variety } from 'entities/crop/crop.models';
import { groupBy, isEmpty } from 'lodash';
import type { Dictionary } from 'config/types';
import type { AppState } from 'redux/app-state';
import { createSelector } from 'reselect';
import type { AreaVariable, AreaVariableMassiveStages, Region, SeasonArea } from './region.models';
import { RegionType } from './region.models';

export type GetAreaVariablesPerSeasonAreaReturn = Record<string, Record<string, AreaVariable>>;

export interface AreaVariableNameAndKey {
  key: string;
  name: I18nMap;
}

export const getAreaVariableMassiveStage: Selector<AreaVariableMassiveStages | undefined> = state =>
  state.entities.region.areaVariableLoadingStage;

export const getLeafRegions: Selector<Region[]> = state => {
  const regionsMap = state.entities.region.entities;
  return Object.values(regionsMap).filter(region => !region.children);
};

export const getRegions: Selector<Dictionary<Region>> = state => state.entities.region.entities;

export const getParentRegion: Selector<Region | undefined> = state => {
  const regionsMap = state.entities.region.entities;
  return Object.values(regionsMap).find(region => !region.parent_id);
};

export const getRecursiveRegionsAndSeasonArea = (state: AppState) => {
  const flags = state.uiState.global.systemFlags;

  const recursiveAreas = state.entities.region.recursiveRegionsAndSeasonAreas;

  const recursiveFields = state.entities.region.recursiveRegionsAndSeasonFields;

  return flags?.P40_34492_REGION_EPIC_SEASON_FIELDS ? recursiveFields : recursiveAreas;
};

export const getSeasonAreaMap: Selector<Record<string, SeasonArea>> = state => {
  const { seasonAreas } = state.entities.region;
  let seasonAreaMap: Record<string, SeasonArea> = {};
  seasonAreas?.forEach(element => {
    seasonAreaMap = { ...seasonAreaMap, [element.seasonAreaId]: element };
  });
  return { ...seasonAreaMap };
};

export const getAreaVariablesPerSeasonArea: Selector<GetAreaVariablesPerSeasonAreaReturn | undefined> = state => {
  const mapSeasonAreaIdToAreaVariables: GetAreaVariablesPerSeasonAreaReturn = {};

  const areaVariablesPerSeason = state.entities.region.areaVariablesPerSeason;

  if (!areaVariablesPerSeason) return;

  Object.values(areaVariablesPerSeason).forEach(areaVariables => {
    areaVariables.forEach(areaVariable => {
      if (areaVariable?.season_area_id && !mapSeasonAreaIdToAreaVariables?.[areaVariable.season_area_id]) {
        mapSeasonAreaIdToAreaVariables[areaVariable.season_area_id] = {};
      }
      mapSeasonAreaIdToAreaVariables[areaVariable.season_area_id][areaVariable.key] = areaVariable;
    });
  });
  return mapSeasonAreaIdToAreaVariables;
};

export const getAvailableAreaVariableNamesAndKeys: Selector<AreaVariableNameAndKey[]> = state => {
  let areaVariablesNamesAndKeys: AreaVariableNameAndKey[] = [];
  const { areaVariablesPerSeason } = state.entities.region;
  Object.values(areaVariablesPerSeason).forEach(areaVariables => {
    areaVariables.forEach(areaVariable => {
      const { key, name } = areaVariable;
      if (!areaVariablesNamesAndKeys.find(el => el.key === key)) {
        areaVariablesNamesAndKeys = [...areaVariablesNamesAndKeys, { name, key }];
      }
    });
  });
  return areaVariablesNamesAndKeys;
};

export const isTableInfoLoading: Selector<boolean> = state => state.entities.region.isTableInfoLoading;

export const getAreaVariables: Selector<AreaVariable[]> = state => {
  return Object.values(state.entities.region.areaVariablesPerSeason).reduce((acc, current) => [...acc, ...current], []);
};

export const isRegionsLoaded: Selector<boolean> = state => state.entities.region.isRegionsLoaded;

/** @deprecated use getSeasonFieldsCropsVarieties instead */
export const getSeasonAreasCropsVarieties: Selector<Record<string, Variety[]>> = state => state.entities.region.seasonAreasCropsVarieties;

export const getSeasonFieldsCropsVarieties: Selector<Record<string, Variety[]> | null> = state =>
  state.entities.region.seasonFieldsCropsVarieties;

export const getSeverityIndicatorsSelected: Selector<UUID | undefined> = state => state.entities.region.severityIndicatorSelected;

export const getHasPressureIndicator: Selector<boolean | undefined> = state => state.entities.region.hasPressureIndicator;

const regionsToDictionary = (regions: Region[]): Dictionary<Region> => {
  const regionsMap: Dictionary<Region> = {};
  regions.forEach(region => {
    regionsMap[region.id] = region;
  });
  return regionsMap;
};

export const safelySelectRegions = createSelector(
  getRegions,
  (state: AppState) => state.entities.property.currentAreaSeasons,
  getSelectedSeasons,
  (regions, currentSeasonArea, selectedSeasons) => {
    if (isEmpty(regions)) return [];

    return Object.values(regions).map(region => {
      const dictSeasonAreas = groupBy(currentSeasonArea, 'areaId');
      return {
        ...region,
        current_info: region.current_info && {
          ...region.current_info,
          crop_ended: !dictSeasonAreas[region.id]?.some(seasonArea => selectedSeasons?.includes(seasonArea.seasonId))
        }
      };
    });
  }
);

export const safelySelectRegionsDictionary = createSelector(safelySelectRegions, regionsToDictionary);

export const safelySelectRegionsInCurrentSeason = createSelector(safelySelectRegions, getSelectedSeasons, (regions, seasons) => {
  return regions.filter(region => {
    const seasonIds = region.seasons.map(s => s.id);
    return seasonIds.some(id => seasons.includes(id));
  });
});

export const safelySelectRegionsInCurrentSeasonDictionary = createSelector(safelySelectRegionsInCurrentSeason, regionsToDictionary);

export const selectSafelyCurrentSeasonFields = createSelector(safelySelectRegionsInCurrentSeason, regions =>
  regions.filter(region => region.type === RegionType.AREA)
);

export const selectSafelyCurrentSeasonFieldsIds = createSelector(safelySelectRegionsInCurrentSeason, regions =>
  regions.filter(region => region.type === RegionType.AREA).map(region => region.id)
);
