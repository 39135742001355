import STTypo from 'core/shared/typo';
import { ColorOptions, TypeOptions } from 'core/shared/typo/typo.models';
import type React from 'react';
import type { ReportsTypes } from '../reports-types.enum';

interface ReportButtonProps {
  setReportToOpen: (type: ReportsTypes) => void;
  className: string;
  type: ReportsTypes;
  text: string;
  icon: React.ReactNode;
  testId?: string;
}

const ReportButton: React.FC<ReportButtonProps> = ({ setReportToOpen, className, type, text, icon, testId = 'report-item-spray' }) => {
  return (
    <div className={`st-report-drawer__item ${className}`} data-testid={testId} onClick={() => setReportToOpen(type)}>
      <div className='st-report-drawer__item-icon' data-testid={`report-item-${type}-icon`}>
        {icon}
      </div>
      <div className='st-report-drawer__item-text' data-testid={`report-item-${type}-name`}>
        <STTypo type={TypeOptions.P1} color={ColorOptions.PRIMARY}>
          {text}
        </STTypo>
      </div>
    </div>
  );
};
export default ReportButton;
