import { ColorOptions } from 'core/shared/typo/typo.models';
import styled from 'styled-components';

export const StyledGroupContainer = styled.div<{ containerWidth?: string }>`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 10px 20px;

  > div {
    width: ${({ containerWidth }) => containerWidth ?? 'inherit'};
  }
`;

export const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-height: 300px;
  border: 1px solid #c6e6ff;
  border-radius: 4px;
`;

export const StyledHeader = styled.div`
  display: flex;
  align-items: center;
  padding: 0px 16px;
  height: 36px;
  min-width: 180px;
  background-color: none;
  justify-content: space-between;
`;

export const StyledButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  width: 20px;
  height: 20px;
  background-color: ${ColorOptions.BLUE_30};
  cursor: pointer;
`;
