import { selectSelectedProperty } from 'core/core.reducer';
import type { Action } from 'core/utils/basic.models';
import { selectAllProperties } from 'entities/property/property.reducer';
import { ofType } from 'redux-observable';
import type { AppState } from 'redux/app-state';
import { of } from 'rxjs';
import { catchError, concatMap, map, withLatestFrom } from 'rxjs/operators';
import { AssigneeActionsTypes, LoadAssigneesFailure, LoadAssigneesSuccess } from './assignee.actions';
import type { AssigneePage, PayloadAssigneesType } from './assignee.models';
import { getAssigneePage } from './assignee.service';

export const handleLoadAssignees = (action$, state$) =>
  action$.pipe(
    ofType(AssigneeActionsTypes.LOAD_ASSIGNEES),
    map((action: Action<PayloadAssigneesType>) => action.payload),
    withLatestFrom(
      state$.pipe(map((state: AppState) => selectSelectedProperty(state.uiState.global))),
      state$.pipe(map((state: AppState) => selectAllProperties(state.entities.property).map(property => property.id)))
    ),
    concatMap(([payload, property_id, property_ids]) => {
      return getAssigneePage(payload?.company_id, property_id ? [property_id] : property_ids, payload?.only_accounts_workers).pipe(
        map(response => response.data),
        map((assigneePage: AssigneePage) => {
          return LoadAssigneesSuccess(assigneePage);
        }),
        catchError((error: string) => of(LoadAssigneesFailure(error)))
      );
    })
  );
