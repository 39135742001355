import { getOrganizationInfo } from 'core/services/auth/auth.service';
import type { Action, PageableQuery, UUID } from 'core/utils/basic.models';
import { ofType } from 'redux-observable';
import type { AppState } from 'redux/app-state';
import { concat, of } from 'rxjs';
import { catchError, concatMap, map, mergeMap, withLatestFrom } from 'rxjs/operators';
import { SetPermissionFlags } from '../../core/services/auth/auth.actions';
import { getLicensingStatus, getPermissionFlags } from '../../core/services/auth/auth.functions';
import {
  CompanyActionsTypes,
  LoadCompaniesFailure,
  LoadCompaniesSuccess,
  LoadCurrentWorkspaceIdFailure,
  LoadCurrentWorkspaceIdSuccess,
  LoadOrgLicensingStatusFailure,
  LoadOrgLicensingStatusSuccess,
  ReloadCompanies
} from './company.actions';
import type { CompanyPage, ReloadCompaniesModel } from './company.models';
import { getCompaniesWithActiveContracts, getCompanyPage, getOrgLicensingStatus } from './company.service';

export const handleLoadCompanies = (action$, state$) =>
  action$.pipe(
    ofType(CompanyActionsTypes.LOAD_COMPANIES),
    map((action: Action<PageableQuery>) => action.payload),
    withLatestFrom(state$.pipe(map((state: AppState) => state.uiState.global.systemFlags))),
    mergeMap(([query, flags]) => {
      if (!flags) return of(LoadCompaniesFailure(''));

      if (flags?.P40_32809_organizations_with_active_contracts) {
        return getCompaniesWithActiveContracts().pipe(
          map(response => response.data),
          map(companyPage => LoadCompaniesSuccess(companyPage)),
          catchError(error => of(LoadCompaniesFailure(error)))
        );
      }

      return getCompanyPage(query, 13000).pipe(
        map(response => response.data),
        map((companyPage: CompanyPage) => {
          return LoadCompaniesSuccess(companyPage);
        }),
        catchError(error => {
          if (error.message.includes('timeout')) return of(ReloadCompanies({ query, retry: 1 }));
          return of(LoadCompaniesFailure(error));
        })
      );
    })
  );

export const handleLoadOrgLicensingStatus = (action$, state$) =>
  action$.pipe(
    ofType(CompanyActionsTypes.LOAD_LICENSING_STATUS),
    map((action: Action<UUID>) => action.payload),
    concatMap((query: UUID) =>
      getOrgLicensingStatus(query).pipe(
        concatMap(companyPage =>
          concat([
            SetPermissionFlags(getPermissionFlags(companyPage, state$.value.uiState.auth.permissionFlags)),
            LoadOrgLicensingStatusSuccess(getLicensingStatus(companyPage))
          ])
        ),
        catchError(error => {
          return of(LoadOrgLicensingStatusFailure(error));
        })
      )
    )
  );

export const handleLoadCurrentWorkspaceId = action$ =>
  action$.pipe(
    ofType(CompanyActionsTypes.LOAD_CURRENT_WORKSPACE_ID),
    map((action: Action<UUID>) => action.payload),
    concatMap((orgId: UUID) =>
      getOrganizationInfo(orgId).pipe(
        map(response => response.data),
        map(orgInfo => LoadCurrentWorkspaceIdSuccess(orgInfo.workspace_id)),
        catchError(error => of(LoadCurrentWorkspaceIdFailure(error)))
      )
    )
  );

export const handleReloadCompanies = (action$, state$) =>
  action$.pipe(
    ofType(CompanyActionsTypes.RELOAD_COMPANIES),
    map((action: Action<ReloadCompaniesModel>) => action.payload),
    withLatestFrom(state$.pipe(map((state: AppState) => state.uiState.global.systemFlags))),
    mergeMap(([data, flags]) => {
      if (!flags) return of(LoadCompaniesFailure(''));

      if (flags?.P40_32809_organizations_with_active_contracts) {
        return getCompaniesWithActiveContracts().pipe(
          map(response => response.data),
          map(companyPage => LoadCompaniesSuccess(companyPage)),
          catchError(error => of(LoadCompaniesFailure(error)))
        );
      }

      const timeouts = [13000, 21000, 34000, 55000, 89000];
      const timeout = data.retry < 5 ? timeouts[data.retry] : 0;
      return getCompanyPage(data.query, timeout).pipe(
        map(response => response.data),
        map((companyPage: CompanyPage) => LoadCompaniesSuccess(companyPage)),
        catchError(error => {
          if (error.message.includes('timeout') && data.retry < 5) {
            return of(ReloadCompanies({ query: data.query, retry: data.retry + 1 }));
          }
          return of(LoadCompaniesFailure(error));
        })
      );
    })
  );
