import { Collapse, Tag } from 'antd';
import { images } from 'assets';
import { useDidMount } from 'beautiful-react-hooks';
import SDMarkerPopup from 'components/map/sd-marker-popup.component';
import type { LatLngTuple } from 'core/core.models';
import { ThresholdColor } from 'core/core.models';
import STAvatar from 'core/shared/avatar';
import STImage from 'core/shared/image/image.component';
import STTypo, { STTypoModels } from 'core/shared/typo';
import { ColorOptions } from 'core/shared/typo/typo.models';
import type { UUID } from 'core/utils/basic.models';
import { formatNumber, getCurrentLanguage, getInitials, getPhenomenonUrl } from 'core/utils/functions';
import type { mapboxgl } from 'core/utils/map/mapbox-gl';
import moment from 'moment';
import type { MonitoringDetailPhenomenon } from 'pages/timeline/monitoring.models';
import { getIndicatorThreshold } from 'pages/timeline/timeline.functions';
import type { MonitoringPoint } from 'pages/timeline/timeline.models';
import { getMonitoringPoint } from 'pages/timeline/timeline.service';
import type React from 'react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import sentryService from 'sentry/service';
import SvgAnnotationsGlyph from 'st-protector-icons/Glyph/AnnotationsGlyph';
import SvgClockGlyph from 'st-protector-icons/Glyph/ClockGlyph';
import PlusFilledGlyph from 'st-protector-icons/Glyph/PlusFilledGlyph';
import SvgPropertyGlyph from 'st-protector-icons/Glyph/PropertyGlyph';

const { Panel } = Collapse;

interface IPopupPhenomena {
  phenomena: MonitoringDetailPhenomenon[];
}

export interface ISDPopupMonitoring extends MonitoringPoint {
  popup: any;
  propertyId: UUID;
  mapboxPopup: mapboxgl.Popup;
  resources?: Record<string, string>;
}

const PhenomenonIndicators = ({ indicators }) => (
  <div className='st-popup__indicators'>
    {indicators.map(indicator =>
      indicator.values.map((pointValue, idx) => {
        return (
          <div className='st-popup__indicator' key={`${indicator.id}_${idx}`}>
            <div
              className='st-popup__status-sideline'
              style={{
                backgroundColor: ThresholdColor[getIndicatorThreshold(pointValue.value, indicator.thresholds).label]
              }}
            />
            <div className='st-grid-row--2'>
              <STTypo maxlength={28}>{indicator.values.length > 1 ? `${indicator.name} (${idx + 1})` : indicator.name}</STTypo>
              <STTypo
                type={STTypoModels.TypeOptions.P1}
                fontWeight={500}
                color={ThresholdColor[getIndicatorThreshold(pointValue.value, indicator.thresholds).label]}>
                {typeof pointValue.value === 'number' ? formatNumber(pointValue.value, 0) : '--'}
                &nbsp;
                {indicator.uom}
              </STTypo>
            </div>
          </div>
        );
      })
    )}
  </div>
);

export const FoundOccurences: React.FC<IPopupPhenomena> = ({ phenomena }) => (
  <div className='st-popup__phenomena' style={{ marginTop: '8px' }}>
    {phenomena.map((phenomenon, idx) => (
      <div className='st-popup__phenomenon' key={idx}>
        <div className='st-popup__item st-align-items--center'>
          <STImage
            className='st-popup__phenomenon__image'
            url={getPhenomenonUrl(phenomenon.id)}
            fallbackimage={images.falbackImageMinor}
            alt={phenomenon.name}
          />
          <div className='st-grid-row--2'>
            <STTypo fontWeight={500} maxlength={20}>
              {phenomenon.name}
            </STTypo>
            <STTypo color={ColorOptions.SECONDARY} maxlength={30} italic>
              {phenomenon.description}
            </STTypo>
          </div>
        </div>
        <PhenomenonIndicators indicators={phenomenon.indicators} />
      </div>
    ))}
  </div>
);

const NotFoundOccurences: React.FC<IPopupPhenomena> = ({ phenomena }) => {
  const [t] = useTranslation();

  return (
    <div>
      <Collapse className='st-popup__phenomenon-not-found-collapse' expandIconPosition='right'>
        <Panel
          header={
            <div className='st-popup__phenomenon-not-found-collapse__title'>
              <STTypo fontWeight={500}>
                <STTypo maxlength={23} fontWeight={500}>
                  {t('pages.timeline.monitoring_window.not_found_occurences')}
                </STTypo>
                <STTypo fontWeight={500}>:{phenomena.length}</STTypo>
              </STTypo>
            </div>
          }
          key='1'>
          <Collapse className='st-popup__phenomenon-not-found-collapse__indicator-collapse' expandIconPosition='right'>
            {phenomena.map((phenomenon, i) => (
              <Panel
                key={i}
                header={
                  <div className='st-popup__phenomenon' key={phenomenon.name}>
                    <div className='st-popup__phenomenon-not-found-header'>
                      <STImage
                        className='st-popup__image'
                        url={getPhenomenonUrl(phenomenon.id)}
                        fallbackimage={images.falbackImageMinor}
                        alt={phenomenon.name}
                      />
                      <div className='st-grid-row--2'>
                        <STTypo fontWeight={500} maxlength={18}>
                          {phenomenon.name}
                        </STTypo>
                        <STTypo color={ColorOptions.SECONDARY} maxlength={30} italic>
                          {phenomenon.description}
                        </STTypo>
                      </div>
                    </div>
                  </div>
                }>
                <PhenomenonIndicators indicators={phenomenon.indicators} />
              </Panel>
            ))}
          </Collapse>
        </Panel>
      </Collapse>
    </div>
  );
};

const SDPopupMonitoring: React.FC<ISDPopupMonitoring> = ({
  propertyId,
  date,
  forced_point,
  scouter,
  point,
  popup,
  mapboxPopup,
  image,
  id,
  resources
}) => {
  const [t] = useTranslation();
  const coordinates: LatLngTuple = [point.coordinates[1], point.coordinates[0]];
  const forcedCoordinates: LatLngTuple | undefined = forced_point ? [forced_point.coordinates[1], forced_point.coordinates[0]] : undefined;

  const [foundOccurences, setFoundOcurrences] = useState<MonitoringDetailPhenomenon[]>([]);
  const [notFoundOcurrences, setNotFoundOcurrences] = useState<MonitoringDetailPhenomenon[]>([]);
  const [notesMonitoring, setNotesMonitoring] = useState<string[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [loaded, setLoaded] = useState(false);

  moment.locale(getCurrentLanguage());

  useDidMount(() => {
    if (loaded || isLoading) return;
    setIsLoading(true);
    getMonitoringPoint({ property_id: propertyId, monitoring_id: id, date: moment(date).format('YYYY-MM-DD') })
      .toPromise()
      .then(data => {
        const { phenomena, notes } = data;
        let newFoundOccurences: MonitoringDetailPhenomenon[] = [];
        let newNotFoundOccurences: MonitoringDetailPhenomenon[] = [];

        if (notes && notes.length) {
          setNotesMonitoring(notes);
        }

        phenomena.forEach(phenomenon => {
          let foundValidIndicator = false;

          phenomenon.indicators.forEach(indicator => {
            if (!indicator.values) return;
            const sumOfIndicatorValues: number = indicator.values
              .map(v => v.value)
              .reduce<number>((acc, value) => {
                return acc + value;
              }, 0);
            if (sumOfIndicatorValues > 0) {
              foundValidIndicator = true;
            }
          });

          if (foundValidIndicator) {
            newFoundOccurences = [...newFoundOccurences, phenomenon];
          } else {
            newNotFoundOccurences = [...newNotFoundOccurences, phenomenon];
          }
        });
        setFoundOcurrences(newFoundOccurences);
        setNotFoundOcurrences(newNotFoundOccurences);
        setIsLoading(false);
        setLoaded(true);
      })
      .catch(e => {
        setIsLoading(false);
        setLoaded(true);
        sentryService.captureException(e, {
          fileName: 'sd-popup-monitoring',
          method: 'getMonitoringPoint'
        });
      });
  });

  return (
    <SDMarkerPopup {...popup} isLoading={isLoading} mapboxPopup={mapboxPopup} title={t('pages.fixed_points.sample_problem')}>
      <div className='st-popup__item'>
        <SvgPropertyGlyph />
        <STTypo color={forcedCoordinates ? STTypoModels.ColorOptions.SECONDARY : STTypoModels.ColorOptions.PRIMARY}>
          {`${coordinates[0].toFixed(6)}, ${coordinates[1].toFixed(6)}`}
        </STTypo>
        {forcedCoordinates && (
          <Tag className='st-tag' color='rgba(111, 160, 138, 0.1)'>
            <STTypo type={STTypoModels.TypeOptions.C2} fontWeight={500} color='#6FA08A'>
              {t('pages.timeline.monitoring_window.point_real')}
            </STTypo>
          </Tag>
        )}
      </div>
      {forcedCoordinates && (
        <div className='st-popup__item'>
          <div className='st-width--16' />
          <STTypo>{`${forcedCoordinates[0].toFixed(6)}, ${forcedCoordinates[1].toFixed(6)}`}</STTypo>
          <Tag className='st-tag' color='rgba(27, 103, 107, 0.1)'>
            <STTypo type={STTypoModels.TypeOptions.C2} fontWeight={500} color='#1B676B'>
              {t('pages.timeline.monitoring_window.point_simulated')}
            </STTypo>
          </Tag>
        </div>
      )}
      <div className='st-popup__item'>
        <SvgClockGlyph />
        <STTypo>{moment(date, 'YYYY-MM-DDTHH:mm').format('DD MMM, YY • HH:mm')}</STTypo>
      </div>
      {scouter && (
        <div className='st-popup__item st-popup__item-avatar st-grid-column-gap--4'>
          <STAvatar label={scouter.name} small disabled>
            {getInitials(scouter.name)}
          </STAvatar>
          <STTypo maxlength={26}>{scouter.name}</STTypo>
        </div>
      )}
      {!!resources && Object.keys(resources).length > 0 && (
        <div>
          <div className='st-popup__item'>
            <PlusFilledGlyph />
            <STTypo>{t('pages.timeline.monitoring_window.resources')}</STTypo>
          </div>
          <div className='st-popup__list'>
            {Object.keys(resources).map(resourceName => (
              <STTypo key={resourceName}>
                {`• ${resourceName}: `}
                <STTypo fontWeight={500}>{resources[resourceName]}</STTypo>
              </STTypo>
            ))}
          </div>
        </div>
      )}
      {!!notesMonitoring.length &&
        notesMonitoring.map((note, index) => (
          <div className='st-popup__item st-popup__item-note st-grid-column-gap--4' key={`monitoring-note${index}`}>
            <SvgAnnotationsGlyph />
            <STTypo>{note}</STTypo>
          </div>
        ))}
      {foundOccurences.length > 0 && <FoundOccurences phenomena={foundOccurences} />}
      {notFoundOcurrences.length > 0 && <NotFoundOccurences phenomena={notFoundOcurrences} />}
    </SDMarkerPopup>
  );
};

export default SDPopupMonitoring;
