import { useQuery } from 'core/utils/data-store/tools';
import type {
  IGetAllPhenomenaByCompany,
  IGetAllPhenomenaByCompanyReturn,
  IGetAllPhenomenaByProperty,
  IGetAllPhenomenaByPropertyReturn
} from 'entities/phenomenon/phenomenon.models';
import { selectAreasByProperty } from 'entities/region/region.reducer';
import { getSelectedSeasons } from 'entities/season/season.reducer';
import { keyBy } from 'lodash';
import { useGetPhenomena } from 'querys/control-strategy/control-strategy.query';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { getPhenomena } from './phenomenon.service';

const TEN_MINUTES = 1000 * 60 * 10;

// Dictionary that contains all the phenomena included in the company, methodologies, static points
export const useGetAllPhenomenaByProperty = ({ enabled = false }: IGetAllPhenomenaByProperty): IGetAllPhenomenaByPropertyReturn => {
  const areas = useSelector(selectAreasByProperty) || [];
  const seasons = useSelector(getSelectedSeasons);

  const { data: controlStrategyPhenomena, isLoading } =
    useGetPhenomena(
      seasons.map(s => s.id),
      areas.map(a => a.id),
      enabled
    ) || [];

  return {
    phenomenaDictionary: keyBy(controlStrategyPhenomena, 'id'),
    data: controlStrategyPhenomena,
    isLoading
  };
};

export const useGetAllPhenomenaByCompany = ({
  enabled = false,
  onSuccess = () => {
    return undefined;
  },
  onError = () => {
    return undefined;
  }
}: IGetAllPhenomenaByCompany): IGetAllPhenomenaByCompanyReturn => {
  const { companyId } = useParams();

  const queryResult = useQuery(['get-all-phenomena-by-company', companyId], () => getPhenomena(companyId ?? ''), {
    enabled: !!companyId && enabled,
    cacheTime: TEN_MINUTES,
    staleTime: Infinity,
    onSuccess,
    onError
  });

  return {
    phenomenaDictionary: keyBy(queryResult?.data, 'id'),
    queryResult
  };
};
