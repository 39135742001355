import chroma from 'chroma-js';

export enum ChoroplethTypes {
  indicator_severity = 'indicator_severity',
  days_without_spray = 'days_without_spray',
  days_without_monitoring = 'days_without_monitoring',
  days_after_emergence = 'days_after_emergence',
  scouting_score = 'scouting_score',
  nematode = 'nematode',
  severity_level = 'severity_level',
  seeds = 'seeds',
  heatmap = 'heatmap',
  phenological_stage = 'phenological_stage',
  carbon_map = 'carbon_map'
}

export interface ChoroplethLimits {
  max: number;
  min: number;
}

export interface StorageMaxDaysLimit {
  daysWithoutMonitoringMax: number | null;
  daysWithoutMonitoringMaxColor: string | null;
  daysWithoutSprayMax: number | null;
  daysWithoutSprayMaxColor: string | null;
  daysAfterEmergenceMax: number | null;
  daysAfterEmergenceMaxColor: string | null;
  daysAfterEmergenceMin: number | null;
  daysAfterEmergenceMinColor: string | null;
}

export const LIMIT_DAYS_COLORS = [
  '#FFD9D9',
  '#F98989',
  '#CF3537',
  '#70191A',
  '#FFE4AE',
  '#E99921',
  '#9A6412',
  '#523305',
  '#C3EAD1',
  '#5EBB7F',
  '#14803C',
  '#06431C',
  '#A9EFE8',
  '#46BBB0',
  '#217C74',
  '#0D413C',
  '#C6E6FF',
  '#31B4F2',
  '#0071CD',
  '#003B69',
  '#E7DDFC',
  '#BA99F6',
  '#8354D6',
  '#462779',
  '#FFFFFF',
  '#000000'
];

export const SEVERITY_COLORS_10: string[] = ['#2FB966', '#FFCA28', '#F74141'];

export const MAP_SEVERITY_COLORS: string[] = ['#19A04B', '#F0C355', '#EB4B4B'];

export const SEVERITY_COLORS_5: string[] = chroma.scale(['#FFFFC1', '#FEB24C', '#F03B21', '#BD0226']).mode('lch').colors(5);

export const MAP_SEVERITY_COLORS_5: string[] = ['#FFF3DD', '#F0C355', '#FF785A', '#CF3537', '#70191A'];

export const SPRAY_COLORS_5: string[] = chroma.scale(['#DCEAFF', '#0568FA', '#00398E']).mode('lch').colors(5);

export const MAP_SPRAY_COLORS_5: string[] = ['#EAF6FF', '#82CFFF', '#0092E4', '#005693', '#002747'];

export const FARMSHOTS_COLORS_256: string[] = chroma.scale(['#F85555', '#FFCA28', '#198746']).mode('lch').colors(256);

export const HEATMAP_COLORS_256: string[] = chroma.scale(['#4BF83F', '#E7EF40', '#E81021']).mode('lch').colors(256);

export const BORER_RISK_COLORS_256: string[] = chroma.scale(['#FFE7CB', '#FFC077', '#FF8A00']).mode('lch').colors(256);

export const DAE_COLORS_5: string[] = chroma.scale(['#C3EAD1', '#90D6AA', '#5EBB7F', '#19A04B', '#0C612C']).mode('lch').colors(5);

export const MAP_DAE_COLORS_5: string[] = ['#DFFBE8', '#5FD2C8', '#2B9C92', '#165C56', '#062B27'];

export const DAMAGE_THRESHOLDS = [
  {
    value: 4,
    color: '#FFF1ED'
  },
  {
    value: 8,
    color: '#FFB59D'
  },
  {
    value: 12,
    color: '#C1462B'
  }
];

export const DAMAGE_DEFAULT_COLOR = '#48150A';

export const AGRONOMIC_COLORS_ORDER = [[0], [0, 4], [0, 1, 4], [0, 1, 3, 4], [0, 1, 2, 3, 4]];
