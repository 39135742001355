import { Button, DatePicker, Drawer, Form, Popconfirm, notification } from 'antd';
import STTypo from 'core/shared/typo';
import { ColorOptions, TypeOptions } from 'core/shared/typo/typo.models';
import useSegmentTracking from 'core/utils/segment/useSegmentTracking';
import type { AnalyticsInfo } from 'core/utils/segment/user-events.types';
import { getSelectedCompany } from 'entities/company/company.reducer';
import { getSelectedProperty } from 'entities/property/property.reducer';
import { RegionType } from 'entities/region/region.models';
import { selectRegionDictionaryByCurrentSeason } from 'entities/region/region.reducer';
import moment from 'moment';
import type { RequestTrackingExport } from 'pages/timeline/timeline.models';
import { getExportTracking } from 'pages/timeline/timeline.service';
import type React from 'react';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import SvgAlertGlyph from 'st-protector-icons/Glyph/AlertGlyph';
import SvgArrowDownGlyph from 'st-protector-icons/Glyph/ArrowDownGlyph';
import SvgCalendarGlyph from 'st-protector-icons/Glyph/CalendarGlyph';
import * as XLSX from 'xlsx';
import { ReportsTypes } from '../reports-types.enum';
import { ExplicativeText } from './explicative-text.component';
import HeaderTitle from './header-title.component';

const { RangePicker } = DatePicker;

export interface STTrackingExportDrawerProps {
  visible: boolean;
  callbackDrawer: () => void;
  icon?: any;
}

export const trackingReportMapper = (t, data, property) => {
  const rows = data.assignees.reduce((acc, assignee) => {
    const assigneeRows = assignee.route.map((coord, i) => {
      const farm = property.name;
      const user = assignee.name;
      const lat = coord[1];
      const lng = coord[0];
      const timestamp = assignee.timestamps[i] || '';

      return [farm, user, lat, lng, timestamp];
    });

    return [...acc].concat(assigneeRows);
  }, []);
  return rows;
};

const STTrackingExportDrawer: React.FC<STTrackingExportDrawerProps> = ({ visible, callbackDrawer, icon }) => {
  const [t] = useTranslation();

  const [timeRangeFrom, setTimeRangeFrom] = useState(moment().subtract(7, 'd').set({ hour: 0, minute: 0, second: 0, millisecond: 0 }));
  const [timeRangeTo, setTimeRangeTo] = useState(moment().set({ hour: 0, minute: 0, second: 0, millisecond: 0 }));
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const property = useSelector(getSelectedProperty);
  const regions = useSelector(selectRegionDictionaryByCurrentSeason);
  const company = useSelector(getSelectedCompany);

  const closeDrawer = useCallback(() => {
    callbackDrawer();
  }, [callbackDrawer]);

  const disabledDate = current => {
    return current && current > moment().endOf('day');
  };

  const handleTimeRange = value => {
    if (value?.length) {
      setTimeRangeFrom(value[0].set({ hour: 0, minute: 0, second: 0, millisecond: 0 }));
      setTimeRangeTo(value[1].set({ hour: 0, minute: 0, second: 0, millisecond: 0 }));
    }
  };

  const segmentTracking = useSegmentTracking();

  const generateReport = () => {
    if (!property) return;
    setIsLoading(true);
    const allRegions = Object.values(regions).filter(region => region.type === RegionType.AREA);
    const request: RequestTrackingExport = {
      query: {
        property: property.id,
        assignees: [],
        regions: allRegions.map(region => region.id),
        since: timeRangeFrom.format('YYYY-MM-DD'),
        until: timeRangeTo.format('YYYY-MM-DD')
      }
    };

    getExportTracking(request)
      .toPromise()
      .then(response => {
        if (response.status !== 200) {
          notification.error({
            message: (
              <>
                <div>
                  <STTypo>{t('report.tracking.error')}.</STTypo>
                </div>
                {t('report.tracking.error_message')}
              </>
            )
          });
          setIsLoading(false);
          return;
        }
        if (!response.data?.assignees.length) {
          notification.warning({
            message: (
              <>
                <div>
                  <STTypo>{t('report.tracking.not_found')}.</STTypo>
                </div>
                {t('report.tracking.empty_message')}
              </>
            )
          });
          setIsLoading(false);
          return;
        }

        const payload: AnalyticsInfo = {
          companyId: company?.id || '',
          companyName: company?.name || '',
          propertyId: property.id,
          propertyName: property.name,
          reportType: ReportsTypes.TRACKING,
          from: 'Export Report'
        };
        segmentTracking.track('Export Report - Generated Report', payload);

        const mappedData = trackingReportMapper(t, response.data, property);
        const columns = [
          t('report.tracking.farm').toLocaleUpperCase(),
          t('report.tracking.user').toLocaleUpperCase(),
          t('report.tracking.latitude').toLocaleUpperCase(),
          t('report.tracking.longitude').toLocaleUpperCase(),
          t('report.tracking.timestamp').toLocaleUpperCase()
        ];

        const worksheet = XLSX.utils.aoa_to_sheet([columns, ...mappedData]);
        const newWorkbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(
          newWorkbook,
          worksheet,
          `Tracking_${timeRangeFrom.format('YYYYMMDD')}_${timeRangeTo.format('YYYYMMDD')}`
        );
        XLSX.utils.sheet_to_csv(worksheet);
        XLSX.writeFile(
          newWorkbook,
          `tracking_${property.name.toLocaleLowerCase().replace(' ', '_')}_${timeRangeFrom.format('YYYYMMDD')}_${timeRangeTo.format(
            'YYYYMMDD'
          )}.csv`,
          {
            bookType: 'csv'
          }
        );
        setIsLoading(false);
      });
  };

  return (
    <Drawer
      className='st-report-drawer'
      placement='right'
      visible={visible}
      width={480}
      onClose={closeDrawer}
      title={<HeaderTitle icon={icon} type={ReportsTypes.TRACKING} />}>
      <div className='st-report-drawer__content' data-testid='report-tracking-drawer'>
        <ExplicativeText type={ReportsTypes.TRACKING} />
      </div>
      <div className='st-report-drawer__form' data-testid='report-tracking-form'>
        <Form layout='vertical'>
          <Form.Item data-testid='report-tracking-export-timerange' label={t('modals.timeline_xray_report.fields.time_range')}>
            <div className='st-report-drawer__form__date'>
              <div className='st-report-drawer__form__date__icon-container'>
                <SvgCalendarGlyph fill='#707374' />
              </div>
              <RangePicker
                value={[timeRangeFrom, timeRangeTo]}
                format='L'
                size='large'
                separator='—'
                suffixIcon={<SvgArrowDownGlyph />}
                onChange={handleTimeRange}
                allowClear={false}
                disabledDate={disabledDate}
              />
            </div>
          </Form.Item>
        </Form>
        <div className='st-report-drawer__actions'>
          <Popconfirm
            placement='bottomRight'
            icon={
              <div className='st-report-drawer__confirm-icon-container'>
                <SvgAlertGlyph fill='#F74141' />
              </div>
            }
            getPopupContainer={trigger => trigger.parentNode as any}
            overlayClassName='st-report-drawer__confirm'
            title={
              <div className='st-report-drawer__confirm-delete'>
                <STTypo type={TypeOptions.P1}>{`${t('report.back')}`}</STTypo>
              </div>
            }
            onConfirm={() => closeDrawer()}
            okText={t('general.continue')}
            okButtonProps={{ style: { backgroundColor: '#F74141', border: 'none', borderRadius: '4px', paddingTop: '2px' } }}
            cancelButtonProps={{
              style: {
                backgroundColor: '#F2F4F6',
                border: 'none',
                borderRadius: '4px',
                paddingTop: '2px',
                color: ColorOptions.PRIMARY
              }
            }}
            cancelText={t('general.back')}>
            <Button type='ghost' size='default' className='st-report-drawer_actions-cancel' data-testid='report-property-cancel'>
              {t('general.cancel')}
            </Button>
          </Popconfirm>
          <Button
            type='primary'
            size='default'
            className='st-report-drawer_actions-submit'
            onClick={generateReport}
            disabled={!timeRangeFrom || !timeRangeTo}
            loading={isLoading}
            data-testid='report-property-submit'>
            {t('report.action')}
          </Button>
        </div>
      </div>
    </Drawer>
  );
};

export default STTrackingExportDrawer;
