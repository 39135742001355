import i18n from 'config/i18n';
import type { Crop } from '../basic.models';
import { slugify } from '../strings';

export const getCropL10nBySlug = (cropSlug: string): string => i18n.t(`general.crops.${cropSlug}`, cropSlug);

export const getCropSlug = (crop: Crop | undefined): string => (crop ? slugify(crop?.wk_slug || crop?.name || 'other') : 'other');

export const getCropL10nByCrop = (crop: Crop | undefined): string =>
  i18n.t(`general.crops.${getCropSlug(crop)}`, { defaultValue: crop?.name ?? crop?.wk_slug });
