import { selectSelectedCompany, selectSelectedProperty } from 'core/core.reducer';
import type { UUID } from 'core/utils/basic.models';
import { initialPage } from 'core/utils/basic.models';
import type { Dictionary, EntityAdapter } from 'redux-ngrx-entity';
import { createEntityAdapter } from 'redux-ngrx-entity';
import type { AppState } from 'redux/app-state';
import { createSelector } from 'reselect';
import { PropertyActionsTypes } from './property.actions';
import type { Property, PropertyState } from './property.models';

function selectPropertyUuid(property: Property): string {
  return property.id;
}

function sortByName(a: Property, b: Property): number {
  return a.name.localeCompare(b.name);
}

const entity: EntityAdapter<Property> = createEntityAdapter<Property>({
  selectId: selectPropertyUuid,
  sortComparer: sortByName
});

export const initialState: PropertyState = entity.getInitialState({
  page: {
    ...initialPage
  },
  isLoadingTime: false,
  isLoading: false,
  isLoaded: false,
  isLoadedPropertyAreaInfo: false,
  error: null,
  loadingDiagnostic: false,
  propertyTime: '',
  currentAreaSeasons: [],
  seasonAreasFromActiveSeasons: {},
  isLoadedSeasonAreas: false,
  isLoadingCurrentAreaSeasons: false,
  isLoadingPropertyData: false,
  propertyData: null,
  propertyPhenomenons: [],
  propertyIndicators: [],
  propertyMethodologies: [],
  isIndicatorPressureLoading: false,
  isLoadingPropertyPhenomena: false,
  seasonAreasInDate: {
    date: null,
    content: []
  }
});

const setLoadingDiagnostic = (propertyIds: UUID[], entities: Dictionary<Property>) => {
  return propertyIds.map(id => {
    return {
      id,
      changes: {
        diagnostic_breakdown: undefined
      }
    };
  });
};

export default (state = initialState, action): PropertyState => {
  switch (action.type) {
    case PropertyActionsTypes.LOAD_PROPERTY:
    case PropertyActionsTypes.LOAD_PROPERTIES:
      return entity.removeAll({
        ...state,
        isLoading: true,
        isLoaded: false,
        isLoadedPropertyAreaInfo: false,
        propertyPhenomenons: []
      });
    case PropertyActionsTypes.LOAD_PROPERTIES_AREA_INFO:
      return {
        ...state,
        isLoadedPropertyAreaInfo: false
      };
    case PropertyActionsTypes.LOAD_PROPERTY_PHENOMENA: {
      return {
        ...state,
        isLoadingPropertyPhenomena: true,
        propertyPhenomenons: []
      };
    }
    case PropertyActionsTypes.LOAD_PROPERTY_PHENOMENA_SUCCESS: {
      return {
        ...state,
        isLoadingPropertyPhenomena: false,
        propertyPhenomenons: action.payload
      };
    }
    case PropertyActionsTypes.LOAD_SEVERITY_BY_INDICATOR: {
      return {
        ...state,
        isIndicatorPressureLoading: true
      };
    }
    case PropertyActionsTypes.LOAD_SEVERITY_BY_INDICATOR_SUCCESS: {
      return {
        ...state,
        isIndicatorPressureLoading: false
      };
    }
    case PropertyActionsTypes.LOAD_PROPERTY_INDICATORS_SUCCESS: {
      return {
        ...state,
        propertyIndicators: action.payload
      };
    }
    case PropertyActionsTypes.LOAD_PROPERTY_METHODOLOGIES_SUCCESS: {
      return {
        ...state,
        propertyMethodologies: action.payload
      };
    }
    case PropertyActionsTypes.LOAD_PROPERTIES_SUCCESS:
      return entity.addMany(action.payload.mutableContent, {
        ...state,
        page: {
          ...action.payload
        },
        isLoading: false,
        isLoaded: true,
        error: null
      });
    case PropertyActionsTypes.LOAD_PROPERTIES_FAILURE:
      return {
        ...state,
        isLoading: false,
        isLoaded: false,
        error: action.payload
      };
    case PropertyActionsTypes.LOAD_PROPERTIES_AREA_INFO_SUCCESS:
      return entity.upsertMany(action.payload.mutableContent, {
        ...state,
        page: {
          ...action.payload
        },
        isLoadedPropertyAreaInfo: true
      });
    case PropertyActionsTypes.LOAD_PROPERTIES_AREA_INFO_FAILURE:
      return {
        ...state,
        isLoadedPropertyAreaInfo: false
      };

    case PropertyActionsTypes.LOAD_PROPERTY_TIME:
      return {
        ...state,
        isLoadingTime: true
      };
    case PropertyActionsTypes.LOAD_PROPERTY_SUCCESS:
      return entity.addOne(action.payload, {
        ...state,
        isLoading: false,
        error: null
      });
    case PropertyActionsTypes.LOAD_PROPERTY_DIAGNOSTIC_BREAKDOWN:
      return entity.updateMany(
        setLoadingDiagnostic(
          action.payload.map(p => p.propertyId),
          state.entities
        ),
        state
      );

    case PropertyActionsTypes.LOAD_PROPERTY_DIAGNOSTIC_BREAKDOWN_SUCCESS:
      return entity.updateOne(
        {
          id: action.payload.propertyId,
          changes: {
            diagnostic_breakdown: action.payload.diagnosticBreakdown
          }
        },
        state
      );

    case PropertyActionsTypes.LOAD_PROPERTY_DIAGNOSTIC_BREAKDOWN_FAILURE:
      return entity.updateOne(
        {
          id: action.payload.propertyId,
          changes: {
            diagnostic_breakdown: undefined
          }
        },
        state
      );
    case PropertyActionsTypes.LOAD_PROPERTY_TIME_SUCCESS:
      return {
        ...state,
        isLoadingTime: false,
        propertyTime: action.payload
      };

    case PropertyActionsTypes.GET_PROPERTY_COUNTRY_SUCCESS:
      return entity.updateOne(
        {
          id: action.payload.id,
          changes: {
            country: action.payload.country
          }
        },
        {
          ...state,
          isLoading: false,
          error: null
        }
      );
    case PropertyActionsTypes.GET_PROPERTY_COUNTRY_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload
      };
    case PropertyActionsTypes.GET_SEASON_AREAS_BY_DATE:
    case PropertyActionsTypes.GET_CURRENT_SEASON_AREAS:
      return {
        ...state,
        isLoadingCurrentAreaSeasons: true,
        isLoadedSeasonAreas: false,
        error: null
      };
    case PropertyActionsTypes.GET_SEASON_AREAS_BY_DATE_SUCCESS:
      return {
        ...state,
        seasonAreasInDate: action.payload,
        isLoadingCurrentAreaSeasons: false,
        isLoadedSeasonAreas: true,
        error: null
      };
    case PropertyActionsTypes.GET_CURRENT_SEASON_AREAS_SUCCESS:
      return {
        ...state,
        currentAreaSeasons: action.payload.current,
        seasonAreasFromActiveSeasons: {
          ...state.seasonAreasFromActiveSeasons,
          [action.payload.propertyId]: action.payload.all
        },
        isLoadingCurrentAreaSeasons: false,
        isLoadedSeasonAreas: true,
        error: null
      };
    case PropertyActionsTypes.GET_SEASON_AREAS_BY_DATE_FAILURE:
    case PropertyActionsTypes.GET_CURRENT_SEASON_AREAS_FAILURE:
      return {
        ...state,
        isLoadingCurrentAreaSeasons: false,
        error: action.payload
      };
    case PropertyActionsTypes.LOAD_PROPERTY_DATA:
      return {
        ...state,
        isLoadingPropertyData: true
      };
    case PropertyActionsTypes.LOAD_PROPERTY_DATA_SUCCESS:
      return {
        ...state,
        isLoadingPropertyData: false,
        propertyData: action.payload
      };
    case PropertyActionsTypes.LOAD_PROPERTY_DATA_FAILURE:
      return {
        ...state,
        isLoadingPropertyData: false,
        error: action.payload
      };
    default:
      return state;
  }
};

const { selectIds, selectEntities, selectTotal } = entity.getSelectors();
export const selectPropertyIds = selectIds;

// Properties without season are invalid
export const selectPropertyEntities = createSelector(selectEntities, propertiesDic => {
  const mutableWithSeason: Dictionary<Property> = {};
  for (const property of Object.values(propertiesDic)) {
    if (property?.seasons?.length) {
      mutableWithSeason[property.id] = property;
    }
  }
  return mutableWithSeason;
});

export const selectAllProperties = createSelector(
  (state: PropertyState) => state.entities,
  properties => {
    if (!properties) return [];
    return Object.values(properties).filter(property => property?.seasons?.length);
  }
);

export const selectPropertyTotal = selectTotal;

export const getSelectedProperty = createSelector(
  (state: AppState) => selectAllProperties(state.entities.property),
  (state: AppState) => selectSelectedProperty(state.uiState.global),
  (properties, selectedProperty) => properties.find(property => property.id === selectedProperty)
);

export const getSelectedPropertyByCompany = createSelector(
  (state: AppState) => selectAllProperties(state.entities.property),
  (state: AppState) => selectSelectedCompany(state.uiState.global),
  (properties, selectedCompany) => properties.filter(property => property.company_id === selectedCompany)
);

export const getAllPropertiesByCompany = createSelector(
  (state: AppState) => Object.values(state.entities.property.entities),
  (state: AppState) => selectSelectedCompany(state.uiState.global),
  (properties, selectedCompany) => properties.filter(property => property.company_id === selectedCompany)
);
