import { CloseOutlined } from '@ant-design/icons';
import STTypo from 'core/shared/typo';
import { ColorOptions } from 'core/shared/typo/typo.models';
import type React from 'react';
import { StyledButtonWrapper, StyledContainer, StyledHeader, StyledInputDataWrapper } from './group-input.styles';

export interface GroupInputProps {
  title: string;
  hasCloseButton?: boolean;
  onClose?: () => void;
}

const GroupInput: React.FC<GroupInputProps> = ({ title, hasCloseButton, onClose, children }) => {
  return (
    <StyledContainer data-testid='group-input'>
      <StyledHeader>
        <STTypo color={ColorOptions.BLUE_60} fontWeight={500}>
          {title}
        </STTypo>
        {hasCloseButton && (
          <StyledButtonWrapper data-testid='group-input-close'>
            <CloseOutlined size={18} color={ColorOptions.BLUE_60} onClick={onClose} />
          </StyledButtonWrapper>
        )}
      </StyledHeader>
      <StyledInputDataWrapper>{children}</StyledInputDataWrapper>
    </StyledContainer>
  );
};

export default GroupInput;
