import { getSelectedCompany } from 'core/core.selectors';
import { selectCurrentUser } from 'core/services/auth/auth.reducer';
import { generateUUID, getCurrentLanguage } from 'core/utils/functions';
import { getSelectedProperty } from 'entities/property/property.reducer';
import { RequestReport } from 'entities/reports/report.actions';
import type { RequestReportBody } from 'entities/reports/report.models';
import type { Property } from 'entities/season/season-tree.models';
import type { Season } from 'entities/season/season.models';
import { selectAllSeasons } from 'entities/season/season.reducer';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import type { AppState } from 'redux/app-state';
import { ReportsTypes } from '../reports-types.enum';

interface ReportControlStrategyProps {
  onSubmit: () => void;
  onlySelectedSuggestions?: boolean;
}
export const useReportControlStrategy = ({ onSubmit, onlySelectedSuggestions = false }: ReportControlStrategyProps) => {
  const dispatch = useDispatch();
  const user = useSelector(selectCurrentUser);
  const property = useSelector<AppState, Property | undefined>(getSelectedProperty);
  const company = useSelector((state: AppState) => getSelectedCompany(state));
  const seasons = useSelector<AppState, Season[]>(state => selectAllSeasons(state.entities.season));
  const [activeSeasonId, setActiveSeasonId] = useState<string>();

  const [timeRangeFrom, setTimeRangeFrom] = useState(moment().subtract(7, 'd').startOf('day'));
  const [timeRangeTo, setTimeRangeTo] = useState(moment().startOf('day'));
  const [startDayActivitySeason, setStartDayActivitySeason] = useState<string>(moment().format('YYYY-MM-DD'));

  useEffect(() => {
    const activitySeasons = seasons.filter(season => moment().isBetween(season.start_date, season.end_date, 'day', '[]'));
    activitySeasons.sort((activitySeasonA, activitySeasonB) =>
      moment(activitySeasonA.start_date).diff(moment(activitySeasonB.start_date), 'days')
    );
    setActiveSeasonId(activitySeasons[0]?.id);
    setStartDayActivitySeason(activitySeasons[0]?.start_date);
  }, [seasons]);

  const handleTimeRange = (value: moment.Moment[]) => {
    if (value?.length) {
      setTimeRangeFrom(value[0].set({ hour: 0, minute: 0, second: 0, millisecond: 0 }));
      setTimeRangeTo(value[1].set({ hour: 0, minute: 0, second: 0, millisecond: 0 }));
    }
  };
  const disabledDate = (date: moment.Moment | null) => {
    return (
      (date?.diff(moment(moment().format('YYYY-MM-DD')), 'days') ?? 0) > 0 ||
      (date?.diff(moment(moment(startDayActivitySeason).format('YYYY-MM-DD')), 'days') ?? 0) < 0
    );
  };

  const handleExportReport = () => {
    if (!property || !company || !user) {
      return;
    }
    const requestReportBody: RequestReportBody = {
      id: generateUUID(),
      type: ReportsTypes.CONTROL_STRATEGIES,
      property_id: property?.id,
      data_request: {
        language: getCurrentLanguage(),
        property_id: property.id,
        company_id: company ?? '',
        start_date: timeRangeFrom.format('YYYY-MM-DD'),
        end_date: timeRangeTo.format('YYYY-MM-DD'),
        user_id: user.id,
        only_selected_suggestions: onlySelectedSuggestions,
        season_id: activeSeasonId
      }
    };
    dispatch(RequestReport(requestReportBody));
    onSubmit();
  };

  return {
    handleExportReport,
    disabledDate,
    handleTimeRange,
    timeRangeFrom,
    timeRangeTo
  };
};
