import { Layout } from 'antd';
import { C_LEVEL_REPORT } from 'authentication/contants';
import { useDidMount } from 'beautiful-react-hooks';
import { PROTECTOR_APP_ID } from 'config/constants';
import STHeader from 'core/components/header/header.container';
import STMenu from 'core/components/menu/menu.component';
import 'core/components/menu/menu.styles.less';
import STTrigger from 'core/components/trigger/trigger.component';
import { getLicensingStatus } from 'core/core.selectors';
import { Entitlements } from 'core/shared/enums/entitlements.enum';
import ZendeskWidget from 'core/shared/zendesk/zendeskWidget';
import { validatePlansAndEntitlements } from 'core/utils/functions';
import { useLoadFeatureFlags } from 'core/utils/hooks/feature.flags.hook';
import { useVerifyEmbeddedIntegration } from 'core/utils/hooks/integration.hook';
import useSegmentTracking from 'core/utils/segment/useSegmentTracking';
import type { AnalyticsInfo } from 'core/utils/segment/user-events.types';
import { LoadCompanies, LoadCurrentWorkspaceId, LoadOrgLicensingStatus } from 'entities/company/company.actions';
import { getSelectedCompany } from 'entities/company/company.reducer';
import { LoadProducts } from 'entities/product/product.actions';
import { SetUseNewMapColors } from 'entities/property/property.actions';
import { getSelectedProperty } from 'entities/property/property.reducer';
import { type Dictionary } from 'lodash';
import type React from 'react';
import { Suspense, useCallback, useEffect, useState } from 'react';
import { Unless } from 'react-if';
import { useDispatch, useSelector } from 'react-redux';
import { Outlet, matchPath, useLocation, useMatch, useNavigate } from 'react-router-dom';
import type { AppState } from 'redux/app-state';
import { IndexedDBPromiseAPI } from '../core/indexeddb.utils';
import { getZendeskWidgetKey } from '../core/utils/functions';

const { Header, Content, Sider } = Layout;

const STSider: React.FC = () => {
  const [collapsed, setCollapsed] = useState(true);
  const selectedCompany = useSelector(getSelectedCompany);
  const selectedProperty = useSelector(getSelectedProperty);
  const matchCompany = useMatch({ path: '/company/:companyId', end: false });
  const hideMenuInReaPath = !!useMatch({ path: '/company/:companyId/property/:propertyId/integrations/rea/*' });

  const segmentTracking = useSegmentTracking();

  const handleClick = useCallback(() => {
    const payload: AnalyticsInfo = {
      companyId: selectedCompany?.id,
      companyName: selectedCompany?.name,
      propertyId: selectedProperty?.id,
      propertyName: selectedProperty?.name
    };

    segmentTracking.track(collapsed ? 'Expanded sidebar' : 'Collapsed sidebar', payload);

    setCollapsed(!collapsed);
  }, [collapsed, segmentTracking, selectedCompany?.id, selectedCompany?.name, selectedProperty?.id, selectedProperty?.name]);

  return (
    <Unless condition={hideMenuInReaPath}>
      <Sider data-testid='sd-sidebar' className='st-sider' trigger={null} collapsible collapsed={collapsed} collapsedWidth={64} width={250}>
        <div className='st-menu'>{matchCompany && <STMenu />}</div>

        <STTrigger onClick={handleClick} collapsed={collapsed} />
      </Sider>
    </Unless>
  );
};

const MainLayout = () => {
  const [showZendesk, setShowZendesk] = useState<boolean>(false);
  const [zendeskWidgetKey, setzendeskWidgetKey] = useState<string>();
  const navigate = useNavigate();
  const systemFlags = useSelector<AppState, Dictionary<boolean | string> | null>(state => state.uiState.global.systemFlags);
  const dispatch = useDispatch();
  const { pathname } = useLocation();

  useLoadFeatureFlags();

  const selectedCompany = useSelector(getSelectedCompany);
  const companyId = selectedCompany?.id;

  const licensingStatus = useSelector(getLicensingStatus);

  const verifyIfOrgHasAccessProtector = useCallback(() => {
    if (companyId && licensingStatus && systemFlags) {
      const appProtector = licensingStatus.apps.filter(app => app.id === PROTECTOR_APP_ID);
      if (appProtector.length === 0) {
        if (!window.location.href.includes('/unlicensed')) window.location.pathname = '/unlicensed';
        sessionStorage.setItem('unlicensed', 'true');
        sessionStorage.removeItem('refreshed');
      }
    }
  }, [licensingStatus, companyId, systemFlags]);

  useEffect(() => {
    if (companyId) {
      dispatch(LoadOrgLicensingStatus(companyId));
      dispatch(LoadCurrentWorkspaceId(companyId));
      dispatch(LoadProducts(companyId));
    }
  }, [companyId, dispatch]);

  useEffect(() => {
    if (systemFlags) {
      dispatch(
        LoadCompanies({
          pageable: {
            page: 0,
            size: 10
          }
        })
      );
    }
  }, [systemFlags, dispatch]);

  const [indexedDBInitialized, setIndexedDBInitialized] = useState(false);
  useEffect(() => {
    if (!indexedDBInitialized) {
      setIndexedDBInitialized(true);
      IndexedDBPromiseAPI.initializeIDB().then(
        () => console.info('IDB initialized!'),
        () => console.error('IndexedDB not supported')
      );
    }
  }, [indexedDBInitialized]);

  useEffect(() => {
    verifyIfOrgHasAccessProtector();
  }, [verifyIfOrgHasAccessProtector]);

  useEffect(() => {
    dispatch(SetUseNewMapColors(!!systemFlags?.P40_29700_show_statistic_legend));
  }, [systemFlags?.P40_29700_show_statistic_legend, dispatch]);

  useDidMount(() => {
    if (matchPath('/', window.location.pathname)) {
      navigate('/hall-of-companies');
    }
  });

  useEffect(() => {
    if (licensingStatus) {
      setShowZendesk(validatePlansAndEntitlements(licensingStatus, null, [Entitlements.ZENDESK, Entitlements.ZENDESK_LATAM]));
      const widgetkey = getZendeskWidgetKey(licensingStatus, [Entitlements.ZENDESK, Entitlements.ZENDESK_LATAM]);
      widgetkey === '' ? setShowZendesk(false) : setzendeskWidgetKey(widgetkey);
    }
  }, [licensingStatus]);

  const isEmbedded = useVerifyEmbeddedIntegration();

  const isCLevelReportPage = pathname.includes(C_LEVEL_REPORT);

  if (isEmbedded) {
    return (
      <Layout className='st-layout'>
        {!isCLevelReportPage && (
          <Header className='st-header' data-testid='header-container'>
            <STHeader isEmbedded />
          </Header>
        )}
        <Layout className='st-layout-internal'>
          <Suspense fallback=''>
            <Outlet />
          </Suspense>
        </Layout>
      </Layout>
    );
  }

  return (
    <Layout className='st-layout'>
      {!isCLevelReportPage && (
        <Header className='st-header' data-testid='header-container'>
          <STHeader />
        </Header>
      )}
      <Layout className='st-layout-internal'>
        {!isCLevelReportPage && <STSider />}
        <Content className='st-content'>
          <Suspense fallback=''>
            <Outlet />
          </Suspense>
        </Content>
        {showZendesk && <ZendeskWidget widgetKey={zendeskWidgetKey} />}
      </Layout>
    </Layout>
  );
};

export default MainLayout;
