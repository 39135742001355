import { Tag } from 'antd';
import SDMarkerPopup from 'components/map/sd-marker-popup.component';
import STAvatar from 'core/shared/avatar';
import STTypo, { STTypoModels } from 'core/shared/typo';
import { TypeOptions } from 'core/shared/typo/typo.models';
import type { UUID } from 'core/utils/basic.models';
import { getCurrentLanguage, getInitials } from 'core/utils/functions';
import type { mapboxgl } from 'core/utils/map/mapbox-gl';
import moment from 'moment';
import { InfoTags } from 'pages/timeline/area-day-info/components/area-day-info-events-annotations/info-tags';
import type { AnnotationStage } from 'pages/timeline/timeline.models';
import { FILE_TYPE } from 'pages/timeline/timeline.models';
import type React from 'react';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import SvgAnnotationsGlyph from 'st-protector-icons/Glyph/AnnotationsGlyph';
import SvgClockGlyph from 'st-protector-icons/Glyph/ClockGlyph';
import SvgPropertyGlyph from 'st-protector-icons/Glyph/PropertyGlyph';
import type { PopupCustomProps } from './sd-popup.models';

export interface ISDPopupAnnotation extends AnnotationStage {
  productsAvailable?: string | boolean;
  enableMultiplePhotos?: boolean;
  mapboxPopup: mapboxgl.Popup;
  segmentTracking?: any;
  isGalleryV2?: boolean;
  fieldName?: string;
  propertyId: UUID;
  popup: PopupCustomProps;
}

const SDPopupAnnotation: React.FC<ISDPopupAnnotation> = ({
  enableMultiplePhotos,
  productsAvailable,
  segmentTracking,
  forced_point,
  isGalleryV2,
  mapboxPopup,
  fieldName,
  comments,
  scouter,
  point,
  popup,
  date
}) => {
  const [t] = useTranslation();

  const { coordinates } = point;

  const photos = useMemo(() => {
    if (!popup?.medias) return [];

    return popup.medias.filter(media => media.type === FILE_TYPE.PICTURES).map(pictures => pictures.url);
  }, [popup]);

  moment.locale(getCurrentLanguage());

  const dateToScreen = useMemo(
    () => (popup.propertyTimezone ? moment.tz(date, popup.propertyTimezone) : moment(date)),
    [date, popup.propertyTimezone]
  );

  return (
    <SDMarkerPopup
      productsAvailable={productsAvailable}
      segmentTracking={segmentTracking}
      mapboxPopup={mapboxPopup}
      isGalleryV2={isGalleryV2}
      fieldName={fieldName}
      photos={photos}
      {...popup}>
      <div className='st-popup__item'>
        <SvgPropertyGlyph />
        <STTypo color={forced_point ? STTypoModels.ColorOptions.SECONDARY : STTypoModels.ColorOptions.PRIMARY}>
          {`${coordinates[1].toFixed(6)}, ${coordinates[0].toFixed(6)}`}
        </STTypo>
        {forced_point && (
          <Tag className='st-tag' color='rgba(111, 160, 138, 0.1)'>
            <STTypo fontWeight={500} type={TypeOptions.C2} color='#6FA08A'>
              {t('pages.timeline.monitoring_window.point_real')}
            </STTypo>
          </Tag>
        )}
      </div>
      {forced_point && (
        <div className='st-popup__item'>
          <div className='st-width--16' />
          <STTypo>{`${forced_point.coordinates[0].toFixed(6)}, ${forced_point.coordinates[1].toFixed(6)}`}</STTypo>
          <Tag className='st-tag' color='rgba(27, 103, 107, 0.1)'>
            <STTypo fontWeight={500} type={TypeOptions.C2} color='#1B676B'>
              {t('pages.timeline.monitoring_window.point_simulated')}
            </STTypo>
          </Tag>
        </div>
      )}
      <div className='st-popup__item'>
        <SvgClockGlyph />
        <STTypo>{dateToScreen.format('DD MMM, YY • HH:mm')}</STTypo>
      </div>
      <div className='st-popup__item st-popup__item-note st-grid-column-gap--4'>
        <SvgAnnotationsGlyph />
        <STTypo>{comments}</STTypo>
      </div>
      {!!enableMultiplePhotos && <InfoTags tags={popup?.tags} />}
      {scouter && (
        <div className='st-popup__item st-popup__item-avatar st-grid-column-gap--4'>
          <STAvatar label={scouter.name} small disabled>
            {getInitials(scouter.name)}
          </STAvatar>
          <STTypo>{scouter.name}</STTypo>
        </div>
      )}
    </SDMarkerPopup>
  );
};

export default SDPopupAnnotation;
