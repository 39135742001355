import { DatePicker, Drawer, Form, Icon, Spin } from 'antd';
import RegionTreeSelectDefault from 'components/SD-region-tree-select/components/region-tree-select-default';
import STTextArea from 'components/textArea/textArea.component';
import FooterMessageAboutImage from 'core/components/header/components/report/drawers/components/footer-message-about-logo/footer-message-about-logo.component';
import AddLogoVisitReport from 'core/components/header/components/report/drawers/components/report-visit-add-logo/report-visit-add-logo.component';
import { selectSystemFlags } from 'core/core.reducer';
import { getRegionEntitiesInEntities } from 'core/core.selectors';
import { selectCurrentUser } from 'core/services/auth/auth.reducer';
import type { UUID } from 'core/utils/basic.models';
import { generateUUID, getCurrentLanguage } from 'core/utils/functions';
import useSegmentTracking from 'core/utils/segment/useSegmentTracking';
import type { AnalyticsInfo } from 'core/utils/segment/user-events.types';
import { getSelectedCompany } from 'entities/company/company.reducer';
import { LoadPropertyPhenomena } from 'entities/property/property.actions';
import { getSelectedProperty } from 'entities/property/property.reducer';
import { getPropertyPhenomenons } from 'entities/property/property.selectors';
import { RequestReport } from 'entities/reports/report.actions';
import type { RequestReportVisitBody } from 'entities/reports/report.models';
import moment from 'moment';
import { updateOrganizationPicture, useGetOrganizationPicture } from 'querys/report/report.query';
import type React from 'react';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import type { AppState } from 'redux/app-state';
import { selectRegionEntities, selectRootRegion } from '../../../../../../entities/region/region.reducer';
import { isConfirmButtonDisabled, useSeasonSelect } from '../functions/report-generate.functions';
import '../report.styles.less';
import { ReportsTypes } from '../reports-types.enum';
import FooterDrawer from './components/footer-drawer.component';
import SelectSeason from './components/season-select.component';
import { ExplicativeText } from './explicative-text.component';
import HeaderTitle from './header-title.component';
import PhenomenaSelectAndLoading from './phenomena-select-and-loading';

const { RangePicker } = DatePicker;
interface ISTReportVisitDrawer {
  visible: ReportsTypes | null;
  callbackDrawer: () => void;
  icon?: any;
}

interface PropsFooterMessage {
  show: boolean;
  title: string;
  message: string;
  enableButton: boolean;
}

const defaultFooterMessage: PropsFooterMessage = { show: false, title: '', message: '', enableButton: false };

const STReportVisitDrawer: React.FC<ISTReportVisitDrawer> = ({ visible, callbackDrawer, icon }) => {
  const initialTimeRangeTo = moment().set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
  const initialTimeRangeFrom = moment().subtract(1, 'd').set({ hour: 0, minute: 0, second: 0, millisecond: 0 });

  const [startTimeRange, setStartTimeRange] = useState(initialTimeRangeFrom);
  const [endTimeRange, setEndTimeRange] = useState(initialTimeRangeTo);
  const [selectedAreas, setSelectedAreas] = useState<UUID[]>([]);
  const [seasonStartDate, setSeasonStartDate] = useState<moment.Moment>();
  const [seasonEndtDate, setSeasonEndDate] = useState<moment.Moment>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [selectedSeason, setSelectedSeason] = useState<UUID>();
  const [selectedPhenomena, setSelectedPhenomena] = useState<UUID[]>([]);
  const [comments, setComments] = useState<string>('');
  const [file, setFile] = useState<File | undefined>();
  const [removeDefaultLogo, setRemoveDefaultLogo] = useState<boolean>(false);
  const [selectedLogo, setSelectedLogo] = useState<boolean>(false);
  const [messageError, setMessageError] = useState<string>();

  const [footerMessage, setFooterMessage] = useState<PropsFooterMessage>(defaultFooterMessage);

  const forceClose = useRef<boolean>(false);
  const systemFlags = useSelector(selectSystemFlags);
  const property = useSelector(getSelectedProperty);
  const regions = useSelector(getRegionEntitiesInEntities);
  const rootRegion = useSelector(selectRootRegion);
  const isRegionsLoaded = useSelector((state: AppState) => state.entities.region.isRegionsLoaded);
  const currentSeasons = useSelector((state: AppState) => state.uiState.global.selectedSeasons);
  const company = useSelector(getSelectedCompany);
  const user = useSelector(selectCurrentUser);
  const dispatch = useDispatch();
  const seasonSelect = useSeasonSelect(selectedSeason);
  const [t] = useTranslation();

  const areas = useSelector((state: AppState) => selectRegionEntities(state.entities.region));
  const propertyPhenomenons = useSelector(getPropertyPhenomenons);

  const { data: imageUrl } = useGetOrganizationPicture(company?.id);
  const addLogoVisitReportIsActive = systemFlags?.P40_25308_add_company_logo_visit_report;

  useEffect(() => {
    if (selectedSeason) {
      const result = seasonSelect.filter(item => item.id === selectedSeason);
      const startDate = moment(result[0].start_date);
      setSeasonStartDate(startDate);
      const endDate = moment(result[0].end_date);
      setSeasonEndDate(endDate);

      const seasonIsClosed = moment(endDate).isBefore(initialTimeRangeTo);
      if (seasonIsClosed) {
        setStartTimeRange(moment(endDate).subtract(1, 'd'));
        setEndTimeRange(endDate);
      } else {
        setStartTimeRange(initialTimeRangeFrom);
        setEndTimeRange(initialTimeRangeTo);
      }
    }
  }, [selectedSeason]);

  useEffect(() => {
    if (property && selectedSeason && selectedSeason !== '' && selectedAreas.length > 0) {
      setSelectedPhenomena([]);
      dispatch(LoadPropertyPhenomena(property.id, [selectedSeason], selectedAreas));
    }
  }, [dispatch, selectedSeason, selectedAreas, property]);

  const onChangeTimeRange = value => {
    if (value?.length) {
      setStartTimeRange(value[0].set({ hour: 0, minute: 0, second: 0, millisecond: 0 }));
      setEndTimeRange(value[1].set({ hour: 0, minute: 0, second: 0, millisecond: 0 }));
    }
  };

  const checkIsGenerateReport =
    !systemFlags?.P40_25308_add_company_logo_visit_report || footerMessage.enableButton || (!removeDefaultLogo && imageUrl);

  const closeDrawerReportVisit = useCallback(() => {
    if (footerMessage.show) {
      setFooterMessage(defaultFooterMessage);
      return;
    }

    callbackDrawer();
    setSelectedAreas([]);
    setStartTimeRange(initialTimeRangeFrom);
    setEndTimeRange(initialTimeRangeTo);
    setSelectedSeason('');
    setSelectedPhenomena([]);
  }, [callbackDrawer, initialTimeRangeTo, initialTimeRangeFrom, footerMessage]);

  useEffect(() => {
    if (forceClose.current) {
      setIsLoading(false);
      closeDrawerReportVisit();
    }
  }, [footerMessage, closeDrawerReportVisit]);

  const handleOnchangeSeason = useCallback((season: string) => {
    setSelectedSeason(season);
    setSelectedAreas([]);
  }, []);

  const onPressConfirm = () => {
    if (checkIsGenerateReport) {
      generateReport();
      return;
    }

    const title = file ? `${t('modals.timeline_xray_report.logo_changed_title')}` : `${t('modals.timeline_xray_report.logo_empty_title')}`;

    const message = file ? `${t('modals.timeline_xray_report.logo_changed_message')}` : `${t('modals.timeline_xray_report.logo_empty')}`;

    setFooterMessage({
      show: true,
      message,
      title,
      enableButton: true
    });
  };

  const segmentTracking = useSegmentTracking();

  const generateReport = async () => {
    setIsLoading(true);

    const resultImage = await updateOrganizationPicture(file, company?.id);

    const fileDataURL = removeDefaultLogo && !file ? null : imageUrl;

    const companyLogo = resultImage?.avatar_url || fileDataURL;

    if (property && company && selectedSeason && user) {
      const requestBody: RequestReportVisitBody = {
        id: generateUUID(),
        property_id: property.id,
        type: ReportsTypes.VISIT,
        data_request: {
          property_id: property.id,
          company_id: company.id,
          language: getCurrentLanguage(),
          season_ids: [selectedSeason],
          area_ids: selectedAreas,
          user_id: user.id,
          phenomenon_ids: selectedPhenomena || undefined,
          comments,
          end_date: endTimeRange.format('YYYY-MM-DD'),
          start_date: startTimeRange.format('YYYY-MM-DD'),
          company_logo: addLogoVisitReportIsActive ? companyLogo : null
        }
      };

      dispatch(RequestReport(requestBody));

      const payloadAnalytics: AnalyticsInfo = {
        companyId: company?.id,
        companyName: company?.name,
        propertyId: property?.id,
        propertyName: property?.name,
        reportType: ReportsTypes.VISIT,
        startDate: startTimeRange.format('YYYY-MM-DD'),
        endDate: endTimeRange.format('YYYY-MM-DD'),
        selectedSeasonId: [selectedSeason],
        selectedAreasId: selectedAreas,
        phenomenonId: selectedPhenomena,
        phenomenonName: propertyPhenomenons
          .filter(item => selectedPhenomena.includes(item.id))
          .map(item => item?.name?.localized_strings?.en),
        selectedAreasName: Object.values(areas)
          .filter(item => selectedAreas.includes(item.id))
          .map(item => item.name),
        selectedSeasonName: seasonSelect[0]?.name,
        comment: comments,
        userId: user.id
      };

      segmentTracking.track('Export Report - Generated Report', payloadAnalytics);
    }

    forceClose.current = true;
    setFooterMessage(defaultFooterMessage);
    setIsLoading(false);
    closeDrawerReportVisit();
  };

  const disableDatesOutRange = (date): boolean => {
    return moment(date).isBefore(seasonStartDate) || moment(date).isAfter(initialTimeRangeTo) || moment(date).isAfter(seasonEndtDate);
  };

  return (
    <Drawer
      className='st-report-drawer'
      placement='right'
      visible={visible === ReportsTypes.VISIT}
      width={400}
      data-testid='report-visit-drawer-container'
      title={<HeaderTitle icon={icon} type={visible} />}
      onClose={closeDrawerReportVisit}
      maskClosable>
      <div className='st-report-drawer__content' data-testid='report-visit-drawer'>
        <ExplicativeText type={visible} />
        <div className='st-report-drawer__form' data-testid='report-visit-form'>
          <Form layout='vertical'>
            {addLogoVisitReportIsActive && (
              <AddLogoVisitReport
                imageUrl={imageUrl}
                file={file}
                setFile={setFile}
                setRemoveDefaultLogo={setRemoveDefaultLogo}
                selectedLogo={selectedLogo}
                setSelectedLogo={setSelectedLogo}
                messageError={messageError}
                setMessageError={setMessageError}
              />
            )}
            <Form.Item label={`${t('modals.timeline_xray_report.fields.crop_cycle')}`}>
              <SelectSeason
                placeholder={t('modals.timeline_xray_report.placeholder.crop_cycle')}
                selectedSeason={selectedSeason}
                setSelectedSeason={handleOnchangeSeason}
                showOnlySeasonsSelected
              />
            </Form.Item>
            <Form.Item data-testid='report-visit-type-date-range' label={t('modals.timeline_xray_report.fields.date_range')}>
              <RangePicker
                value={[startTimeRange, endTimeRange]}
                format='L'
                size='large'
                separator='—'
                onChange={onChangeTimeRange}
                allowClear={false}
                disabledDate={disableDatesOutRange}
              />
            </Form.Item>
            <Form.Item data-testid='report-visit-type-region-select' label={t('modals.timeline_xray_report.fields.areas')}>
              {!isRegionsLoaded && (
                <div className='st-report-drawer__loading'>
                  <Spin indicator={<Icon type='loading' style={{ fontSize: 16 }} spin />} />
                </div>
              )}
              {property && (
                <RegionTreeSelectDefault
                  regions={regions}
                  rootRegionId={rootRegion?.id}
                  selectedSeasonsIds={selectedSeason?.length ? [selectedSeason] : currentSeasons}
                  value={selectedAreas}
                  onChange={setSelectedAreas}
                />
              )}
            </Form.Item>
            <PhenomenaSelectAndLoading
              selected={selectedPhenomena}
              handleSetSelected={e => {
                setSelectedPhenomena(e.slice(0, 7));
              }}
              placeholder={t('modals.timeline_xray_report.fields.phenomena_placeholder_with_max', {
                count: t('modals.timeline_xray_report.fields.seven')
              })}
              disableOptionAll
              disableChildren={selectedPhenomena.length > 6}
              maxSelected={7}
              label={t('modals.timeline_xray_report.fields.phenomena_summary')}
            />
            <Form.Item data-testid='report-visit-type-comments' label={t('modals.timeline_xray_report.fields.comments')}>
              <STTextArea
                setValue={setComments}
                value={comments}
                testID='report-visit-type-comments-input'
                rows={4}
                maxLength={1000}
                alignCounter='right'
              />
            </Form.Item>
          </Form>
        </div>

        {footerMessage.show && <FooterMessageAboutImage title={footerMessage.title} message={footerMessage.message} />}

        <FooterDrawer
          onPressConfirm={onPressConfirm}
          isLoading={isLoading}
          footerMessageShow={footerMessage.show}
          disableConfirmButton={isConfirmButtonDisabled({
            selectedPhenomena,
            filterBySeasonWithTimeRange: false,
            seasonSelect,
            timeRangeFrom: startTimeRange,
            timeRangeTo: endTimeRange,
            selectedAreas,
            selectedSeason
          })}
          onPressCancel={closeDrawerReportVisit}
        />
      </div>
    </Drawer>
  );
};

export default STReportVisitDrawer;
