import axios from 'axios';
import axiosObservable from 'axios-observable';
import type { AxiosObservable } from 'axios-observable/dist/axios-observable.interface';
import { PROTECTOR_API_URL } from 'config/constants';
import type { DateISOString, DateOnlyString, Moment, UUID } from 'core/utils/basic.models';
import type { CurrentSeasonArea } from 'entities/property/property.models';
import type { PhenologicalStagesModel } from 'pages/disease-risk/disease-risk.models';
import qs from 'qs';
import type { Observable } from 'rxjs';
import { of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import type { IEditAreaRequestParams } from './area-day-info/components/area-day-info.resume.models';
import type { MonitoringDetailPhenomenon } from './monitoring.models';
import type {
  RequestTracking,
  RequestTrackingExport,
  SaveEditAreaInfoResponse,
  TimelineBarPage,
  TimelineWindow,
  TimelineWindowEvents,
  TrackingAssigneeResponse
} from './timeline.models';
import { EventType } from './timeline.models';

const protectorApiUrl = PROTECTOR_API_URL ?? 'http://localhost:8080';

const trackings = `${protectorApiUrl}/v1/tracking/query`;
const timelineWindowEventsURL = `${protectorApiUrl}/v1/timeline/window`;
const eventPages = `${protectorApiUrl}/v1/timeline/events`;
const timelinePropertiesEvents = `${protectorApiUrl}/v1/timeline/properties`;
const monitoring = `${protectorApiUrl}/v1/analytics/monitoring`;
const phenology = `${protectorApiUrl}/v1/phenology`;

/** @deprecated v1/season-areas will be deprecated in the future */
const editAreaUrl = `${protectorApiUrl}/v1/season-areas`;
const samplingphotosURL = `${protectorApiUrl}/v1/timeline/photo/`;

/** @deprecated v1/season-areas will be deprecated in the future, if possible use POST v2/season-fields/list */
const seasonAreasUrl = `${protectorApiUrl}/v1/season-areas`;

export const getStationLatestPhoto = (propertyId: UUID, stationId: UUID): Observable<string> => {
  const stationLatestPhotoURL = `${protectorApiUrl}/api/v1/properties/${propertyId}/stations/${stationId}/latest-photo`;
  return axiosObservable.get<string>(stationLatestPhotoURL).pipe(
    // data is photo url
    map(({ data }) => (data === 'default backend - 404' ? '' : data)),
    catchError(() => of(''))
  );
};

export const getSamplingPhoto = (propertyId: UUID, photoId: UUID): AxiosObservable<string> => {
  const fullSamplingPhotoURL = `${samplingphotosURL}${propertyId}/${photoId}.png`;
  return axiosObservable.get<string>(fullSamplingPhotoURL).pipe(
    // data is photo url
    map(({ data }) => (data === 'default backend - 404' ? '' : data)),
    catchError(a => of(a.response))
  );
};

export const MAX_WINDOW_EVENTS = 8;

export const getEvents = (propertyId: string | null, areaId: string | null, seasonIds: string[]): Observable<TimelineWindow[]> => {
  const data = {
    property_id: propertyId,
    area_id: areaId,
    season_ids: seasonIds,
    ignore_events: ['STATIC_POINT']
  };
  return axiosObservable.post<TimelineBarPage>(`${eventPages}`, data).pipe(
    map(response => {
      const window = response.data.window
        .map(w => {
          const result = { ...w, events: w.events.filter(e => e !== EventType.STATIC_POINT) };
          return !result.events.length ? undefined : result;
        })
        .filter(w => w !== undefined);

      return { window };
    }),
    map(timelineWindows => {
      return timelineWindows.window as TimelineWindow[];
    })
  );
};

export const getWindowEvents = (
  property_id: string | null,
  area_id: string,
  season_ids: string[],
  start: Moment,
  end: Moment
): AxiosObservable<TimelineWindowEvents> => {
  return axiosObservable.post<TimelineWindowEvents>(`${timelineWindowEventsURL}`, {
    property_id,
    area_id,
    season_ids,
    start,
    end
  });
};

export const saveEditAreaInfo = (requestParams: IEditAreaRequestParams) => {
  return axiosObservable.put<SaveEditAreaInfoResponse>(`${editAreaUrl}?update_harvesting_date=true`, requestParams);
};

export const getTimelineProperties = async (propertyId: UUID): Promise<PhenologicalStagesModel[]> => {
  return axios.get<PhenologicalStagesModel[]>(`${timelinePropertiesEvents}/${propertyId}/phenologies`).then(response => response.data);
};

export const getTrackings = (request: RequestTracking) => {
  return axiosObservable.post<TrackingAssigneeResponse>(trackings, request).pipe(
    map(response => response.data.assignees),
    catchError(error => {
      throw error;
    })
  );
};

export const getExportTracking = (request: RequestTrackingExport) => {
  return axiosObservable.post(trackings, request);
};

export const getSeasonAreas = (propertyId: UUID, pastSeasonAreas: boolean, seasonId?: string[]) => {
  return axiosObservable
    .get<CurrentSeasonArea[]>(
      `${seasonAreasUrl}?propertyId=${propertyId}&allowPastSeasonAreas=${pastSeasonAreas}${
        seasonId ? `&seasonIds=${seasonId.join(',')}` : ''
      }`
    )
    .pipe(map(response => response.data));
};

export interface MonitoringPointRequest {
  property_id: UUID;
  monitoring_id: UUID;
  date: DateOnlyString;
}

export interface MonitoringPointResponse {
  id: UUID;
  date: DateISOString;
  coordinates: GeoJSON.Point;
  forced_coordinates?: GeoJSON.Point;
  real_coordinates: GeoJSON.Point;
  scouter_id: UUID;
  scouter_name: string;
  phenomena: MonitoringDetailPhenomenon[];
  indicators: MonitoringDetailPhenomenon[];
  notes: string[];
  photos: UUID[];
}

export const getMonitoringPoint = (props: MonitoringPointRequest): Observable<MonitoringPointResponse> => {
  const url = `${monitoring}?${qs.stringify(props)}`;
  return axiosObservable
    .get<MonitoringPointResponse>(url, { headers: { 'Content-Type': 'application/json' } })
    .pipe(map(response => response.data));
};

export const deletePhenology = (phenologyId: UUID) => axiosObservable.delete(`${phenology}/${phenologyId}/delete`);
