import moment from 'moment';
import type { Moment } from './basic.models';

/**
 * Return a date that represents the same date received as parameter, but without the time information.
 * @param dt date
 * @returns a date that represents the same date received as parameter, but without the time information.
 */
export const dateTimeToDate = (dt: Date): Date => {
  return new Date(dt.toDateString());
};

/**
 * Return the difference in days between two dates (date2 - date1)
 * @param date1 date1
 * @param date2 date2
 * @returns the difference in days between two dates
 */
export const getDifferenceInDays = (date1: Date, date2: Date): number => {
  const diffInMs = date2.getTime() - date1.getTime();
  return diffInMs / (1000 * 60 * 60 * 24);
};

/**
 * Returns whether a date has the same year as the other
 * @param date1 date1
 * @param date2 date2
 * @returns true or false year comparison
 */
export const isEqualYear = (date1: string, date2: string): boolean => {
  return date1.split('-')[0] === date2.split('-')[0];
};

/**
 * Returns date without time
 * @param date date
 * @returns date format ISO
 */
export const removeTimeOfDate = (date: string): string => {
  return moment(date).format('YYYY-MM-DD');
};

/**
 * Returns year of date
 * @param date date string
 * @param amountDigits number of digits
 * @returns year of date
 */
export const yearOfDate = (date: string, amountDigits?: number): string => {
  if (amountDigits === 2) {
    return moment(date).format('YY');
  }
  return moment(date).format('YYYY');
};

/**
 * Returns a date list interval [{ start: Moment, end: Moment }] splitted by days
 * @param startDate date moment
 * @param endDate date moment
 * @param days number of days
 * @param maxInterval max interval size
 * @returns a date list splitted by days
 */
export const splitDateByDays = (startDate: Moment, endDate: Moment, days: number, maxInterval = 5): { start: Moment; end: Moment }[] => {
  if (!startDate || !endDate || !days) return [];

  let totalInterval = 0;
  let currentDate = endDate;
  const splittedDates: { start: Moment; end: Moment }[] = [];

  do {
    const end = currentDate;
    currentDate = end.clone().subtract(days, 'days');

    splittedDates.push({
      start: moment(currentDate).isSameOrAfter(startDate) ? currentDate : startDate,
      end: end.clone()
    });
    totalInterval++;
  } while (moment(currentDate).isSameOrAfter(startDate) && totalInterval < maxInterval);

  return splittedDates;
};

/**
 * Returns whether the date is between a range of dates
 * @param targetDate date to be checked for range
 * @param startDate initial date of range
 * @param endDate end date of range
 */
export const isDateBetween = (targetDate: string, startDate: string, endDate: string): boolean => {
  const targetMoment = moment(targetDate);
  const startMoment = moment(startDate);
  const endMoment = moment(endDate);
  return targetMoment.isBetween(startMoment, endMoment);
};
