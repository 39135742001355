export const icons: Record<string, string> = {
  apple: 'apple',
  bean: 'bean',
  blueberry: 'blueberry',
  avocado: 'avocado',
  coffee: 'coffee-bean',
  grape: 'grape',
  rice: 'rice',
  citrus: 'lemon',
  wheat: 'wheat',
  barley: 'wheat',
  watermelon: 'watermelon',
  tomato: 'tomato',
  cotton: 'cotton',
  lemon: 'lemon',
  soybeans: 'soybeans',
  sugarcane: 'sugarcane',
  canaindica: 'sugarcane',
  corn: 'corn',
  orange: 'corn'
};
