import { Radio } from 'antd';
import STTypo, { STTypoModels } from 'core/shared/typo';
import { ColorOptions } from 'core/shared/typo/typo.models';
import type { UUID } from 'core/utils/basic.models';
import { isActive } from 'entities/season/season.functions';
import type { Season } from 'entities/season/season.models';
import { Set as ImmutableSet } from 'immutable';
import type React from 'react';
import { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import type { Dictionary } from 'config/types';
import { BreadcrumbSeasonsGroup } from './breadcrumb-seasons.enum';

interface IBreadcrumbSeasonList {
  handleCheckChange: (id) => void;
  seasonsSelected: UUID | null;
  groupSelected: BreadcrumbSeasonsGroup;
  activeSortedGroupKeys: ImmutableSet<UUID> | undefined;
  inactiveSortedGroupKeys: ImmutableSet<UUID> | undefined;
  seasonsGroups: Dictionary<Season[]>;
  onSelectInactive: (id) => void;
  inactiveSelected: UUID | undefined;
}

const BreadcrumbSeasonList: React.FC<IBreadcrumbSeasonList> = ({
  handleCheckChange,
  seasonsSelected,
  groupSelected,
  activeSortedGroupKeys,
  inactiveSortedGroupKeys,
  seasonsGroups,
  onSelectInactive,
  inactiveSelected
}) => {
  const [t] = useTranslation();

  const removeDuplicatedSesions = (allSeasons): Season[] => {
    return ImmutableSet(allSeasons).toArray() as Season[];
  };

  return (
    <div className='st-seasons-list'>
      <Radio.Group onChange={handleCheckChange} value={seasonsSelected} disabled={groupSelected === BreadcrumbSeasonsGroup.ALL_ACTIVES}>
        {activeSortedGroupKeys && activeSortedGroupKeys.toArray().length > 0 && (
          <div className='st-seasons--select-actives'>
            {activeSortedGroupKeys.map((year, idx) => {
              return (
                <div className='st-seasons--season-group' key={idx}>
                  <h3 className='st-seasons--group-name'>
                    <STTypo
                      type={STTypoModels.TypeOptions.H5}
                      color={groupSelected === BreadcrumbSeasonsGroup.ALL_ACTIVES ? '#C1C5C8' : ColorOptions.SECONDARY}
                      uppercase
                      fontWeight={500}>
                      {`${t('entities.season.key')} ${year}`}
                    </STTypo>
                  </h3>
                  <div className='st-seasons--season-group-item' data-testid='seasons-group'>
                    {seasonsGroups[year] &&
                      removeDuplicatedSesions(seasonsGroups[year]).map((season, seasonIdx) => (
                        <Fragment key={idx + seasonIdx}>
                          {isActive(season) && (
                            <div key={idx + seasonIdx} data-testid='season-select-active'>
                              <Radio value={season.id} data-testid={`season-select-active-${seasonIdx}`}>
                                <div className='st-seasons--season-checkbox-text'>
                                  <STTypo nowrap maxlength={50}>
                                    {season.name}
                                  </STTypo>
                                </div>
                              </Radio>
                            </div>
                          )}
                        </Fragment>
                      ))}
                  </div>
                </div>
              );
            })}
          </div>
        )}
      </Radio.Group>
      <Radio.Group onChange={onSelectInactive} value={inactiveSelected} disabled={groupSelected === BreadcrumbSeasonsGroup.ALL_ACTIVES}>
        {inactiveSortedGroupKeys && inactiveSortedGroupKeys.toArray().length > 0 && (
          <>
            <p className='st-seasons--section-title'>
              <STTypo
                fontWeight={500}
                color={groupSelected === BreadcrumbSeasonsGroup.ALL_ACTIVES ? '#C1C5C8' : ColorOptions.SECONDARY}
                uppercase>
                {t('general.context_select.season_select.inactives_seasons')}
              </STTypo>
            </p>

            <div className='st-seasons--season-group'>
              <div className='st-seasons--season-group-item' data-testid='seasons-group'>
                {inactiveSortedGroupKeys.map(
                  (year, idx) =>
                    seasonsGroups[year] &&
                    removeDuplicatedSesions(seasonsGroups[year]).map((season, seasonIdx) => (
                      <Fragment key={idx + seasonIdx}>
                        {!isActive(season) && (
                          <div key={idx + seasonIdx} data-testid='season-select-inactive'>
                            <Radio value={season.id} data-testid={`season-select-inactive-${seasonIdx}`}>
                              <STTypo nowrap maxlength={50}>
                                {season.name}
                              </STTypo>
                            </Radio>
                          </div>
                        )}
                      </Fragment>
                    ))
                )}
              </div>
            </div>
          </>
        )}
      </Radio.Group>
    </div>
  );
};

export default BreadcrumbSeasonList;
