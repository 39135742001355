import axios from 'axios-observable';
import type { AxiosObservable } from 'axios-observable/dist/axios-observable.interface';
import { CORE_SERVICES_API_URL, PROTECTOR_API_URL, PROTECTOR_APP_ID } from 'config/constants';
import type { PageableQuery, UUID, V4Page } from 'core/utils/basic.models';
import { mockPageableDataInAxiosResponse } from 'core/utils/functions';
import { map } from 'rxjs/operators';
import type { Company, CompanyDetails, CompanyPage, CompanyPageResponse, LicensingStatusResponse } from './company.models';

const protectorApiUrl = PROTECTOR_API_URL;
const coreServicesApiUrl = CORE_SERVICES_API_URL || 'http://localhost:8080';
const queryString = '?includeSeasons=no&includeCrops=no';
const assetsUrl = `${protectorApiUrl}/v1/companies/`;
const assetsUrlV2 = `${coreServicesApiUrl}/v2/orgs/`;
const usersCompany = (id: string) => `${coreServicesApiUrl}/v1/companies/${id}/users`;
const licensingStatusUrl = `${coreServicesApiUrl}/v2/licensing/status`;
export const apiURL = CORE_SERVICES_API_URL ?? 'http://localhost:8080';

export const getCompanyPage = (_query: PageableQuery, timeout: number): AxiosObservable<CompanyPage> => {
  const query = `${queryString}&filteredByAppId=${PROTECTOR_APP_ID}`;
  const companyUrl = `${protectorApiUrl}/v1/panel/companies${query}`;
  return axios
    .get<CompanyPage>(`${companyUrl}`, { timeout })
    .pipe(map(e => mockPageableDataInAxiosResponse(e))) as AxiosObservable<CompanyPage>;
};

export const getCompaniesWithActiveContracts = (): CompanyPageResponse => {
  const query = `app_id=${PROTECTOR_APP_ID}&include_inactive_contracts=false`;

  return axios.get<V4Page<Company>>(`${apiURL}/v2/orgs?${query}`).pipe(
    map(response => ({
      data: {
        number: 0,
        size: 1000,
        pages: 1,
        total: response.data.content.length,
        mutableContent: response.data.content
      }
    }))
  );
};

export const getAssetsByCompany = (companyId: UUID) => {
  return axios.get<any>(`${assetsUrlV2}${companyId}/equipments`).pipe(map(response => response.data.content));
};

export const getUsersByCompany = (companyId: UUID) => {
  return axios.get<any>(usersCompany(companyId)).pipe(map(response => response.data));
};

export const getSeasonsTreeByCompany = (companyId: UUID) => {
  return axios.get<any>(`${assetsUrl}${companyId}/seasons/tree`).pipe(
    map(response => {
      return response;
    })
  );
};

export const getOrgLicensingStatus = (orgId: UUID) => {
  return axios.get<LicensingStatusResponse>(`${licensingStatusUrl}?org=${orgId}&apps=all`).pipe(map(response => response.data));
};

export const getCompanyDetails = (companyId: UUID) => {
  return axios.get<CompanyDetails>(`${assetsUrl}${companyId}`).pipe(map(response => response.data));
};
