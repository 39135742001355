import type { AxiosResponse } from 'axios';
import axios from 'axios';

import axiosObservable from 'axios-observable';
import type { AxiosObservable } from 'axios-observable/dist/axios-observable.interface';
import { PROTECTOR_API_URL } from 'config/constants';
import type { UUID } from 'core/utils/basic.models';
import { chunk } from 'lodash';
import type {
  ConfigurationParameterDTO,
  pageRiskDTO,
  ParametersDataDTO,
  ParametersDataPage,
  UpdateParametersRequest
} from 'pages/borer-risk/borer-risk-map/borer-risk.models';

const protectorApiUrl = PROTECTOR_API_URL ?? 'http://localhost:8080';

export const saveConfigurationParameters = (body: UpdateParametersRequest, property_id: UUID, season_id: UUID): AxiosObservable<any> => {
  return axiosObservable.post(`${protectorApiUrl}/api/v1/properties/${property_id}/seasons/${season_id}/pests-risk/configuration`, body);
};

export const getConfigurationParameters = (property_id: UUID, season_id: UUID): AxiosObservable<any> => {
  return axiosObservable.get(`${protectorApiUrl}/api/v1/properties/${property_id}/seasons/${season_id}/pests-risk/configuration`);
};

export const getRiskConfigurationParameters = (
  property_id: UUID,
  season_id: UUID
): Promise<AxiosResponse<{ pr: ConfigurationParameterDTO[] }>> => {
  return axios.get(`${protectorApiUrl}/api/v1/properties/${property_id}/seasons/${season_id}/pests-risk/configuration`);
};

const getParametersDataPage = (
  property_id: UUID,
  season_id: UUID,
  cursor: string | null,
  size = 1000
): Promise<AxiosResponse<ParametersDataPage>> => {
  const baseUrl = `${protectorApiUrl}/api/v1/properties/${property_id}/seasons/${season_id}/pests-risk/data`;
  let queryParams = `?paginated=true&size=${size}`;
  if (cursor) {
    queryParams += `&cursor=${cursor}`;
  }
  const url = baseUrl + queryParams;
  return axios.get(url);
};

export const getParametersData = async (property_id: UUID, season_id: UUID): Promise<ParametersDataDTO> => {
  let cursor = null;
  let isLast = false;

  const parametersData: ParametersDataDTO = {
    property_id,
    season_id,
    areas_pr: []
  };
  while (!isLast) {
    const response = await getParametersDataPage(property_id, season_id, cursor);
    cursor = response.data.cursor;
    isLast = response.data.is_last;
    parametersData.areas_pr = parametersData.areas_pr.concat(response.data.content.areas_pr);
  }
  return parametersData;
};

export const getBorerRiskDataPage = (
  property_id: UUID,
  season_id: UUID,
  size = 500,
  cursor: string | null,
  year?: string
): AxiosObservable<pageRiskDTO> => {
  const url = `${protectorApiUrl}/api/v1/properties/${property_id}/seasons/${season_id}/pests-risk/risks?size=${size}${
    year ? `&year=${year}` : ''
  }${cursor ? `&cursor=${cursor}` : ''}`;

  return axiosObservable.get(url);
};

export const saveParametersData = (parametersData: ParametersDataDTO): Promise<AxiosResponse<ParametersDataDTO>> => {
  return axios.post(
    `${protectorApiUrl}/api/v1/properties/${parametersData.property_id}/seasons/${parametersData.season_id}/pests-risk/data`,
    parametersData
  );
};

interface ParameterErrorResponse {
  area_id: string;
  area_error: string;
}
export const saveParametersDataInBatches = async (parametersData: ParametersDataDTO): Promise<void> => {
  const parametersBatches = chunk(parametersData.areas_pr, 1000);
  const promises = parametersBatches.map(parametersBatch =>
    saveParametersData({
      property_id: parametersData.property_id,
      season_id: parametersData.season_id,
      areas_pr: parametersBatch
    })
  );
  const responses = await Promise.allSettled(promises);
  const errorData: ParameterErrorResponse[] = [];
  responses.forEach(response => {
    if (response.status === 'rejected') {
      const reason = response.reason as { response: { data: { message: string } } };
      const data = reason.response.data;
      const message = data.message ?? '[]';
      errorData.push(...(JSON.parse(message) as ParameterErrorResponse[]));
    }
  });
  if (errorData.length) {
    throw Error(JSON.stringify(errorData));
  }
};
