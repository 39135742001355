import { Button, Checkbox, DatePicker, Drawer, Form, Popconfirm, Skeleton, TreeSelect } from 'antd';
import type { Dictionary } from 'config/types';
import type { IDateRange } from 'core/core.models';
import { selectCurrentUser } from 'core/services/auth/auth.reducer';
import STTypo from 'core/shared/typo';
import { ColorOptions, TypeOptions } from 'core/shared/typo/typo.models';
import type { UUID } from 'core/utils/basic.models';
import { generateUUID, getCurrentLanguage } from 'core/utils/functions';
import useSegmentTracking from 'core/utils/segment/useSegmentTracking';
import type { AnalyticsInfo } from 'core/utils/segment/user-events.types.ts';
import { getSelectedCompany } from 'entities/company/company.reducer';
import { getSelectedProperty } from 'entities/property/property.reducer';
import { RequestReport } from 'entities/reports/report.actions';
import type { RequestReportBody } from 'entities/reports/report.models';
import type { Season } from 'entities/season/season.models';
import { LoadStaticPointsTemplates } from 'entities/static-points/static-points.actions';
import type { StaticPointTemplate } from 'entities/static-points/static-points.models';
import { selectStaticPointsTemplateBySelectedCompany } from 'entities/static-points/static-points.reducer';
import { LoadWarehouse } from 'entities/warehouse/warehouse.actions';
import type { WarehouseResponse } from 'entities/warehouse/warehouse.models';
import isEmpty from 'lodash/isEmpty';
import moment from 'moment';
import { FixedPointType } from 'pages/fixed-points/fixed-points.models';
import type React from 'react';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import type { AppState } from 'redux/app-state';
import SvgAlertGlyph from 'st-protector-icons/Glyph/AlertGlyph';
import '../report.styles.less';
import { ReportsTypes } from '../reports-types.enum';
import { ExplicativeText } from './explicative-text.component';
import HeaderTitle from './header-title.component';
import { handleSetDateRange } from './report-trap-warehouse.functions.ts';

const { RangePicker } = DatePicker;
const { TreeNode } = TreeSelect;

export interface ISTReportTrapWarehouse {
  visible: boolean;
  callbackDrawer: () => void;
  type: ReportsTypes | null;
  icon?: any;
}

const STReportTrapWarehouse: React.FC<ISTReportTrapWarehouse> = ({ visible, callbackDrawer, type, icon }) => {
  const [t] = useTranslation();
  const [selectedWarehouses, setSelectedWarehouses] = useState<UUID[]>([]);
  const [warehousesFiltered, setWarehousesFiltered] = useState<any[]>([]);
  const [selectedTemplates, setSelectedTemplates] = useState<UUID[]>([]);
  const [filteredTemplates, setFilteredTemplates] = useState<StaticPointTemplate[]>([]);
  const [timeRangeFrom, setTimeRangeFrom] = useState(
    moment().subtract(7, 'd').set({
      hour: 0,
      minute: 0,
      second: 0,
      millisecond: 0
    })
  );
  const [timeRangeTo, setTimeRangeTo] = useState(moment().set({ hour: 0, minute: 0, second: 0, millisecond: 0 }));
  const [hasOneWarehouse, setHasOneWarehouse] = useState<boolean>(false);
  const [dateRange, setDateRange] = useState<IDateRange | undefined>();
  const selectedSeasons = useSelector<AppState, string[]>(state => state.uiState.global.selectedSeasons);
  const allSeasons = useSelector<AppState, Dictionary<Season>>(state => state.entities.season.entities);
  const warehouses = useSelector<AppState, Dictionary<WarehouseResponse>>(state => state.entities.warehouse.list);
  const templates = useSelector<AppState, StaticPointTemplate[]>(state => selectStaticPointsTemplateBySelectedCompany(state));
  const property = useSelector((state: AppState) => getSelectedProperty(state));
  const company = useSelector((state: AppState) => getSelectedCompany(state));
  const user = useSelector(selectCurrentUser);
  const dispatch = useDispatch();

  useEffect(() => {
    if (visible && property && !templates.length && type === ReportsTypes.TRAP) {
      dispatch(LoadStaticPointsTemplates(property.id));
    }
  }, [visible, dispatch, property, templates, type]);

  const resetDate = (referenceDate?) => {
    setTimeRangeTo(moment(referenceDate).set({ hour: 0, minute: 0, second: 0, millisecond: 0 }));
    setTimeRangeFrom(moment(referenceDate).subtract(7, 'd').set({ hour: 0, minute: 0, second: 0, millisecond: 0 }));
  };

  useEffect(() => {
    if (!selectedSeasons?.length || isEmpty(allSeasons)) return;

    const allSeasonsKeys = Object.keys(allSeasons);
    const isInvalidSelectedSeasons = !selectedSeasons?.every(season => allSeasonsKeys?.includes(season));

    if (isInvalidSelectedSeasons) return;

    handleSetDateRange(selectedSeasons, allSeasons, visible, setDateRange, resetDate);
  }, [selectedSeasons, allSeasons, visible]);

  useEffect(() => {
    if (type === ReportsTypes.WAREHOUSE_INVENTORY) {
      if (company && warehouses && warehouses[company.id]) {
        setWarehousesFiltered(warehouses[company.id] as any);
      } else if (company && !warehouses?.[company.id]) {
        dispatch(LoadWarehouse(company.id));
      }
    }
  }, [company, warehouses, dispatch, type]);

  useEffect(() => {
    if (templates.length && type === ReportsTypes.TRAP) {
      const filtered = templates.filter(template => template.static_point.class_name === FixedPointType.TRAP.toLowerCase());
      setFilteredTemplates(filtered);
    }
  }, [templates, type]);

  useEffect(() => {
    if (warehousesFiltered.length === 1) {
      setHasOneWarehouse(true);
      setSelectedWarehouses(warehousesFiltered.map(item => item.id));
    } else {
      setHasOneWarehouse(false);
    }
  }, [warehousesFiltered, visible]);

  const handleSelectWarehouses = value => {
    if (selectedWarehouses.length && selectedWarehouses.includes(value)) {
      setSelectedWarehouses(selectedWarehouses.filter(item => item !== value));
    } else {
      setSelectedWarehouses([...selectedWarehouses, value]);
    }
  };

  const handleSelectAllWarehouses = allWarehouses => {
    if (allWarehouses.length && selectedWarehouses.length !== allWarehouses.length) {
      setSelectedWarehouses(warehousesFiltered.map(item => item.id));
    } else {
      setSelectedWarehouses([]);
    }
  };

  const handleTimeRange = value => {
    if (value?.length) {
      setTimeRangeFrom(value[0].set({ hour: 0, minute: 0, second: 0, millisecond: 0 }));
      setTimeRangeTo(value[1].set({ hour: 0, minute: 0, second: 0, millisecond: 0 }));
    }
  };

  const handleSelectTemplate = value => {
    setSelectedTemplates(value);
  };

  const closeDrawer = useCallback(() => {
    callbackDrawer();
    setSelectedTemplates([]);
    setSelectedWarehouses([]);
  }, [callbackDrawer]);

  const segmentTracking = useSegmentTracking();

  const setSegmentInfo = useCallback(
    (reportType: ReportsTypes) => {
      const analyticsInfo: AnalyticsInfo = {
        companyId: company?.id || '',
        companyName: company?.name || '',
        propertyId: property?.id,
        propertyName: property?.name,
        reportType,
        from: 'Export Report'
      };

      switch (type) {
        case ReportsTypes.TRAP:
          if (selectedTemplates[0] === 'all') {
            segmentTracking.track('Export Report - Generated Report', {
              ...analyticsInfo,
              trapType: filteredTemplates.map(trap => trap.static_point.name)
            });
          } else {
            segmentTracking.track('Export Report - Generated Report', {
              ...analyticsInfo,
              trapType: Object.values(filteredTemplates)
                .filter(template => selectedTemplates.includes(template.static_point.id))
                .map(trap => trap.static_point.name)
            });
          }
          break;
        case ReportsTypes.WAREHOUSE_INVENTORY:
          segmentTracking.track('Export Report - Generated Report', {
            ...analyticsInfo,
            selectedWarehouseId: Object.values(warehousesFiltered)
              .filter(filteredWarehouses => selectedWarehouses.includes(filteredWarehouses.id))
              .map(warehouse => warehouse.id),
            selectedWarehouseName: Object.values(warehousesFiltered)
              .filter(filteredWarehouses => selectedWarehouses.includes(filteredWarehouses.id))
              .map(warehouse => warehouse.name)
          });
          break;
        default:
          break;
      }
    },
    [company, property, type, filteredTemplates, selectedTemplates, warehousesFiltered, selectedWarehouses]
  );

  const onConfirm = () => {
    if (property && company && user && type) {
      let template_ids = selectedTemplates;
      if (type === ReportsTypes.TRAP && template_ids.length === 1 && template_ids[0] === 'all') {
        template_ids = filteredTemplates.map(item => item.static_point.id);
      }
      let warehouse_ids = selectedWarehouses;
      if (warehouse_ids.length === 1 && warehouse_ids[0] === 'all') {
        warehouse_ids = warehousesFiltered.map(item => item.id);
      }

      const requestBody: RequestReportBody = {
        id: generateUUID(),
        property_id: property.id,
        type,
        data_request: {
          property_id: property.id,
          company_id: company.id,
          language: getCurrentLanguage(),
          user_id: user.id,
          ...{
            ...(type === ReportsTypes.TRAP && {
              template_ids,
              end_date: timeRangeTo.format('YYYY-MM-DD'),
              start_date: timeRangeFrom.format('YYYY-MM-DD')
            })
          },
          ...{ ...(type === ReportsTypes.WAREHOUSE_INVENTORY && { warehouse_ids }) }
        }
      };

      setSegmentInfo(type);
      dispatch(RequestReport(requestBody));
    }
    closeDrawer();
  };

  const disableDatesOutRange = (date): boolean => {
    return moment(date).isAfter(dateRange?.to) || moment(date).isBefore(dateRange?.from);
  };

  return (
    <Drawer
      className='st-report-drawer'
      placement='right'
      visible={visible}
      width={400}
      title={<HeaderTitle icon={icon} type={type} />}
      onClose={closeDrawer}
      maskClosable>
      <div className='st-report-drawer__content' data-testid={`report-${type === ReportsTypes.TRAP ? 'trap' : 'warehouse'}-drawer`}>
        <ExplicativeText type={type} />
        <div className='st-report-drawer__form' data-testid={`report-${type === ReportsTypes.TRAP ? 'trap' : 'warehouse'}-form`}>
          <Form layout='vertical'>
            {type === ReportsTypes.TRAP && (
              <>
                <Form.Item
                  data-testid={`report-${type === ReportsTypes.TRAP ? 'trap' : 'warehouse'}-type-timerange`}
                  label={t('modals.trap_report.fields.time_range')}>
                  <RangePicker
                    value={[timeRangeFrom, timeRangeTo]}
                    format='L'
                    size='large'
                    data-testid={`report-${type === ReportsTypes.TRAP ? 'trap' : 'warehouse'}-type-timerange-picker`}
                    separator='—'
                    onChange={handleTimeRange}
                    allowClear={false}
                    disabledDate={dateRange ? disableDatesOutRange : undefined}
                  />
                </Form.Item>
                <Form.Item
                  data-testid={`report-${type === ReportsTypes.TRAP ? 'trap' : 'warehouse'}-type-region-select`}
                  label={t('modals.trap_report.fields.type')}>
                  <TreeSelect
                    disabled={false}
                    onChange={handleSelectTemplate}
                    treeNodeFilterProp='title'
                    treeCheckable
                    multiple
                    placeholder={t('modals.trap_report.fields.placeholder')}
                    size='large'
                    showCheckedStrategy='SHOW_PARENT'
                    data-testid={`report-${type === ReportsTypes.TRAP ? 'trap' : 'warehouse'}-selector`}
                    value={selectedTemplates as any}>
                    <TreeNode
                      value='all'
                      title={t('modals.trap_report.fields.all_traps')}
                      key='template-tree-all'
                      data-testid={`report-${type === ReportsTypes.TRAP ? 'trap' : 'warehouse'}-alltraps`}>
                      {filteredTemplates.map((template, index) => {
                        return (
                          <TreeNode
                            value={template.static_point.id}
                            title={template.static_point.name}
                            key={`template-tree-${index}`}
                            data-testid={`report-${type === ReportsTypes.TRAP ? 'trap' : 'warehouse'}-trapitem`}
                          />
                        );
                      })}
                    </TreeNode>
                  </TreeSelect>
                </Form.Item>
              </>
            )}
            {type === ReportsTypes.WAREHOUSE_INVENTORY && (
              <>
                <div className='st-report-drawer__wrapper'>
                  <STTypo color={ColorOptions.PRIMARY} fontWeight={500}>
                    {`${t('report.warehouse.select_inventory')}`}
                  </STTypo>

                  <div
                    data-testid='report-warehouse-select-all'
                    className={`${
                      hasOneWarehouse ? 'st-report-drawer__wrapper-has-one-warehouse' : 'st-report-drawer__wrapper-select-all'
                    }`}
                    onClick={() => !hasOneWarehouse && handleSelectAllWarehouses(warehousesFiltered)}>
                    <STTypo color={hasOneWarehouse ? '#C1C5C8' : ColorOptions.SUCCESS}>{`${t('report.warehouse.select_all')}`}</STTypo>
                  </div>
                </div>
                {!warehousesFiltered.length ? (
                  <Skeleton paragraph={{ rows: 6 }} active title />
                ) : (
                  warehousesFiltered.map((item, index) => {
                    return (
                      <>
                        <Form.Item key={index} className='st-report-drawer__form' data-testid='report-warehouse-type-region-select'>
                          <Checkbox
                            data-testid={`report-warehouse-checkbox-${index}`}
                            disabled={hasOneWarehouse}
                            className='st-report-drawer__form-checkbox'
                            onClick={() => handleSelectWarehouses(item.id)}
                            checked={selectedWarehouses.includes(item.id)}>
                            <STTypo color={ColorOptions.SECONDARY} data-testid={`report-warehouse-label-${index}`}>
                              {item.name}
                            </STTypo>
                          </Checkbox>
                        </Form.Item>
                        <div className='st-report-drawer__divider' />
                      </>
                    );
                  })
                )}
              </>
            )}
          </Form>
        </div>
        <div className='st-report-drawer__actions'>
          <Popconfirm
            placement='bottomRight'
            icon={
              <div className='st-report-drawer__confirm-icon-container'>
                <SvgAlertGlyph fill='#F74141' />
              </div>
            }
            overlayClassName='st-report-drawer__confirm'
            title={
              <div className='st-report-drawer__confirm-delete'>
                <STTypo type={TypeOptions.P1}>{`${t('report.back')}`}</STTypo>
              </div>
            }
            onConfirm={() => closeDrawer()}
            okText={t('general.complete')}
            okButtonProps={{ className: 'st-report__confirm_delete' }}
            cancelButtonProps={{ className: 'st-report__cancel_delete' }}
            cancelText={t('general.back')}>
            <Button
              type='ghost'
              size='default'
              className='st-report-drawer_actions-cancel'
              data-testid={`report-${type === ReportsTypes.TRAP ? 'trap' : 'warehouse'}-cancel`}>
              {t('general.cancel')}
            </Button>
          </Popconfirm>
          <Button
            type='primary'
            size='default'
            className='st-report-drawer_actions-submit'
            onClick={onConfirm}
            disabled={type === ReportsTypes.TRAP ? !selectedTemplates.length : !selectedWarehouses.length}
            data-testid={`report-${type === ReportsTypes.TRAP ? 'trap' : 'warehouse'}-submit`}>
            {t('report.action')}
          </Button>
        </div>
      </div>
    </Drawer>
  );
};

export default STReportTrapWarehouse;
