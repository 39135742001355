import type { Season } from 'entities/season/season.models';
import moment from 'moment';
import { useSelector } from 'react-redux';
import type { AppState } from 'redux/app-state';
import { ReportsTypes } from '../reports-types.enum';

export const handleType = (type: ReportsTypes): string => {
  if (type === ReportsTypes.PROPERTY) return 'property';
  if (type === ReportsTypes.SAMPLE) return 'sample';
  if (type === ReportsTypes.SAMPLING) return 'sample_points';
  if (type === ReportsTypes.SAMPLING_IMPORT) return 'sampling';
  if (type === ReportsTypes.SCOUTING) return 'scouting';
  if (type === ReportsTypes.SPRAY) return 'spray';
  if (type === ReportsTypes.TRACKING) return 'tracking';
  if (type === ReportsTypes.TRAP) return 'trap';
  if (type === ReportsTypes.WAREHOUSE_INVENTORY) return 'warehouse';
  if (type === ReportsTypes.MONITORING_DETAILED) return 'report_detailed';
  if (type === ReportsTypes.MONITORING_CONSOLIDATED) return 'report_consolidated';
  if (type === ReportsTypes.RAIN_GAUGE_REPORT) return 'rain_gauge';
  if (type === ReportsTypes.VISIT) return 'visit';
  if (type === ReportsTypes.DISEASE_RISK) return 'disease_risk';
  if (type === ReportsTypes.CONTROL_STRATEGIES) return 'control_strategy';
  return '';
};

export const calculateDate = (season: Season[], timeFrom: moment.Moment, timeTo: moment.Moment): boolean => {
  const dateStart = new Date(season[0]?.start_date.toString());
  const dateEnd = new Date(season[0]?.end_date.toString());
  const timeRangeFromConvert = new Date(timeFrom.toDate().toString());
  const timeRangeToConvert = new Date(timeTo.toDate().toString());
  return (
    (timeRangeFromConvert >= dateStart && timeRangeFromConvert < dateEnd) ||
    (timeRangeToConvert <= dateEnd && timeRangeToConvert > dateStart)
  );
};

export const disabledDate = current => {
  return current && current > moment().endOf('day');
};

export interface PropsIsConfirmButtonDisabled {
  selectedPhenomena: string[];
  filterBySeasonWithTimeRange: boolean;
  seasonSelect: Season[];
  timeRangeFrom: moment.Moment;
  timeRangeTo: moment.Moment;
  selectedAreas: string[];
  selectedSeason: string | undefined;
}

export const isConfirmButtonDisabled = ({
  selectedPhenomena,
  filterBySeasonWithTimeRange,
  seasonSelect,
  timeRangeFrom,
  timeRangeTo,
  selectedAreas,
  selectedSeason
}: PropsIsConfirmButtonDisabled): boolean => {
  const willDisableByPhenomena = !selectedPhenomena.length;
  if (filterBySeasonWithTimeRange) {
    const dateActiveButton = calculateDate(seasonSelect, timeRangeFrom, timeRangeTo);
    return !selectedAreas.length || !selectedSeason || willDisableByPhenomena || !dateActiveButton;
  }
  return !selectedAreas.length || !selectedSeason || willDisableByPhenomena;
};

export const useSeasonSelect = selectedSeason => {
  const allSeasons = useSelector((state: AppState) => state.entities.season.entities);
  return Object.values(allSeasons).filter(el => {
    if (el.id === selectedSeason) return el;
    return undefined;
  });
};
