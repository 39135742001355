import ax from 'axios';
import axios from 'axios-observable';
import { GenericRetryStrategy, is5xxStatus } from 'core/rxjs-utils';
import { refreshToken } from 'core/services/auth/auth.service';
import { isTrustedDomain } from 'core/utils/requests';
import { retryWhen } from 'rxjs/operators';
import sentryService from 'sentry/service';
import type { Token } from '../core/services/auth/auth.models';
import { getCurrentLanguage, isUK } from '../core/utils/functions';
import { AUTH_SECRET_ID } from './constants';

// Adds access token to every request
axios.interceptors.request.use(
  config => {
    if (!isTrustedDomain(config?.url ?? '')) {
      return config;
    }

    const token = localStorage.getItem('access_token') || sessionStorage.getItem('access_token');

    if (token !== null && !config.headers?.['X-Auth-Token']) {
      const currentLanguage = config.url?.includes('/v2/store/apps') && isUK() ? 'en-gb' : getCurrentLanguage();
      const isRemoteSensing = config.url?.includes('remote-sensing');
      const isNemadigital = config.url?.includes('.amazonaws.com');

      let xMock: boolean | string = 'no';
      if ('x-mock-disease-risk' in config.headers) {
        xMock = config.headers['x-mock-disease-risk'];

        delete config.headers['x-mock-disease-risk'];
      }
      let request_headers = {
        ...config.headers,
        ...(!(isRemoteSensing || isNemadigital)
          ? {
              'X-Mock': xMock,
              'Accept-Language': currentLanguage
            }
          : {}),
        Authorization: config.url && config.url.includes('refresh_token') ? `Basic ${AUTH_SECRET_ID}` : `Bearer ${token}`
      };
      if (sessionStorage.getItem('company_id') && sessionStorage.getItem('company_id') !== 'null' && !isRemoteSensing && !isNemadigital) {
        request_headers = {
          ...request_headers,
          'X-Company-Id': sessionStorage.getItem('company_id')
        };
      }

      return {
        ...config,
        headers: request_headers,
        withCredentials: false
      };
    }

    return config;
  },
  err => {
    return Promise.reject(err);
  }
);

let subscribers: ((token: string) => void)[] = [];
axios.interceptors.response.use(undefined, err => {
  const { config } = err;
  const status = err.response?.status;

  if (is5xxStatus(status)) {
    return axios.create({}).request(config).pipe(retryWhen(GenericRetryStrategy())).toPromise();
  }

  if (ax.isCancel(err)) {
    return Promise.reject(err);
  }

  const isAccountByIdRequest = err.config.url.includes('/v2/accounts/') && !err.config.url.includes('v2/accounts/me');

  const isAccountByIdConditionalAvailable = !isAccountByIdRequest;
  const isTrapViewIntegration = err.config?.url?.includes('/api/v1/trapview');
  const isJohnDeere = err.config?.url?.includes('/api/v1/john-deere');
  const isNemadigital = config.url?.includes('.amazonaws.com');
  const isRemoteSensing = config.url?.includes('remote-sensing');

  if (
    isAccountByIdConditionalAvailable &&
    !isNemadigital &&
    !isJohnDeere &&
    !isTrapViewIntegration &&
    !isRemoteSensing &&
    (status === 401 || status === 403)
  ) {
    if (err.response.data && ['invalid_token', 'invalid_grant', 'Unauthorized'].includes(err.response.data.error)) {
      localStorage.clear();
      sessionStorage.clear();

      sentryService.captureException(err, {
        fileName: 'interceptors.ts',
        method: 'axios.interceptors.response',
        description: 'Invalid token'
      });

      window.location.href = '/';
      return Promise.reject(err);
    }
    if (!sessionStorage.getItem('refreshed')) {
      const refresh_token = localStorage.getItem('refresh_token');
      if (refresh_token) {
        refreshToken(refresh_token)
          .toPromise()
          .then(response => {
            const auth: Token = response.data;
            onRefreshed(auth.access_token);
            localStorage.setItem('access_token', auth.access_token);
            localStorage.setItem('refresh_token', auth.refresh_token);
            subscribers = [];
            sessionStorage.setItem('refreshed', 'true');
            const requestSubscribers = new Promise(resolve => {
              subscribeTokenRefresh((token: string) => {
                const originalRequest = {
                  ...config,
                  headers: {
                    ...config.headers,
                    // 'X-Mock': 'no',
                    'Accept-Language': 'pt-BR',
                    Authorization: `Bearer ${token}`
                  }
                };

                resolve(new axios(originalRequest));
              });
            });
            return requestSubscribers;
          })
          .catch(error => {
            sentryService.captureException(error, {
              fileName: 'interceptors',
              method: 'refreshToken',
              description: 'unlicensed'
            });
            if (!window.location.href.includes('/unlicensed')) window.location.pathname = '/unlicensed';
            sessionStorage.setItem('refreshed', 'true');
            sessionStorage.setItem('unlicensed', 'true');
            return Promise.reject(error);
          });
      }
    } else {
      if (!window.location.href.includes('/unlicensed')) window.location.pathname = '/unlicensed';
      sessionStorage.setItem('unlicensed', 'true');
      sessionStorage.removeItem('refreshed');
      return Promise.reject(err);
    }
  }

  return Promise.reject(err);
});

(window as any).axios = axios;

const subscribeTokenRefresh = (cb: (token: string) => void) => {
  subscribers = [...subscribers, cb];
};

const onRefreshed = (token: string) => {
  subscribers.map(cb => cb(token));
};
