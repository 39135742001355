import booleanPointInPolygon from '@turf/boolean-point-in-polygon';
import buffer from '@turf/buffer';
import type { Coord } from '@turf/helpers';
import { lineString } from '@turf/helpers';
import length from '@turf/length';
import lineIntersect from '@turf/line-intersect';
import { API_REMOTE_SENSING_URL } from 'config/constants';
import type { Dictionary } from 'config/types';
import { Threshold } from 'core/core.models';
import { getCurrentLanguage, hashNumber } from 'core/utils/functions';
import { getNemadigitalColor } from 'core/utils/map/choropleth';
import type { ChoroplethLimits, StorageMaxDaysLimit } from 'core/utils/map/choropleth.models';
import { flattenArray } from 'core/utils/performance/perf.utils';
import type { CurrentSeasonArea } from 'entities/property/property.models';
import type {
  ColorDictionary,
  CurrentInfo,
  GeometryProperties,
  Region,
  RegionGeometry,
  RegionWithParents
} from 'entities/region/region.models';
import { RegionType } from 'entities/region/region.models';
import RegionsUtil from 'entities/region/region.utils';
import { isActive } from 'entities/season/season.functions';
import type { Feature, FeatureCollection, GeoJsonProperties, Geometry, Position } from 'geojson';
import _ from 'lodash';
import moment from 'moment';
import { isAValidRegionGeometry } from 'pages/disease-risk/disease-risk-map/disease-risk-map.utils';
import { scoutingScoreColors } from 'pages/timeline/area-day-info/components/application-details.functions';
import {
  getChoroplethSeverityColor,
  getDaysAfterEmergenceColor,
  getDaysWithoutMonitoringColor,
  getDaysWithoutSprayColor
} from 'pages/timeline/timeline.functions';
import type { TrackingSegment } from 'pages/timeline/timeline.models';
import type { ScoutingScorePerField } from 'querys/scouting-score/scouting-score.model';
import type { NemadigitalCropCycleReport } from 'syngenta-digital-react-cropwise-nemadigital/dist/module/map-overlay/map-overlay.types';
import type { UUID } from '../../utils/basic.models';
import { LissThresholds } from '../../utils/basic.models';

/**
 * Tracking Analytics
 * Cuts tracking into slices that lay inside the polygon and calculates its timestamps and distances
 *
 * @param tracking Response of `${protectorApiUrl}/v1/tracking/query`
 * @param regionGeometry Polygon to use
 * @param removeBuffer if yes a buffer around `regionGeometry` will be created
 *
 * @response {
 *  trackings: Dictionary of trackings by assignees
 *  timestamps: Dictionary of timestamps by assignees
 *  distances: Dictionary of distances by assignees
 *  totalDistance: sum of distances in meter
 *  totalTimestamp:  sum of timestamps in minutes
 * }
 */
export interface TrackingResponse {
  assignees: {
    id: UUID;
    name: string;
    route: number[][];
    timestamps: string[];
  }[];
}
type MultiPolyline = number[][][];

export interface TrackingAnalysis {
  totalDistance: number;
  totalTimestamp: number;
}

export const DEFAULT_UUID = '00000000-0000-0000-0000-000000000000';
const BUFFER_SIZE_IN_METERS = 50;

/**
 * @deprecated Use endpoint result instead
 */
export const getTrackingTotalDistanceAndTime = (
  tracking: TrackingResponse,
  regionGeometry: Feature<any>,
  removeBuffer = false
): TrackingAnalysis => {
  const timestamps = {};
  const distances = {};
  let totalTimestamp = 0;
  let totalDistance = 0;

  tracking.assignees.forEach(assignee => {
    const route = _.chain(assignee.route)
      .map((p, idx) => {
        return [[p[1], p[0]], assignee.timestamps[idx]]; // reverting points
      })
      .value();

    timestamps[assignee.id] = 0;
    distances[assignee.id] = 0;

    const slicedRoute: MultiPolyline = [];
    const firstPoint = route[0];
    const routeOffseted = route.slice(1);
    let currentSlice = 0;

    const createSliceIfUndefined = (sl: number) => {
      if (!slicedRoute[sl]) slicedRoute[sl] = [];
    };

    let route1 = firstPoint;

    // Loop through points two by two
    routeOffseted.forEach((route2, idx) => {
      const point1 = [route1[0][0], route1[0][1]] as Position;
      const point2 = [route2[0][0], route2[0][1]] as Position;
      // Create a line between these two points
      const line = lineString([point1, point2]);

      if (tracking.assignees.length === 1) {
        // Timestamps (We just sum these values if it belongs to a line that lay totally inside the polygon)
        if (assignee.timestamps[idx + 1] && assignee.timestamps[idx]) {
          const point1Time = moment(assignee.timestamps[idx]);
          const point2Time = moment(assignee.timestamps[idx + 1]);
          const diff = point2Time.diff(point1Time, 'seconds');
          if (diff < 300) {
            // Five minutes to a new time section.
            timestamps[assignee.id] += diff;
          }
        }

        distances[assignee.id] += length(line);
      } else {
        createSliceIfUndefined(currentSlice);
        const isLastLine = idx === route.length - 2;

        let bufferedGeometry = buffer(regionGeometry, BUFFER_SIZE_IN_METERS / 1000, {
          units: 'kilometers'
        });
        if (removeBuffer) bufferedGeometry = regionGeometry;

        const isPoint1Inside = booleanPointInPolygon(point1.slice().reverse(), bufferedGeometry);

        // lineIntersect has weird types 😰
        const intersects = lineIntersect(line, bufferedGeometry as any);
        const trackingCuts = intersects.features.length > 0;
        const trackingCutsOnce = intersects.features.length === 1;
        const trackingCutsMoreThanOnce = intersects.features.length > 1;
        const isTrackingInside = !trackingCuts && isPoint1Inside;

        if (isTrackingInside || tracking.assignees.length === 1) {
          slicedRoute[currentSlice].push(point1);
          if (isLastLine) {
            slicedRoute[currentSlice].push(point2);
          }

          // Timestamps (We just sum these values if it belongs to a line that lay totally inside the polygon)
          if (assignee.timestamps[idx + 1] && assignee.timestamps[idx]) {
            const point1Time = moment(assignee.timestamps[idx]);
            const point2Time = moment(assignee.timestamps[idx + 1]);
            const diff = point2Time.diff(point1Time, 'seconds');
            if (diff < 300) {
              // Five minutes to a new time section.
              timestamps[assignee.id] += diff;
            }
          }

          distances[assignee.id] += length(line);
        } else if (trackingCutsOnce) {
          const intersectPoint = intersects.features[0].geometry!.coordinates.slice().reverse();
          if (isPoint1Inside) {
            slicedRoute[currentSlice].push(point1);
            slicedRoute[currentSlice].push(intersectPoint);
            currentSlice++;

            distances[assignee.id] += length(lineString([point1, intersectPoint]));
          } else {
            slicedRoute[currentSlice].push(intersectPoint);
            distances[assignee.id] += length(lineString([intersectPoint, point2]));
          }
        } else if (trackingCutsMoreThanOnce) {
          // @TODO WE MUST IMPROVE THIS TO HANDLE 3 OR MORE INTERSECTIONS IN NON-CONVEX POLYGONS
          createSliceIfUndefined(++currentSlice);
          slicedRoute[currentSlice].push(intersects.features[0].geometry!.coordinates.slice().reverse());
          slicedRoute[currentSlice].push(intersects.features[1].geometry!.coordinates.slice().reverse());
          distances[assignee.id] += length(
            lineString([intersects.features[0].geometry!.coordinates, intersects.features[1].geometry!.coordinates])
          );
        }
      }

      route1 = route2;
    });

    // Timestamps in minutes
    timestamps[assignee.id] = Math.ceil(timestamps[assignee.id] / 60);

    // Distances in meters
    distances[assignee.id] = Math.ceil(distances[assignee.id] * 1000);
    totalTimestamp += timestamps[assignee.id];
    totalDistance += distances[assignee.id];
  });
  return { totalTimestamp, totalDistance };
};

const getCoordinatesInPolygon = (polygon: RegionGeometry, coordinates: Coord[]): Coord[][] => {
  const initialValue: Record<number, Coord[]> = { 0: [] };
  const coordsReduced = coordinates.reduce((acc: Record<number, Coord[]>, coordinate) => {
    const lastIndex = Object.keys(acc).length - 1;

    // eslint-disable-next-line @typescript-eslint/no-unsafe-call
    if (isAValidRegionGeometry(polygon) && !booleanPointInPolygon(coordinate, polygon)) {
      if (acc[lastIndex].length) {
        return { ...acc, [lastIndex + 1]: [] };
      }
      return { ...acc };
    }
    let lastSection: Coord[] = [];
    if (acc[lastIndex]) {
      lastSection = acc[lastIndex];
    }

    lastSection.push(coordinate);
    return { ...acc, [lastIndex]: lastSection };
  }, initialValue);

  return Object.values(coordsReduced);
};

/**
 * Return sections for all coordinates inside polygon
 * @param polygon
 * @param coordinates
 */
export function sliceTrackingRoute(
  coordinates: Coord[],
  segments: TrackingSegment[],
  polygon?: RegionGeometry,
  enableTrackingBySegments?: boolean
): Coord[][] {
  if (!polygon) {
    return [];
  }

  if (!enableTrackingBySegments) {
    return getCoordinatesInPolygon(polygon, coordinates);
  }

  return segments.flatMap(segment => {
    const coords = coordinates.slice(segment.start_index, segment.end_index + 1);
    return getCoordinatesInPolygon(polygon, coords);
  });
}

export const getAreaThreshold = (area: Region): Threshold => {
  if (!area?.current_info?.last_monitoring) return Threshold.NONE;

  if (area.current_info.severity_level < LissThresholds.MIN_CONTROL) {
    return Threshold.ACCEPTANCE;
  }

  if (area.current_info.severity_level < LissThresholds.MIN_DAMAGE) {
    return Threshold.CONTROL;
  }

  return Threshold.DAMAGE;
};

export const setAreaHasLastSprayAfterMonitoring = (region: Region, seasonEnded: boolean): boolean => {
  if (seasonEnded) return false;

  const options = [Threshold.ENDED, Threshold.SPRAY];

  const lastSpray = region?.current_info?.last_spray?.end_date;
  const lastMonitoring = region?.current_info?.last_monitoring?.date;

  const severityLevel = getAreaThreshold(region);
  const validatedField = !severityLevel || (severityLevel && options.includes(severityLevel));

  if (!lastSpray) return false;

  if (validatedField || !lastMonitoring) return true;
  const parsedLastSpray = moment(lastSpray).format('YYYY-MM-DD HH:mm');
  const parsedLastMonitoring = moment(lastMonitoring).format('YYYY-MM-DD HH:mm');

  return moment(parsedLastSpray).isAfter(parsedLastMonitoring);
};

/**
 * Return region with all child areas geojson and custom properties.
 */
export function getRegionFeatureCollection(
  region: Region,
  areaSeasons?: Dictionary<CurrentSeasonArea[]>,
  selectedSeasons?: string[],
  choroplethLimits?: Dictionary<ChoroplethLimits>,
  flags?: Dictionary<string | boolean> | null,
  mapLayersColorDictionary: ColorDictionary = {},
  storageMaxDays?: StorageMaxDaysLimit
): GeoJSON.FeatureCollection<GeoJSON.Polygon, GeometryProperties> {
  const areas = _.uniqBy(getRegionAreasWithParents(region), 'id');

  const features = areas.map(area => {
    const crop_ended = areaSeasons ? !areaSeasons[area.id]?.some(as => selectedSeasons?.includes(as.seasonId)) : false;
    const geometry = area.geometry as GeoJSON.Feature<GeoJSON.Polygon, GeometryProperties>;
    const seed = area.current_info?.seeds?.[0];
    const phenologicalStage = area.current_info?.phenological_stage;
    const dae = RegionsUtil.getDAEByEmergenceDay(area.current_info?.emergence_day);

    const properties: GeometryProperties = {
      id: area.id,
      name: area.name,
      parent_id: area.parent_id,
      total_area: area.total_area,
      type: area.type,
      parents: area.parents,
      current_info: {
        ...area.current_info,
        crop_ended
      } as CurrentInfo,
      is_season_active: isActive(area.seasons[0]),
      severityLevelColor: area.current_info && getChoroplethSeverityColor(area, crop_ended, !!flags?.P40_29700_show_statistic_legend),
      daysWithoutSprayColor:
        area.current_info &&
        choroplethLimits &&
        getDaysWithoutSprayColor(
          area,
          choroplethLimits,
          crop_ended,
          !!flags?.P40_29700_show_statistic_legend,
          storageMaxDays?.daysWithoutSprayMaxColor
        ),
      daysWithoutMonitoringColor:
        area.current_info &&
        choroplethLimits &&
        getDaysWithoutMonitoringColor(
          area,
          choroplethLimits,
          crop_ended,
          !!flags?.P40_29700_show_statistic_legend,
          storageMaxDays?.daysWithoutMonitoringMaxColor
        ),
      seedColor: mapLayersColorDictionary?.seedsColors?.[seed]?.color,
      phenologicalStageColor: mapLayersColorDictionary?.phenologicalStageColors?.[phenologicalStage]?.color,
      phenologicalStage: phenologicalStage !== '' ? phenologicalStage : undefined,
      daeColor:
        area.current_info &&
        choroplethLimits &&
        getDaysAfterEmergenceColor(
          area,
          choroplethLimits,
          crop_ended,
          !!flags?.P40_29700_show_statistic_legend,
          storageMaxDays?.daysAfterEmergenceMaxColor,
          storageMaxDays?.daysAfterEmergenceMinColor,
          storageMaxDays?.daysAfterEmergenceMin || 0
        ),
      dae,
      ...(flags?.P40_22123_appliedSpraysOnTheSeverityMap && {
        hasSprayAfterMonitoring: setAreaHasLastSprayAfterMonitoring(area, crop_ended),
        seed
      }),
      highlight: false
    };
    return { ...geometry, id: hashNumber(area.id), properties };
  });
  return {
    type: 'FeatureCollection',
    features
  };
}

export function addScoutingScoreToRegionProperties(
  region: Region,
  latestScoutingScore: ScoutingScorePerField = {}
): FeatureCollection<Geometry, GeometryProperties> {
  const features: any = (region.geometry as FeatureCollection<Geometry, GeoJsonProperties>)?.features.map(regionGeometry => {
    const areaScoutingScore = latestScoutingScore[regionGeometry.properties?.id];
    return {
      ...regionGeometry,
      properties: {
        ...regionGeometry.properties,
        scoutingScore: areaScoutingScore?.score,
        scoutingScoreColor: scoutingScoreColors(areaScoutingScore?.score).fill,
        scouters: areaScoutingScore?.scoutersScores.map(s => s.scouter_id)
      }
    };
  });
  return {
    type: 'FeatureCollection',
    features
  };
}

export function addNematodeDamageToRegionProperties(
  region: Region,
  nemadigitalReport: NemadigitalCropCycleReport
): FeatureCollection<Geometry, GeometryProperties> {
  const features: any = (region.geometry as FeatureCollection<Geometry, GeoJsonProperties>)?.features.map(regionGeometry => {
    const { damagePerField } = nemadigitalReport;
    const fieldNematodeDamage = damagePerField[regionGeometry.properties?.id];
    return {
      ...regionGeometry,
      properties: {
        ...regionGeometry.properties,
        nematodeDamage: isNaN(fieldNematodeDamage)
          ? undefined
          : `${fieldNematodeDamage.toLocaleString(getCurrentLanguage(), {
              maximumFractionDigits: 2,
              minimumFractionDigits: 2
            })}%`,
        nematodeColor: isNaN(fieldNematodeDamage) ? undefined : getNemadigitalColor(fieldNematodeDamage)
      }
    };
  });
  return {
    type: 'FeatureCollection',
    features
  };
}

export function isNumberInRange(dae: number, highlightValue: string | undefined): boolean {
  const value = dae || 0;

  if (highlightValue?.includes('>')) {
    const formatValue = Number(highlightValue.replace('>', ''));
    return dae >= formatValue;
  }
  const min = highlightValue?.split('-')[0];
  const max = highlightValue?.split('-')[1];

  return value >= Number(min) && value < Number(max);
}

export function updateRegionsHighlightStatus(
  region: Region,
  highlightFilter: { field: string; value: string | undefined }
): FeatureCollection<Geometry, GeometryProperties> {
  const features: any = (region.geometry as FeatureCollection<Geometry, GeoJsonProperties>)?.features.map(regionGeometry => {
    const dae = regionGeometry.properties?.[highlightFilter.field];
    let highlight = false;

    const daeBetweenRange = isNumberInRange(dae, highlightFilter?.value);

    if (highlightFilter.value) {
      highlight = dae === highlightFilter.value || daeBetweenRange;
    }

    return {
      ...regionGeometry,
      properties: {
        ...regionGeometry.properties,
        highlight
      }
    };
  });
  return {
    type: 'FeatureCollection',
    features
  };
}

export function updateSeedAndPhenologyColorsToRegionProperties(
  region: Region,
  mapLayersColorDictionary: ColorDictionary
): FeatureCollection<Geometry, GeometryProperties> {
  const features: any = (region.geometry as FeatureCollection<Geometry, GeoJsonProperties>)?.features.map(regionGeometry => {
    const seed = regionGeometry.properties?.current_info?.seeds?.[0];
    const phenologicalStage = regionGeometry.properties?.current_info?.phenological_stage || '';
    return {
      ...regionGeometry,
      properties: {
        ...regionGeometry.properties,
        seedColor: mapLayersColorDictionary?.seedsColors?.[seed]?.color,
        phenologicalStageColor: mapLayersColorDictionary?.phenologicalStageColors?.[phenologicalStage]?.color
      }
    };
  });
  return {
    type: 'FeatureCollection',
    features
  };
}

/**
 * Return all child areas of a region.
 */
export function getRegionAreasWithParents(region: Region, parents = [] as UUID[]): RegionWithParents[] {
  return (region.children as Region[]).flatMap(child => {
    if (child.type === RegionType.REGION) return getRegionAreasWithParents(child, [...parents, region.id]);

    return { ...child, parents } as RegionWithParents;
  });
}

export function getRegionsChild(region): Region[] {
  return flattenArray(region.children).filter(x => x.type === RegionType.REGION);
}

interface IFarmshotsLayer {
  id: string;
  tiles: string[];
  coordinates?: any;
}

interface IRemoteSensingLayersFactory {
  imageryLayers: IFarmshotsLayer[];
  exprs?: string;
}

interface RemoteSensingLayersFactoryParams {
  checkedAssets: string[];
  algorithm: string;
}

export const replaceAlgoritm = {
  NDVIR: 'ndvi',
  SAVI: 'savi',
  RGB: 'rgb'
};

export function RemoteSensingLayersFactory({ checkedAssets, algorithm }: RemoteSensingLayersFactoryParams): IRemoteSensingLayersFactory {
  const algorithmSelected = replaceAlgoritm[algorithm];
  const color = algorithmSelected !== 'rgb' ? 'falsecolor' : 'truecolor';
  const imageryLayers = checkedAssets?.map(assetId => {
    const tiles = [`${API_REMOTE_SENSING_URL}/images/${assetId}/${algorithmSelected}/${color}/tiles/{z}/{x}/{y}/png`];

    return {
      id: assetId,
      tiles
    };
  });

  return {
    imageryLayers
  };
}
