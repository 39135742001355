import UnstyledButton from 'components/unstyled-button';
import STTypo, { STTypoModels } from 'core/shared/typo';
import type { mapboxgl } from 'core/utils/map/mapbox-gl';
import type { SegmentTrackingHookReturn } from 'core/utils/segment/useSegmentTracking';
import { loadPopup } from 'pages/timeline/timeline.functions';
import { EventType } from 'pages/timeline/timeline.models';
import { useTranslation } from 'react-i18next';
import SvgCloseMinGlyph from 'st-protector-icons/Glyph/CloseMinGlyph';
import { SdPopupClusterItem } from './sd-popup-cluster-item';
import type { Popup } from './sd-popup.models';

interface Marker {
  id: string;
  markerProps: {
    type: EventType.MONITORING | EventType.PHENOLOGY | EventType.ANNOTATION;
  };
  popupProps: unknown;
}

interface ISDPopupCluster {
  segmentTracking: SegmentTrackingHookReturn;
  productsAvailable?: string | boolean;
  enableMultiplePhotos?: boolean;
  isGalleryV2?: string | boolean;
  mapboxPopup: mapboxgl.Popup;
  fieldName?: string;
  data: Marker[];
}

const SDPopupCluster = ({
  enableMultiplePhotos,
  productsAvailable,
  segmentTracking,
  mapboxPopup,
  isGalleryV2,
  fieldName,
  data
}: ISDPopupCluster) => {
  const [t] = useTranslation();

  return (
    <>
      <div className='st-popup-cluster__header'>
        <STTypo type={STTypoModels.TypeOptions.P1} fontWeight={500}>
          {data?.length} {t('general.map.clustered.grouped_points')}
        </STTypo>
        <UnstyledButton className='st-popup-cluster__header__close-button' onClick={() => mapboxPopup.remove()}>
          <SvgCloseMinGlyph />
        </UnstyledButton>
      </div>
      <div className='st-popup-cluster__container'>
        {data?.map(marker => (
          <UnstyledButton
            key={marker.id}
            data-testid={`st-popup-cluster-list-${marker.id}`}
            className='st-popup-cluster__list'
            onClick={() => {
              loadPopup({
                popup: mapboxPopup,
                marker,
                enableMultiplePhotos,
                productsAvailable,
                segmentTracking,
                isGalleryV2,
                fieldName
              });
            }}>
            <div className='st-popup-cluster__item'>
              {
                {
                  [EventType.MONITORING]: (
                    <SdPopupClusterItem marker={marker as Popup<EventType.MONITORING>} eventType={EventType.MONITORING} />
                  ),
                  [EventType.PHENOLOGY]: (
                    <SdPopupClusterItem marker={marker as Popup<EventType.PHENOLOGY>} eventType={EventType.PHENOLOGY} />
                  ),
                  [EventType.ANNOTATION]: (
                    <SdPopupClusterItem
                      marker={marker as Popup<EventType.ANNOTATION>}
                      eventType={EventType.ANNOTATION}
                      enableMultiplePhotos={enableMultiplePhotos}
                    />
                  )
                }[marker.markerProps.type]
              }
            </div>
          </UnstyledButton>
        ))}
      </div>
    </>
  );
};

export default SDPopupCluster;
