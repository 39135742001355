import { Button, Popconfirm } from 'antd';
import STTypo from 'core/shared/typo';
import { ColorOptions, TypeOptions } from 'core/shared/typo/typo.models';
import type React from 'react';
import { useTranslation } from 'react-i18next';
import SvgAlertGlyph from 'st-protector-icons/Glyph/AlertGlyph';

interface IFooterDrawerProps {
  isLoading: boolean;
  disableConfirmButton: boolean;
  onPressConfirm: () => void;
  onPressCancel: () => void;
  footerMessageShow?: boolean;
}

const FooterDrawer: React.FC<IFooterDrawerProps> = ({
  isLoading,
  onPressConfirm,
  disableConfirmButton,
  onPressCancel,
  footerMessageShow
}) => {
  const [t] = useTranslation();
  return (
    <div className='st-report-drawer__actions'>
      {footerMessageShow ? (
        <Button
          type='ghost'
          size='default'
          className='st-report-drawer_actions-cancel'
          data-testid='report-footer-btn-cancel-message-show'
          onClick={onPressCancel}>
          {t('general.cancel')}
        </Button>
      ) : (
        <Popconfirm
          placement='bottomRight'
          icon={
            <div className='st-report-drawer__confirm-icon-container'>
              <SvgAlertGlyph fill='#F74141' />
            </div>
          }
          overlayClassName='st-report-drawer__confirm'
          title={
            <div className='st-report-drawer__confirm-delete'>
              <STTypo type={TypeOptions.P1}>{`${t('report.back')}`}</STTypo>
            </div>
          }
          onConfirm={() => onPressCancel()}
          okText={t('general.complete')}
          okButtonProps={{ style: { backgroundColor: '#F74141', border: 'none', borderRadius: '4px', paddingTop: '2px' } }}
          cancelButtonProps={{
            style: {
              backgroundColor: '#F2F4F6',
              border: 'none',
              borderRadius: '4px',
              paddingTop: '2px',
              color: ColorOptions.PRIMARY
            }
          }}
          cancelText={t('general.back')}>
          <Button type='ghost' size='default' className='st-report-drawer_actions-cancel' data-testid='report-footer-btn-cancel'>
            {t('general.cancel')}
          </Button>
        </Popconfirm>
      )}
      <Button
        type='primary'
        size='default'
        className='st-report-drawer_actions-submit'
        onClick={onPressConfirm}
        disabled={disableConfirmButton}
        loading={isLoading}
        data-testid='report-scouting-submit'>
        {t('report.action')}
      </Button>
    </div>
  );
};

export default FooterDrawer;
