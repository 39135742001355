import type { Action } from '../../core/utils/basic.models';
import type { Region } from '../../entities/region/region.models';

export const TaskUiActionTypes = {
  SELECT_AREA_FROM_TIMELINE: '[Global Ui] Select area from timeline'
};

type SelectEntityType = (region: Region | undefined) => Action<Region | undefined>;

export const SetAreaFromTimeline: SelectEntityType = area => {
  return {
    type: TaskUiActionTypes.SELECT_AREA_FROM_TIMELINE,
    payload: area
  };
};
