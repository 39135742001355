import { PROTECTOR_APP_ID } from 'config/constants';
import type { GlobalUiState } from 'core/core.models';
import type { Selector } from 'core/core.selectors';
import { Entitlements } from 'core/shared/enums/entitlements.enum';
import type { UUID } from 'core/utils/basic.models';
import { getDifferenceInDays } from 'core/utils/date';
import { hasEntitlements } from 'core/utils/entitlement';
import { compareStrings } from 'core/utils/strings';
import _ from 'lodash';
import moment from 'moment';
import type { EntityState } from 'redux-ngrx-entity';
import type { AppState } from 'redux/app-state';
import { createSelector } from 'reselect';
import type { Company, CompanyState, LicensingStatusAppsContractResponse, LicensingStatusAppsResponse } from './company.models';

const filterConfiguredApps = ({ contract: { start, end }, id }: LicensingStatusAppsResponse): boolean =>
  id === PROTECTOR_APP_ID && !!start && !!end;
const createGroupAppsByExpiration =
  (nowMoment: number) =>
  ({ contract: { end } }: LicensingStatusAppsResponse): 'activeApps' | 'inactiveApps' =>
    moment(end).diff(nowMoment) > 0 ? 'activeApps' : 'inactiveApps';

const sortAppsByStart = (
  { contract: { start: startA } }: LicensingStatusAppsResponse,
  { contract: { start: startB } }: LicensingStatusAppsResponse
): number => compareStrings(startB, startA);

export const selectMostCurrentApp: Selector<LicensingStatusAppsResponse | null> = createSelector(
  (state: AppState) => state.entities.company.licensingStatus?.apps,
  (apps: LicensingStatusAppsResponse[] | undefined) => {
    if (!apps) return null;

    const configuredApps = apps.filter(filterConfiguredApps);
    const { activeApps, inactiveApps } = _.groupBy(configuredApps, createGroupAppsByExpiration(moment.now()));

    if (activeApps?.length) {
      activeApps.sort(sortAppsByStart);
      return activeApps[0];
    }

    if (!inactiveApps?.length) {
      return null;
    }

    inactiveApps.sort(sortAppsByStart);
    return inactiveApps[0];
  }
);

export const getAppFromAppListByAppId = (apps: LicensingStatusAppsResponse[], appId: UUID): LicensingStatusAppsResponse | null =>
  apps
    .filter(app => app.id === appId)
    .reduce((acc: LicensingStatusAppsResponse | null, app) => {
      if (!acc) return app;
      const difference = getDifferenceInDays(new Date(acc.contract.start), new Date(app.contract.start));
      return difference < 0 ? acc : app;
    }, null);

export const getProtectorApp: Selector<LicensingStatusAppsResponse | null> = state => {
  const featureFlagP40_31566 = state.uiState.global.systemFlags?.P40_31566_GET_LAST_CONTRACT_HEADER;
  return featureFlagP40_31566
    ? getProtectorLastApp(state)
    : getAppFromAppListByAppId(state.entities.company.licensingStatus?.apps || [], PROTECTOR_APP_ID);
};

export const getAppFromLastAppListByAppId = (apps: LicensingStatusAppsResponse[], appId: UUID): LicensingStatusAppsResponse | null =>
  apps
    .filter(app => app.id === appId)
    .reduce((acc: LicensingStatusAppsResponse | null, app) => {
      if (!acc) return app;
      const difference = getDifferenceInDays(new Date(acc.contract.end), new Date(app.contract.end));
      return difference < 0 ? acc : app;
    }, null);

export const getProtectorLastApp: Selector<LicensingStatusAppsResponse | null> = state => {
  return getAppFromLastAppListByAppId(
    state.entities.company.licensingStatus?.apps.filter(x => {
      const today = new Date();
      return moment(today).diff(x.contract.start, 'd') >= 0;
    }) || [],
    PROTECTOR_APP_ID
  );
};

export const getContract: Selector<LicensingStatusAppsContractResponse | undefined> = state => {
  const protectorApp = getProtectorApp(state);
  return protectorApp && hasEntitlements(protectorApp, [Entitlements.WARNING_EXPIRATION_PLAN]) ? protectorApp.contract : undefined;
};

export const getContractByAppId = createSelector(
  (state: AppState, appId: string) => ({
    appList: state.entities.company.licensingStatus?.apps,
    appId
  }),
  ({ appList, appId }) => {
    const app = getAppFromLastAppListByAppId(appList || [], appId);
    return app ? app.contract : undefined;
  }
);

export const getCompanyLicensingWithCurrentContract = createSelector(
  (state: AppState) => state.entities.company.licensingStatus,
  (state: AppState) => getProtectorLastApp(state),
  (licensing, protectorApp) => {
    const apps: LicensingStatusAppsResponse[] = protectorApp ? [protectorApp] : [];
    return licensing ? { ...licensing, apps } : null;
  }
);

export const getCompanyEntities: Selector<EntityState<Company>['entities']> = state => state.entities.company.entities;

export const getSelectedCompanyId: Selector<GlobalUiState['selectedCompany']> = state => state.uiState.global.selectedCompany;

export const getLoadingCompany: Selector<CompanyState['isLoading']> = state => state.entities.company.isLoading;

export const getLogoCompanyById = createSelector(
  (state: AppState) => ({
    companies: state.entities.company.entities,
    companyId: state.uiState.global.selectedCompany
  }),
  ({ companies, companyId }) => {
    return companyId ? companies?.[companyId]?.picture_url : null;
  }
);

export const getCurrentWorkspaceId = createSelector(
  (state: AppState) => state.entities.company.isLoadingCurrentWorkspaceId,
  (state: AppState) => state.entities.company.currentWorkspaceId,
  (loading, id) => {
    if (!loading) return id;
    return null;
  }
);
