import { selectAllProperties } from 'entities/property/property.reducer';
import { selectRegion } from 'entities/region/region.reducer';
import { selectSeasonEntities } from 'entities/season/season.reducer';
import type { AppState } from 'redux/app-state';
import { createSelector } from 'reselect';
import Rox from 'rox-browser';
import { CoreActionsTypes } from './core.actions';
import type { GlobalUiState } from './core.models';
import { getCurrentCurrency, getCurrentUnitSystem } from './utils/functions';

export const initialState: GlobalUiState = {
  currentLocation: {
    path: null,
    title: null,
    icon: null
  },
  selectedCompany: null,
  selectedProperty: null,
  interfaceLanguage: (localStorage.getItem('currentLanguage') ?? localStorage.getItem('i18nextLng'))?.toLowerCase() ?? null,
  selectedSeasons: [],
  selectedRegion: null,
  selectedArea: null,
  currentCurrency: getCurrentCurrency(),
  currentUnitSystem: getCurrentUnitSystem(),
  appsPermission: null,
  dashboardState: {
    showCompanyWidgets: true,
    selectedProperties: [],
    selectedDateRange: undefined
  },
  layoutState: { showHeader: true, showSider: true, showMonitoringDrawer: false },
  systemFlags: null,
  loadedAppPermissions: false
};

export default (state = initialState, action): GlobalUiState => {
  switch (action.type) {
    case CoreActionsTypes.CHANGE_APP_LOCATION:
      return {
        ...state,
        currentLocation: action.payload
      };
    case CoreActionsTypes.SAVE_FLAGS:
      return {
        ...state,
        systemFlags: action.payload
      };

    case CoreActionsTypes.UPDATE_FLAG:
      return {
        ...state,
        systemFlags: {
          ...state.systemFlags,
          [action.payload.flag]: action.payload.value
        }
      };
    case CoreActionsTypes.SELECT_COMPANY:
      Rox.setCustomStringProperty('CompanyID', action.payload);
      return {
        ...state,
        selectedCompany: action.payload
      };
    case CoreActionsTypes.SELECT_PROPERTY:
      Rox.setCustomStringProperty('PropertyID', action.payload);
      return {
        ...state,
        selectedProperty: action.payload
      };
    case CoreActionsTypes.SELECT_SEASONS:
      return {
        ...state,
        selectedSeasons: action.payload
      };
    case CoreActionsTypes.SELECT_REGION:
      return {
        ...state,
        selectedRegion: action.payload
      };
    case CoreActionsTypes.SELECT_AREA:
      return {
        ...state,
        selectedArea: action.payload
      };
    case CoreActionsTypes.CHANGE_CURRENCY:
      return {
        ...state,
        currentCurrency: action.payload
      };
    case CoreActionsTypes.CHANGE_UNIT_SYSTEM:
      return {
        ...state,
        currentUnitSystem: action.payload
      };
    case CoreActionsTypes.CHANGE_INTERFACE_LANGUAGE:
      return {
        ...state,
        interfaceLanguage: action.payload
      };
    case CoreActionsTypes.CHANGE_DASHBOARD_STATE:
      return {
        ...state,
        dashboardState: {
          ...state.dashboardState,
          ...action.payload
        }
      };
    case CoreActionsTypes.TOGGLE_HEADER:
      return {
        ...state,
        layoutState: {
          ...state.layoutState,
          showHeader: !state.layoutState.showHeader
        }
      };

    case CoreActionsTypes.LOAD_APPS_PERMISSIONS:
      return {
        ...state,
        loadedAppPermissions: false
      };
    case CoreActionsTypes.LOAD_APPS_PERMISSIONS_FAILURE:
      return {
        ...state,
        loadedAppPermissions: true
      };
    case CoreActionsTypes.LOAD_APPS_PERMISSIONS_SUCCESS:
      return {
        ...state,
        appsPermission: action.payload,
        loadedAppPermissions: true
      };
    case CoreActionsTypes.TOGGLE_SIDER:
      return {
        ...state,
        layoutState: {
          ...state.layoutState,
          showSider: !state.layoutState.showSider
        }
      };
    case CoreActionsTypes.TOGGLE_MONITORING_DRAWER:
      return {
        ...state,
        layoutState: {
          ...state.layoutState,
          showMonitoringDrawer: !state.layoutState.showMonitoringDrawer
        }
      };
    default:
      return state;
  }
};

export const selectSelectedCompany = (state: GlobalUiState) => state.selectedCompany;

export const selectSelectedProperty = (state: GlobalUiState) => state.selectedProperty;

export const getCurrentRegionId = (state: AppState) => state.uiState.global.selectedRegion;

export const getCurrentRegion = (state: AppState) =>
  (state.uiState.global.selectedRegion && selectRegion(state, state.uiState.global.selectedRegion)) || null;

export const getCurrentAreaId = (state: AppState) => state.uiState.global.selectedArea;

export const getCurrentArea = (state: AppState) =>
  (state.uiState.global.selectedArea && selectRegion(state, state.uiState.global.selectedArea)) || null;

export const selectSelectedSeasons = (state: GlobalUiState) => state.selectedSeasons;

export const getSelectedSeasons = (state: AppState): string[] => selectSelectedSeasons(state.uiState.global);

export const selectPropertiesFromCurrentCompany = createSelector(
  (state: AppState) => selectSelectedCompany(state.uiState.global),
  (state: AppState) => selectAllProperties(state.entities.property),
  (currentCompany, allProperties) => {
    return allProperties.filter(property => {
      return property.company_id === currentCompany;
    });
  }
);

export const selectSelectedCrops = createSelector(
  (state: AppState) => selectSelectedSeasons(state.uiState.global),
  (state: AppState) => selectSeasonEntities(state.entities.season),
  (selectedSeasons, seasons) => {
    const crops = selectedSeasons
      .map(selectedSeason => {
        if (seasons[selectedSeason]) {
          return seasons[selectedSeason].crop.id;
        }
        return undefined;
      })
      .filter(c => c !== undefined);

    return crops;
  }
);

export const selectDashboardState = (state: GlobalUiState) => state.dashboardState;

export const selectLayoutState = (state: GlobalUiState) => state.layoutState;

export const selectSystemFlags = (state: AppState) => state.uiState.global.systemFlags;
