import STTypo, { STTypoModels } from '../typo';
import useVersionStore from './version.store';

export const AppVersion = () => {
  const { version } = useVersionStore();

  return (
    <div className='st-version'>
      {version && (
        <STTypo color={STTypoModels.ColorOptions.SECONDARY} type={STTypoModels.TypeOptions.C2}>
          {version}
        </STTypo>
      )}
    </div>
  );
};
