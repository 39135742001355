export enum COLOR_OPTIONS {
  PRIMARY = '#2f3031',
  SECONDARY = '#707374',
  SUCCESS = '#198746',
  DANGER = '#F74141',
  SEVERITY_BG = '#FFECEC',
  WHITE = '#FFFFFF',
  GREEN_PRIMARY = '#749205',
  GREEN_SECONDARY = '#1a8746',
  GREEN_5 = '#EDFEF0',
  GREEN_CROPWISE = '#73DC78',
  LIGHT_GREEN = '#7ed66b',
  BLUE_PRIMARY = '#01658d',
  BLUE_10 = '#EAF6FF',
  BLUE_20 = '#C6E6FF',
  BLUE_30 = '#82CFFF',
  BLUE_40 = '#31B4F2',
  BLUE_50 = '#0092E4',
  BLUE_60 = '#0071CD',
  BLUE_70 = '#005693',
  BLUE_80 = '#003B69',
  BLUE_90 = '#002747',
  YELLOW_PRIMARY = '#ffca28',
  YELLOW_10 = '#FFF3DD',
  YELLOW_20 = '#FFE4AE',
  YELLOW_30 = '#F0C355',
  YELLOW_40 = '#E99921',
  YELLOW_50 = '#C17E19',
  YELLOW_60 = '#9A6412',
  YELLOW_80 = '#523305',
  ORANGE_40 = '#FF785A',
  ORANGE_60 = '#C1462B',
  HARVESTED = '#2FB966',
  DISABLED = '#8E9293',
  GRAY_7 = '#F5F8FC',
  GRAY_4 = '#C1C5C8',
  GRAY_2 = '#515253',
  GRAY_1 = '#2F3031',
  NEUTRAL_100 = '#14151C',
  NEUTRAL_90 = '#232630',
  NEUTRAL_80 = '#363948',
  NEUTRAL_70 = '#4D5165',
  NEUTRAL_60 = '#696F88',
  NEUTRAL_50 = '#868CA2',
  NEUTRAL_40 = '#A3A9B9',
  NEUTRAL_30 = '#C2C7D0',
  NEUTRAL_20 = '#DFE2E7',
  NEUTRAL_10 = '#F3F4F6',
  NEUTRAL_00 = COLOR_OPTIONS.WHITE,
  GREEN_10 = '#DFFBE8',
  GREEN_40 = '#5EBB7F',
  GREEN_50 = '#19A04B',
  GREEN_60 = '#14803C',
  GREEN_70 = '#0C612C',
  GREEN_DAE = '#0B95A8',
  PINK_30 = '#FFB2C1',
  PINK_40 = '#FF7D96',
  PINK_60 = '#BB485D',
  PURPLE_30 = '#D2BDF9',
  PURPLE_50 = '#9664F0',
  PURPLE_70 = '#643AAA',
  PURPLE_90 = '#2E1954',
  RED_10 = '#FEF1F1',
  RED_30 = '#FFB3B3',
  RED_50 = '#EB4B4B',
  RED_60 = '#CF3537',
  RED_70 = '#9C2628',
  RED_80 = '#70191A',
  RED_100 = '#FF0000',
  TEAL_20 = '#A9EFE8',
  TEAL_30 = '#5FD2C8',
  TEAL_50 = '#2B9C92',
  TEAL_70 = '#165C56'
}
