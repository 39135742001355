import axios from 'axios-observable';
import type { AxiosObservable } from 'axios-observable/dist/axios-observable.interface';
import { CORE_SERVICES_API_URL, PROTECTOR_API_URL } from 'config/constants';
import type { Moment, UUID } from 'core/utils/basic.models';
import moment from 'moment';
import type { Dictionary } from 'config/types';
import type { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import type { SeasonTree } from './season-tree.models';
import type { Season, SeasonBudget, SeasonDeep } from './season.models';

const coreServicesApiUrl = CORE_SERVICES_API_URL;
const protectorApiUrl = PROTECTOR_API_URL;
const seasonDeepUrl = (id: string) => `${coreServicesApiUrl}/v1/seasons/${id}/deep`;

const seasonBudgetPropertyUrl = (idProperty: string, queryParams?: string, isAreasProductsSpending?: string | boolean) => {
  if (isAreasProductsSpending) {
    return `${coreServicesApiUrl}/v1/properties/${idProperty}/areas-products-spending?${queryParams}`;
  }
  return `${coreServicesApiUrl}/v1/properties/${idProperty}/areas-budget-monitoring?${queryParams}`;
};

export const getSeasonDeep = (idSeason: UUID): Observable<SeasonDeep> => {
  return axios.get<SeasonDeep>(seasonDeepUrl(idSeason)).pipe(map(response => response.data));
};

export const getBudgetproperty = (
  idProperty: UUID,
  queryParams?: string,
  isAreasProductsSpending?: string | boolean
): Observable<SeasonBudget[]> => {
  return axios
    .get<SeasonBudget[]>(seasonBudgetPropertyUrl(idProperty, queryParams, isAreasProductsSpending))
    .pipe(map(response => response.data));
};

export const getSelectedSeasonsPeriod = (selectedSeasons: string[], seasonEntites: Dictionary<Season>) => {
  const seasons = selectedSeasons.map(seasonId => seasonEntites[seasonId]);
  let minDate: Moment = moment();
  let maxDate: Moment = moment();
  seasons.forEach(season => {
    if (minDate === undefined || moment(season.start_date).isBefore(minDate)) {
      minDate = moment(season.start_date);
    }
    if (maxDate === undefined || moment(season.end_date).isAfter(maxDate)) {
      maxDate = moment(season.end_date);
    }
  });

  return { minDate, maxDate };
};

export const getSeasonsTreeByCompany = (companyId: UUID): AxiosObservable<SeasonTree[]> => {
  return axios.get<SeasonTree[]>(`${protectorApiUrl}/v1/companies/${companyId}/seasons/tree`);
};
