import { getRegionFeatureCollection, getRegionsChild } from 'core/shared/map/map.functions';
import type { V4Page } from 'core/utils/basic.models';
import type { ChoroplethLimits, StorageMaxDaysLimit } from 'core/utils/map/choropleth.models';
import type { Variety } from 'entities/crop/crop.models';
import { getCropVarieties } from 'entities/crop/crop.service';
import _ from 'lodash';
import type { Dictionary } from 'config/types';
import { of } from 'rxjs';
import type { CurrentSeasonArea } from '../property/property.models';
import { GetAreaVariables, LoadRegionSeasonFieldsSuccess } from './region.actions';
import type { SeasonFieldsSuccessProps } from './region.epics.models';
import type { ColorDictionary, Region } from './region.models';
import { RegionType } from './region.models';
import { buildAreaInfoBySeasonField, castToFourDecimalPlaces, fillAreaValues } from './region.utils';
import type { SeasonField } from '../../querys/season-field/season-field.models.ts';
import moment from 'moment-timezone';

const mapperCropVarieties = (cropIds: string[], varieties: V4Page<Variety>[]) => {
  const cropVarietiesMap: Record<string, Variety[]> = {};
  cropIds?.forEach((crop, idx) => {
    const filteredVarieties = varieties[idx].content;
    cropVarietiesMap[crop] = filteredVarieties;
  });

  return cropVarietiesMap;
};

export function getCropsByIds(cropIds: string[], companyId: string) {
  return cropIds.map(crop => getCropVarieties({ companyId: companyId, cropId: crop }));
}

export const loadRegionSeasonFieldsSuccess =
  ({ selectedSeasons, cropIds, regionChildrenMapping, region, areaSeasonFieldMap, seasons }: SeasonFieldsSuccessProps) =>
  (varieties: V4Page<Variety>[]) => {
    const mappedVarieties = mapperCropVarieties(cropIds, varieties);

    let areaInfo = buildAreaInfoBySeasonField({
      varieties: mappedVarieties,
      regionChildrenMapping,
      areaSeasonFieldMap,
      seasons,
      region
    });

    if (areaInfo) {
      areaInfo = fillAreaValues(areaInfo);
      areaInfo = castToFourDecimalPlaces(areaInfo);
    }

    return of(
      LoadRegionSeasonFieldsSuccess({
        seasonFieldsCropsVarieties: mappedVarieties,
        areaInfo: areaInfo ?? null
      }),
      GetAreaVariables(selectedSeasons)
    );
  };

const mapAreaChildren = (children: Region[], dictSeasonAreas: Record<string, CurrentSeasonArea[]>, selectedSeasons: string[]) => {
  const childrens = children.reduce((accumulator, child) => {
    if (child.type !== RegionType.AREA) {
      accumulator.push(child);
    } else {
      if (child.current_info) {
        child.current_info.crop_ended = dictSeasonAreas
          ? !dictSeasonAreas[child.id]?.some(as => selectedSeasons?.includes(as.seasonId))
          : false;
      }
      accumulator.push(child);
    }
    return accumulator;
  }, [] as Region[]);

  return _.uniqBy(childrens, 'id');
};

export const buildGeometries = (
  rootRegion: Region,
  areaSeasons,
  selectedSeasons: string[],
  choroplethLimits?: Dictionary<ChoroplethLimits>,
  flags: Dictionary<string | boolean> | null = null,
  mapLayersColorDictionary: ColorDictionary = {},
  storageMaxDays?: StorageMaxDaysLimit
): Region => {
  const dictSeasonAreas = _.groupBy(areaSeasons, 'areaId');

  const allRegions = getRegionsChild(rootRegion).map(child => {
    return {
      ...child,
      children: mapAreaChildren(child.children as Region[], dictSeasonAreas, selectedSeasons),
      geometry: getRegionFeatureCollection(
        child,
        dictSeasonAreas,
        selectedSeasons,
        choroplethLimits,
        flags,
        mapLayersColorDictionary,
        storageMaxDays
      )
    };
  });

  const areas = rootRegion.children.filter(child => typeof child === 'object' && child?.type === RegionType.AREA) as Region[];

  const areaChildren = mapAreaChildren(areas, dictSeasonAreas, selectedSeasons);

  return {
    ...rootRegion,
    children: [...areaChildren, ...allRegions] as Region[],
    geometry: getRegionFeatureCollection(
      rootRegion,
      dictSeasonAreas,
      selectedSeasons,
      choroplethLimits,
      flags,
      mapLayersColorDictionary,
      storageMaxDays
    )
  };
};
const dayInUTC = (day?: string) => moment.tz(day, 'UTC');

export const isSetAreaSeasonField = (areaSeasonFieldMap: Record<string, SeasonField>, seasonField: SeasonField): boolean => {
  const seasonFieldInRecord = areaSeasonFieldMap[seasonField.field_id];
  if (!seasonFieldInRecord) return true;

  const seasonFieldInRecordStartDate = dayInUTC(seasonFieldInRecord?.start_date);
  const seasonFieldInRecordEndDate = dayInUTC(seasonFieldInRecord?.end_date);
  const today = dayInUTC();

  const isActivitySeasonFieldInRecord = today.isBetween(seasonFieldInRecordStartDate, seasonFieldInRecordEndDate, 'day', '[]');
  if (isActivitySeasonFieldInRecord) return false;

  const seasonFieldStartDate = dayInUTC(seasonField?.start_date);
  const seasonFieldEndDate = dayInUTC(seasonField?.end_date);

  const isActivitySeasonField = today.isBetween(seasonFieldStartDate, seasonFieldEndDate, 'day', '[]');
  if (isActivitySeasonField) return true;

  const isLaterSeasonField = moment(seasonFieldInRecordStartDate).isBefore(seasonFieldStartDate);
  if (isLaterSeasonField) return true;

  return false;
};
