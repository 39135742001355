import type { Action, UUID } from 'core/utils/basic.models';
import type { LoginError, Oauth2TokenResponse, OrganizationInfo, User, WorkspaceApps } from './auth.models';

export const AuthActionsTypes = {
  LOGIN_REQUEST: '[Auth Service] Request Login',
  LOGIN_SUCCESS: '[Auth Service] Login Success',
  LOGIN_FAILURE: '[Auth Service] Login Failure',

  LOGOUT_REQUEST: '[Auth Service] Logout Request',
  LOGOUT_SUCCESS: '[Auth Service] Logout Success',
  LOGOUT_FAILURE: '[Auth Service] Logout Failure',

  LOAD_ORGANIZATION_INFO: '[Auth Service] Load Organization Info',
  LOAD_ORGANIZATION_INFO_SUCCESS: '[Auth Service] Load Organization Info Success',
  LOAD_ORGANIZATION_INFO_FAILURE: '[Auth Service] Load Organization Info Failure',

  LOAD_LICENSING_STATUS: '[Auth Service] Load Licensing Status',
  LOAD_LICENSING_STATUS_SUCCESS: '[Auth Service] Load Licensing Status Success',
  LOAD_LICENSING_STATUS_FAILURE: '[Auth Service] Load Licensing Status Failure',

  LOAD_CURRENT_USER_SUCCESS: '[Auth Service] Load Current User Success',
  LOAD_CURRENT_USER_FAILURE: '[Auth Service] Load Current User Failure',
  LOAD_CURRENT_USER_ACCOUNT_SERVICE: '[Auth Service] Load Current User Account Service Success',

  LOAD_WORKSPACE_INFO: '[Auth Service] Load Workspace Info',
  LOAD_WORKSPACE_INFO_SUCCESS: '[Auth Service] Load Workspace Info Success',
  LOAD_WORKSPACE_INFO_FAILURE: '[Auth Service] Load Workspace Info Failure',

  LOAD_WORKSPACE_INFO_ACCESS: '[Auth Service] Load Workspace Info Access',
  LOAD_WORKSPACE_INFO_ACCESS_SUCCESS: '[Auth Service] Load Workspace Info Access Success',
  LOAD_WORKSPACE_INFO_ACCESS_FAILURE: '[Auth Service] Load Workspace Info Access Failure',

  LOAD_WORKSPACE_NAME: '[Auth Service] Load Workspace Name',
  LOAD_WORKSPACE_NAME_SUCCESS: '[Auth Service] Load Workspace Name Success',
  LOAD_WORKSPACE_NAME_FAILURE: '[Auth Service] Load Workspace Name Failure',

  LOAD_WORKSPACE_APPS: '[Auth Service] Load Workspace Apps',
  LOAD_WORKSPACE_APPS_SUCCESS: '[Auth Service] Load Workspace Apps Success',
  LOAD_WORKSPACE_APPS_FAILURE: '[Auth Service] Load Workspace Apps Failure',

  SET_PERMISSION_FLAGS: '[Auth Service] Set Permission Flags'
};

export const LoginRequest = (code: string): Action<string> => {
  return {
    type: AuthActionsTypes.LOGIN_REQUEST,
    payload: code
  };
};

export const LoginSuccess = (token: Oauth2TokenResponse) => {
  return {
    type: AuthActionsTypes.LOGIN_SUCCESS,
    payload: token
  };
};

export const LoginFailure = (error: LoginError) => {
  return {
    type: AuthActionsTypes.LOGIN_FAILURE,
    payload: error
  };
};

export const LogoutRequest = () => {
  return {
    type: AuthActionsTypes.LOGOUT_REQUEST
  };
};

export const LogoutSuccess = () => {
  sessionStorage.clear();
  return {
    type: AuthActionsTypes.LOGOUT_SUCCESS
  };
};

export const LogoutFailure = (error: LoginError) => {
  return {
    type: AuthActionsTypes.LOGOUT_FAILURE,
    payload: error
  };
};

export const LoadOrganizationInfoSuccess = (info: OrganizationInfo) => {
  return {
    type: AuthActionsTypes.LOAD_ORGANIZATION_INFO_SUCCESS,
    payload: info
  };
};

export const LoadOrganizationInfoFailure = error => {
  return {
    type: AuthActionsTypes.LOAD_ORGANIZATION_INFO_FAILURE,
    payload: error
  };
};

export const LoadCurrentUserSuccess = (user: User) => {
  return {
    type: AuthActionsTypes.LOAD_CURRENT_USER_SUCCESS,
    payload: user
  };
};

export const LoadCurrentUserAccountService = (isUserAccountService: boolean) => {
  return {
    type: AuthActionsTypes.LOAD_CURRENT_USER_ACCOUNT_SERVICE,
    payload: isUserAccountService
  };
};

export const LoadCurrentUserFailure = () => {
  return {
    type: AuthActionsTypes.LOAD_CURRENT_USER_FAILURE
  };
};

export const LoadWorkspaceInfo = (id: UUID) => {
  return {
    type: AuthActionsTypes.LOAD_WORKSPACE_INFO,
    payload: id
  };
};

export const LoadWorkspaceInfoSuccess = (workspaceId: UUID) => {
  return {
    type: AuthActionsTypes.LOAD_WORKSPACE_INFO_SUCCESS,
    payload: workspaceId
  };
};

export const LoadWorkspaceInfoFailure = (error: string) => {
  return {
    type: AuthActionsTypes.LOAD_WORKSPACE_INFO_FAILURE,
    payload: error
  };
};

export const LoadWorkspaceInfoAccess = (id: UUID) => {
  return {
    type: AuthActionsTypes.LOAD_WORKSPACE_INFO_ACCESS,
    payload: id
  };
};

export const LoadWorkspaceInfoAccessSuccess = (workspaceId: UUID) => {
  return {
    type: AuthActionsTypes.LOAD_WORKSPACE_INFO_ACCESS_SUCCESS,
    payload: workspaceId
  };
};

export const LoadWorkspaceInfoAccessFailure = (error: string) => {
  return {
    type: AuthActionsTypes.LOAD_WORKSPACE_INFO_ACCESS_FAILURE,
    payload: error
  };
};

export const LoadWorkspaceName = (default_licensing_account_id: UUID) => {
  return {
    type: AuthActionsTypes.LOAD_WORKSPACE_NAME,
    payload: default_licensing_account_id
  };
};

export const LoadWorkspaceNameSuccess = (workspaceName: string) => {
  return {
    type: AuthActionsTypes.LOAD_WORKSPACE_NAME_SUCCESS,
    payload: workspaceName
  };
};

export const LoadWorkspaceNameFailure = (error: string) => {
  return {
    type: AuthActionsTypes.LOAD_WORKSPACE_NAME_FAILURE,
    payload: error
  };
};

export const LoadWorkspaceApps = (default_licensing_account_id: UUID) => {
  return {
    type: AuthActionsTypes.LOAD_WORKSPACE_APPS,
    payload: default_licensing_account_id
  };
};

export const LoadWorkspaceAppsSuccess = (workspaceApps: WorkspaceApps) => {
  return {
    type: AuthActionsTypes.LOAD_WORKSPACE_APPS_SUCCESS,
    payload: workspaceApps
  };
};

export const LoadWorkspaceAppsFailure = (error: string) => {
  return {
    type: AuthActionsTypes.LOAD_WORKSPACE_APPS_FAILURE,
    payload: error
  };
};

export const SetPermissionFlags = permissionFlags => {
  return {
    type: AuthActionsTypes.SET_PERMISSION_FLAGS,
    payload: permissionFlags
  };
};
