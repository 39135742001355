import type { AxiosError, AxiosResponse } from 'axios';
import Bluebird from 'bluebird';
import type { Dictionary } from 'config/types';
import { Entitlements } from 'core/shared/enums/entitlements.enum';
import { validatePlansAndEntitlements } from 'core/utils/functions';
import type { LicensingStatusResponse } from 'entities/company/company.models';
import { isAnyBarleyCrop, isAnyWheatCrop, isGrapeCrop, isPotatoCrop } from 'entities/crop/crop.domain';
import type { CurrentSeasonArea } from 'entities/property/property.models';
import { chunk, isEmpty } from 'lodash';
import moment from 'moment-timezone';
import type {
  DailyRisk,
  DiseaseInfo,
  DiseaseRiskConfigurationsUpdatePayload,
  DiseaseRiskFieldData,
  DiseaseRiskGrapesConfigurations,
  DiseaseRiskGrapesConfigurationsPost,
  DiseaseRisksDto,
  FieldDiseaseRiskConfigurations,
  GrapesDiseaseSensitivity,
  RiskBackend,
  RiskFrontend,
  SoilType
} from 'pages/disease-risk/disease-risk.models';
import { Disease, RiskColors } from 'pages/disease-risk/disease-risk.models';
import { useEffect } from 'react';
import sentryService from 'sentry/service';
import type { Crop, DateISOString, UUID } from '../../../core/utils/basic.models';
import type { Region } from '../../../entities/region/region.models';
import { RegionType } from '../../../entities/region/region.models';
import {
  getDiseaseRisksByProperty,
  getDiseaseRisksConfigurationsForCerealsByProperty,
  getDiseaseRisksConfigurationsForGrapesByProperty,
  saveDiseaseRisksConfigurationsForCerealsByField,
  saveDiseaseRisksConfigurationsForGrapesByField
} from '../disease-risk.service';

export interface DiseaseRiskCropsConfigurations {
  grapes: DiseaseRiskGrapesConfigurations[];
  otherCrops: FieldDiseaseRiskConfigurations[];
  errors: DiseaseRiskConfigurationFetchError[];
}

export interface DiseaseRiskConfigurationFetchError {
  fieldId: string;
  fieldName: string | undefined;
  fieldSeeds: string[] | null | undefined;
  plantingDate: string | undefined;
  harvestDate: string | undefined;
  emergenceDate: string | undefined;
  emptyConfiguration: boolean;
  hasError?: boolean;
}

/**
 * Returns a Hex Color by predicted risk level of area
 * @param risk
 * @returns
 */
export const getRiskColor = (risk?: RiskFrontend): RiskColors => {
  switch (risk) {
    case -1:
      return RiskColors.NONE;
    case 0:
      return RiskColors.ACCEPTANCE;
    case 1:
      return RiskColors.CONTROL;
    case 2:
      return RiskColors.DAMAGE;
    default:
      return RiskColors.NO_DATA;
  }
};

/**
 * Returns a Fade Hex Color by predicted risk level of area
 * @param risk
 * @returns
 */

/**
 * Filters all regions that have an ACTIVE crops supported by Disease Risk
 */
export const filterCropTypesForDiseaseRisk = (
  regions: Dictionary<Region>,
  selectedCrops: Crop[],
  license: LicensingStatusResponse | null
): Dictionary<Region> => {
  return Object.values(regions)
    .filter(region => filterRegionTypeForDiseaseRisk(region, selectedCrops, license))
    .reduce<Dictionary<Region>>((acc, region) => {
      return {
        ...acc,
        [region.id]: region
      };
    }, {});
};

/**
 * Returns a parsed list of diseases for a given day from the Disease Risk data retrieved
 * @param day Given day to get diseases from
 * @param data Data obtained from the backend
 */
export function getDiseasesForDay(day: DailyRisk, data: DiseaseRiskFieldData): DailyRisk[] {
  return (
    data.diseases
      ?.flatMap(d =>
        d.dates.map(date => {
          return {
            ...date,
            disease: d.disease
          };
        })
      )
      .filter(d => new Date(d.date).getDate() === new Date(day.date).getDate()) ?? []
  );
}

export const convertBERiskToFERisk = (risk: RiskBackend): RiskFrontend => (risk === 3 ? -1 : risk);

export function getRiskForNextDays(
  data: { dates: { risk: RiskFrontend }[] | { risk: RiskBackend }[] }[],
  nextDays: number,
  isFrontendRisk = false
): RiskFrontend {
  const validatedNextDays = nextDays > 10 ? 10 : nextDays;
  let nextDaysRisk: RiskFrontend = -1;
  data.forEach(disease => {
    const nexDaysRisks = disease.dates.slice(0, validatedNextDays).map(d => {
      return isFrontendRisk ? d.risk : convertBERiskToFERisk(d.risk as RiskBackend);
    });
    const maxDiseaseRisk = Math.max(...nexDaysRisks) as RiskFrontend;
    if (nextDaysRisk < maxDiseaseRisk) {
      nextDaysRisk = maxDiseaseRisk;
    }
  });
  return nextDaysRisk;
}

export function getRiskColorName(risk: number | undefined): string {
  switch (risk) {
    case -1:
      return 'grey';
    case 0:
      return 'green';
    case 1:
      return 'yellow';
    case 2:
      return 'red';
    default:
      return 'undefined';
  }
}

function getDiseaseRiskConfigurationsErrorResponse(fieldId: string): DiseaseRiskConfigurationFetchError {
  const fetchError: DiseaseRiskConfigurationFetchError = {
    fieldId,
    hasError: true,
    emptyConfiguration: true,
    fieldName: undefined,
    fieldSeeds: undefined,
    plantingDate: undefined,
    harvestDate: undefined,
    emergenceDate: undefined
  };
  return fetchError;
}

export function isDiseaseRiskConfigurationFetchError(
  configuration: DiseaseRiskGrapesConfigurations | DiseaseRiskConfigurationFetchError
): configuration is DiseaseRiskConfigurationFetchError {
  return 'emptyConfiguration' in configuration;
}

export function isDiseaseRiskConfiguration<T extends FieldDiseaseRiskConfigurations | DiseaseRiskGrapesConfigurations>(
  configuration: T | DiseaseRiskConfigurationFetchError
): configuration is T {
  return !('hasError' in configuration);
}

/**
 * Returns a Promise array with the fields' configurations
 * @param regions The list of Regions to GET the configurations from
 * @param propertyId The propertyId to GET the configurations from
 */
export async function getFieldConfigurations(regions: Region[], propertyId: string): Promise<DiseaseRiskCropsConfigurations> {
  const fieldsWithGrapes = new Set<UUID>();
  const fieldsWithOtherCrops = new Set<UUID>();

  regions.forEach(region => {
    if (region.seasons.some(season => isGrapeCrop(season.crop))) {
      fieldsWithGrapes.add(region.id);
    } else {
      fieldsWithOtherCrops.add(region.id);
    }
  });

  const grapesResponses = fieldsWithGrapes.size
    ? await getDiseaseRisksConfigurationsForGrapesByProperty(propertyId).catch((error: Error) => {
        sentryService.captureException(error, {
          fileName: 'disease-risk.utils',
          method: 'getFieldConfigurations/getDiseaseRisksConfigurationsForGrapesByProperty'
        });
        return [];
      })
    : [];
  const otherCropsResponses = fieldsWithOtherCrops.size
    ? await getDiseaseRisksConfigurationsForCerealsByProperty(propertyId).catch((error: Error) => {
        sentryService.captureException(error, {
          fileName: 'disease-risk.utils',
          method: 'getFieldConfigurations/getDiseaseRisksConfigurationsForCerealsByProperty'
        });
        return [];
      })
    : [];

  const grapesConfigurations: DiseaseRiskGrapesConfigurations[] = [];
  const otherCropsConfigurations: FieldDiseaseRiskConfigurations[] = [];

  grapesResponses.forEach(response => {
    if (isDiseaseRiskConfiguration(response)) {
      grapesConfigurations.push(response);
      fieldsWithGrapes.delete(response.fieldId);
    }
  });

  otherCropsResponses.forEach(response => {
    if (isDiseaseRiskConfiguration(response)) {
      otherCropsConfigurations.push(response);
      fieldsWithOtherCrops.delete(response.fieldId);
    }
  });

  const errors: DiseaseRiskConfigurationFetchError[] = [...fieldsWithGrapes, ...fieldsWithOtherCrops].map(
    getDiseaseRiskConfigurationsErrorResponse
  );

  return {
    grapes: grapesConfigurations,
    otherCrops: otherCropsConfigurations,
    errors
  };
}

/**
 * Saves the field configuration with the updated Information
 * Returns a Promise Array with the result message of the saving process
 */
interface SaveFieldConfigurationProps {
  regions: UUID[];
  ploughed?: boolean;
  previousCrop?: string;
  soilTexture?: string;
  soilType?: SoilType[];
  antePreviousCrop?: string | null;
  yieldPotential?: number | null;
  nitrogenLevel?: number | null;
}
export async function saveFieldConfigurations({
  regions,
  ploughed,
  previousCrop,
  soilTexture = '',
  soilType,
  antePreviousCrop,
  yieldPotential,
  nitrogenLevel
}: SaveFieldConfigurationProps): Promise<(AxiosResponse<FieldDiseaseRiskConfigurations> | AxiosError)[]> {
  return Bluebird.map(regions, (region: string) => {
    const payload: DiseaseRiskConfigurationsUpdatePayload = {
      ploughed,
      previousCrop,
      soilTexture,
      antePreviousCrop,
      soilType,
      yieldPotential,
      nitrogenLevel
    };
    return saveDiseaseRisksConfigurationsForCerealsByField(region, payload).catch((err: Error) => {
      sentryService.captureException(err, {
        fileName: 'disease-risk.utils',
        method: 'saveDiseaseRiskConfiguration'
      });
      return err;
    });
  }) as Promise<(AxiosResponse<FieldDiseaseRiskConfigurations> | AxiosError)[]>;
}

export async function batchSaveGrapesConfigurations(fieldIds: UUID[], payload: DiseaseRiskGrapesConfigurationsPost): Promise<void> {
  const promises = fieldIds.map(fieldId => saveDiseaseRisksConfigurationsForGrapesByField(fieldId, payload));
  await Promise.all(promises);
}

function mapDiseaseRiskDtoToFieldData(diseaseRiskDto: DiseaseRisksDto): DiseaseRiskFieldData[] {
  return diseaseRiskDto.fields.map<DiseaseRiskFieldData>(field => {
    const { risk, ...rest } = field;
    const riskFiveDays = getRiskForNextDays(field.diseases, 5);

    const customData: DiseaseRiskFieldData = {
      ...rest,
      diseases: field.diseases.map<DiseaseInfo>(disease => ({
        disease: disease.disease,
        risk: convertBERiskToFERisk(disease.risk),
        dates: disease.dates.map<DailyRisk>(date => ({
          ...date,
          risk: convertBERiskToFERisk(date.risk)
        }))
      })),
      riskFieldList: riskFiveDays,
      riskMap: riskFiveDays,
      riskFieldDetails: convertBERiskToFERisk(risk)
    };
    return customData;
  });
}

export async function fetchDiseaseRisksDataByProperty(
  propertyId: UUID,
  fieldIds?: UUID[],
  mockedData?: boolean
): Promise<DiseaseRiskFieldData[]> {
  if (!fieldIds) {
    const response = await getDiseaseRisksByProperty({ propertyId, mockedData });
    return mapDiseaseRiskDtoToFieldData(response);
  }

  const fieldIdBatches = chunk(fieldIds, 10);
  const promises = fieldIdBatches.map(fieldIds => getDiseaseRisksByProperty({ propertyId, fieldIds, mockedData }));

  const responses = await Promise.all(promises);

  const mergedResponse: DiseaseRisksDto = {
    fields: []
  };
  responses.forEach(response => {
    mergedResponse.fields.push(...response.fields);
  });

  return mapDiseaseRiskDtoToFieldData(mergedResponse);
}

/**
 * Hook that alerts clicks outside of the passed ref
 */
export function useOutsideAlerter(ref: React.RefObject<HTMLDivElement>, outsideFunction: () => void): void {
  useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */
    function handleClickOutside(event: MouseEvent) {
      if (ref.current && !ref.current.contains(event.target as Node)) {
        outsideFunction();
      }
    }

    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [ref, outsideFunction]);
}

/**
 * Get weather icon color
 * @param weather : WeatherBody
 * @returns string
 */

export const defineIfRegionHasEndedSeason = ({
  field: region,
  timeZone,
  currentAreaSeasons,
  selectedSeasons
}: {
  field: Region;
  timeZone: string;
  currentAreaSeasons: CurrentSeasonArea[];
  selectedSeasons: string[];
}): boolean => {
  return (
    !!region.current_info?.crop_ended ||
    !currentAreaSeasons.some(
      currentArea =>
        region.id === currentArea.areaId &&
        selectedSeasons.includes(currentArea.seasonId) &&
        moment.tz(moment(), timeZone).isSameOrBefore(moment.tz(currentArea.endsAt, timeZone))
    )
  );
};

export function defineIfRegionIsHarvested(region: Region, timezone = ''): boolean {
  return !!(
    !region?.current_info?.crop_ended &&
    region?.current_info?.harvest_day &&
    moment.tz(moment(), timezone).isSameOrAfter(moment.tz(moment(region.current_info?.harvest_day), timezone))
  );
}

export const filterSelectedCrop = (selectedCrop: Crop, license: LicensingStatusResponse | null): boolean => {
  if (isEmpty(selectedCrop.wk_slug)) {
    return false;
  }

  if (isAnyWheatCrop(selectedCrop)) {
    return validatePlansAndEntitlements(license, null, [Entitlements.DISEASE_RISK_WHEAT]);
  }
  if (isAnyBarleyCrop(selectedCrop)) {
    return validatePlansAndEntitlements(license, null, [Entitlements.DISEASE_RISK_BARLEY]);
  }
  if (isGrapeCrop(selectedCrop)) {
    return validatePlansAndEntitlements(license, null, [Entitlements.DISEASE_RISK_GRAPES]);
  }
  if (isPotatoCrop(selectedCrop)) {
    return validatePlansAndEntitlements(license, null, [Entitlements.DISEASE_RISK_POTATO]);
  }

  return false;
};

export const filterRegionTypeForDiseaseRisk = (region: Region, selectedCrops: Crop[], license: LicensingStatusResponse | null): boolean => {
  return !!(
    region.type === RegionType.AREA &&
    region.seasons
      .flatMap(season => season.crop.id)
      .some(seasonId =>
        selectedCrops
          .filter(selectedCrop => filterSelectedCrop(selectedCrop, license))
          .map(selectedCrop => selectedCrop.id)
          .includes(seasonId)
      )
  );
};

export const filterLevelRiskAndFieldStatus = (diseaseRiskField: DiseaseRiskFieldData, level: number, statusType: string): boolean => {
  return diseaseRiskField.riskFieldList === level && diseaseRiskField.status?.status === statusType;
};

export const getDiseaseTranslationKey = (disease: Disease): string => {
  switch (disease) {
    case Disease.BROWN_RUST:
      return 'pages.disease_risk.brown_rust';
    case Disease.FUSARIUM:
      return 'pages.disease_risk.fusarium';
    case Disease.LEAF_RUST:
      return 'pages.disease_risk.leaf_rust';
    case Disease.NET_BLOTCH:
      return 'pages.disease_risk.net_blotch';
    case Disease.POWDERY_MILDEW:
      return 'pages.disease_risk.powdery_mildew';
    case Disease.RAMULARIA:
      return 'pages.disease_risk.ramularia';
    case Disease.RHYNCHOSPORIUM:
      return 'pages.disease_risk.rhyncho-sporium';
    case Disease.SEPTORIA:
      return 'pages.disease_risk.septoria';
    case Disease.YELLOW_RUST:
      return 'pages.disease_risk.yellow_rust';
    case Disease.EYESPOT:
      return 'pages.disease_risk.eyespot';
    default:
      return 'unsupported disease';
  }
};

export const getGrapesDiseaseSensitivityTranslationKey = (sensitivity: GrapesDiseaseSensitivity | null): string => {
  switch (sensitivity) {
    case 1:
      return 'pages.disease_risk.grapes.disease_sensitivity.low';
    case 2:
      return 'pages.disease_risk.grapes.disease_sensitivity.medium';
    case 3:
      return 'pages.disease_risk.grapes.disease_sensitivity.high';
    default:
      return 'unsupported sensitivity';
  }
};

export const calculateDaysFromCurrentDate = (inputDate: DateISOString | null): number | null => {
  if (!inputDate) return null;
  const parsedDate = moment(inputDate);
  const currentDate = moment();
  return currentDate.diff(parsedDate, 'days');
};
