import classNames from 'classnames';
import STTypo from 'core/shared/typo';
import { ColorOptions, TypeOptions } from 'core/shared/typo/typo.models';
import { isEmpty } from 'lodash';
import type React from 'react';
import { useMemo } from 'react';
import SvgCommentGlyph from 'st-protector-icons/Glyph/CommentGlyph';
import SvgPropertyGlyph from 'st-protector-icons/Glyph/PropertyGlyph';
import SvgTaskGlyph from 'st-protector-icons/Glyph/TaskGlyph';
import { CLASS_NAME_PREFIX } from './area-day-info.events-annotations';

export interface InfoAnnotationProps {
  iconType: 'property' | 'task' | 'comment';
  text: string | GeoJSON.Point;
  iconColor?: string;
}

interface RenderIconProps {
  iconType: 'property' | 'task' | 'comment';
  iconColor?: string;
}

const RenderIcon: React.FC<RenderIconProps> = ({ iconType, iconColor = '#515253' }) => {
  if (iconType === 'property') {
    return <SvgPropertyGlyph data-testid='svg-property-glyph' fill={iconColor} />;
  }

  if (iconType === 'task') {
    return <SvgTaskGlyph data-testid='svg-task-glyph' fill={iconColor} />;
  }

  if (iconType === 'comment') {
    return <SvgCommentGlyph data-testid='svg-comment-glyph' fill={iconColor} />;
  }

  return null;
};

export const InfoAnnotation: React.FC<InfoAnnotationProps> = ({ iconType, text, iconColor }) => {
  const className = classNames({
    [`${CLASS_NAME_PREFIX}__container__info-annotation`]: iconType !== 'comment',
    [`${CLASS_NAME_PREFIX}__container__info-annotation__comment`]: iconType === 'comment'
  });

  const parsedText = useMemo(() => {
    if (!text || isEmpty(text)) return null;

    if (typeof text === 'string') return text;

    const lat = text.coordinates[0].toFixed(6);
    const lng = text.coordinates[1].toFixed(6);

    return `${lat}, ${lng}`;
  }, [text]);

  if (!parsedText) return null;

  return (
    <div data-testid={`info-annotation-container-${iconType}`} className={className}>
      <RenderIcon iconType={iconType} iconColor={iconColor} />
      <STTypo type={TypeOptions.P1} color={ColorOptions.PRIMARY}>
        {parsedText}
      </STTypo>
    </div>
  );
};
