import type { CompanyState, LicensingStatusResponse } from 'entities/company/company.models';
import { getProtectorApp } from 'entities/company/company.selector';
import type { JDOCState } from 'entities/integrations/integrations.models';
import type { CurrentSeasonArea, PropertyInfoAddress, PropertyState, SeasonAreasInDate } from 'entities/property/property.models';
import type { Region, RegionState } from 'entities/region/region.models';
import { selectRegionEntities } from 'entities/region/region.reducer';
import { isActive } from 'entities/season/season.functions';
import type { Dictionary } from 'lodash';
import type { AppState } from 'redux/app-state';
import { createSelector } from 'reselect';
import type { DashboardState, GlobalUiState, Nullable } from './core.models';
import type { PermissionFlagEntitlement, PermissionFlags } from './services/auth/auth.models';
import { Entitlements } from './shared/enums/entitlements.enum';
import type { UUID } from './utils/basic.models';
import { hasEntitlements } from './utils/entitlement';

export type Selector<T> = (state: AppState) => T;
export const getSelectedSeasons: Selector<UUID[]> = state => state.uiState.global.selectedSeasons;
export const getSeverityMapByIssue: Selector<boolean> = state => {
  let hasActiveSeasonSelected = false;
  const allSeasons = state.entities.season.entities || {};
  const selectedSeasons = state.uiState.global.selectedSeasons || [];
  hasActiveSeasonSelected = selectedSeasons.some(selectedSeason => isActive(allSeasons[selectedSeason]));
  return (
    !!state.entities.company.licensingStatus?.apps.find(app =>
      app.plan.entitlements.find(entitlement => entitlement.key === Entitlements.MAPS_SEVERITY_TYPE_PHENOMENON)
    ) && hasActiveSeasonSelected
  );
};

export const getSystemFlags: Selector<GlobalUiState['systemFlags']> = state => state.uiState.global.systemFlags;
export const getPermissionFlags: Selector<PermissionFlags> = (state: AppState) => state.uiState.auth.permissionFlags;

export const getPermissionFlagSimpleTimeline: Selector<PermissionFlagEntitlement['value']> = state =>
  state.uiState.auth.permissionFlags?.simpleTimeline?.value;

export const getPermissionFlagTasksNotificationEmail: Selector<boolean> = state =>
  state.uiState.auth.permissionFlags?.tasksNotificationEmail?.value || false;

export const getPermissionFlagNemaDigital: Selector<PermissionFlagEntitlement['value']> = state =>
  state.uiState.auth.permissionFlags?.nemaDigital?.value || false;

export const getPropertyEntitiesInEntities: Selector<PropertyState['entities']> = state => state.entities.property.entities;

export const getRegionEntitiesInEntities: Selector<RegionState['entities']> = state => state.entities.region.entities;

export const getCompanyEntitiesInEntities: Selector<CompanyState['entities']> = state => state.entities.company.entities;

export const getRegionsErrorsInEntities: Selector<RegionState['error']> = state => state.entities.region.error;

export const getCurrentAreaId: Selector<GlobalUiState['selectedArea']> = state => state.uiState.global.selectedArea;

export const getSelectedPropertyId: Selector<GlobalUiState['selectedProperty']> = state => state.uiState.global.selectedProperty;

export const getIsCurrentInfoLoaded: Selector<RegionState['isCurrentInfoLoaded']> = state => state.entities.region.isCurrentInfoLoaded;

export const getIsCurrentInfoLoading: Selector<RegionState['isCurrentInfoLoading']> = state => state.entities.region.isCurrentInfoLoading;

export const getIsMethodologyLoaded: Selector<RegionState['isMethodologyLoaded']> = state => state.entities.region.isMethodologyLoaded;

export const getEntitiesRegionIsLoading: Selector<RegionState['isLoading']> = state => state.entities.region.isLoading;

export const getIsLoadingCurrentAreaSeasons: Selector<PropertyState['isLoadingCurrentAreaSeasons']> = state =>
  state.entities.property.isLoadingCurrentAreaSeasons;

export const getIsLoadingGeometry: Selector<RegionState['isLoadingGeometry']> = state => state.entities.region.isLoadingGeometry;

export const getGettingUpdateDiff: Selector<JDOCState['gettingUpdateDiff']> = state => state.entities.integrations.jdoc.gettingUpdateDiff;

export const getUpdateDiff: Selector<JDOCState['updateDiff']> = state => state.entities.integrations.jdoc.updateDiff;

export const getSelectedDateRange: Selector<DashboardState['selectedDateRange']> = state =>
  state.uiState.global.dashboardState.selectedDateRange;

export const getChoroplethLimits: Selector<RegionState['choroplethLimits']> = state => state.entities.region.choroplethLimits;

export const getCurrentUnitSystem: Selector<GlobalUiState['currentUnitSystem']> = state => state.uiState.global.currentUnitSystem;

export const getSeasonAreasInDateContent: Selector<SeasonAreasInDate['content']> = state =>
  state.entities.property.seasonAreasInDate?.content;

export const getLicensingStatusApps: Selector<LicensingStatusResponse['apps'] | undefined> = state =>
  state.entities.company.licensingStatus?.apps;

export const getLicensingStatus: Selector<CompanyState['licensingStatus']> = (state: AppState) => state.entities.company.licensingStatus;

export const getLicenseApps: Selector<any> = state => state.entities.company.licensingStatus?.apps;

export const getRegionsDictionary: Selector<Dictionary<Region>> = (state: AppState) => selectRegionEntities(state.entities.region);

export const getPropertyData: Selector<Nullable<PropertyInfoAddress>> = (state: AppState) => state.entities.property.propertyData;

export const getCurrentSeasonFields: Selector<CurrentSeasonArea[]> = (state: AppState) => state.entities.property.currentAreaSeasons;

export const getInterfaceLanguage: Selector<GlobalUiState['interfaceLanguage']> = state => state.uiState.global.interfaceLanguage;

export const getSelectedCompany: Selector<UUID | null> = state => state.uiState.global.selectedCompany;

export const hasSimpleCerealsEntitlement: Selector<boolean> = createSelector(
  getProtectorApp,
  app => !!app && hasEntitlements(app, [Entitlements.SIMPLE_CEREALS])
);

export const selectHasDiseaseRiskLandingPage: Selector<boolean> = createSelector(
  getProtectorApp,
  hasSimpleCerealsEntitlement,
  (app, hasSimpleCereals) => (!!app && hasEntitlements(app, [Entitlements.DISEASE_RISK_LANDING_PAGE])) || hasSimpleCereals
);

export const hasIntegrationFox: Selector<boolean> = createSelector(
  getProtectorApp,
  app => !!app && hasEntitlements(app, [Entitlements.FOX_INTEGRATION_READ_ONLY])
);
