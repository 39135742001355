import { Button, notification } from 'antd';
import { Modal } from 'antdV5';
import GroupInputList from 'components/SD-group-input-list/group-input-list';
import GroupInput from 'components/SD-group-input/group-input';
import MultiSelectCard from 'components/SD-multi-select-card/multi-select-card';
import STTypo from 'core/shared/typo';
import { ColorOptions } from 'core/shared/typo/typo.models';
import { generateUUID } from 'core/utils/functions';
import { isEqual, map, size } from 'lodash';
import type { ConfigurationParameterDTO } from 'pages/borer-risk/borer-risk-map/borer-risk.models';
import { ConfigurationParameterType } from 'pages/borer-risk/borer-risk-map/borer-risk.models';
import { useFieldClusterConfigurationStore } from 'pages/field-clustering/configuration/field-clustering-configuration.zustand';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { StyledConfirmGroupsFooter, StyledListParameters } from '../../parameters.styles';
import type { ListParameterGroups } from '../../parameters.utils';
import { getOptionsByParameter, removeGroup } from '../../parameters.utils';

interface ListTypeParameterProps {
  parameter: ConfigurationParameterDTO;
}

const ListTypeParameter = ({ parameter }: ListTypeParameterProps): JSX.Element => {
  const [t] = useTranslation();
  const { riskParameters, setRiskParameters } = useFieldClusterConfigurationStore();
  const { id } = parameter;
  const defaultGroups = riskParameters?.[id].value as ListParameterGroups;

  const emptyState = {
    0: {
      id: generateUUID(),
      options: []
    }
  };
  const [groups, setGroups] = useState<ListParameterGroups>(defaultGroups || emptyState);
  const [showModal, setShowModal] = useState(false);
  const options = getOptionsByParameter(parameter, defaultGroups || emptyState);

  const onAdd = () => {
    const index = size(groups);
    setGroups({
      ...groups,
      [index]: {}
    });
  };

  const handleSelectedOptions = (groupId: string, selectedOptions: string[]) => {
    const newGroup = {
      id: generateUUID(),
      options: selectedOptions
    };
    setRiskParameters({
      ...riskParameters,
      [id]: {
        type: ConfigurationParameterType.LIST,
        value: {
          ...(riskParameters?.[id]?.value as Record<string, string[]>),
          [groupId]: newGroup
        }
      }
    });

    setGroups({
      ...groups,
      [groupId]: newGroup
    });
  };

  const handleOnClose = (groupId: string) => {
    const newGroups = removeGroup(groups, groupId) as ListParameterGroups;
    setGroups(newGroups);
    setRiskParameters({
      ...riskParameters,
      [id]: {
        type: ConfigurationParameterType.LIST,
        value: newGroups
      }
    });
  };

  const undoCreateIndividualGroups = () => {
    setGroups(emptyState);
    setRiskParameters({
      ...riskParameters,
      [id]: {
        type: ConfigurationParameterType.LIST,
        value: emptyState
      }
    });
  };

  const renderConfirmNotification = () => {
    notification.success({
      message: t('pages.field_clustering.steps.parameters.group_individually_modal.success_title'),
      duration: 5,
      description: (
        <div>
          <STTypo fontFamily='Noto Sans'>
            {t('pages.field_clustering.steps.parameters.group_individually_modal.success_description')}
          </STTypo>
          <StyledConfirmGroupsFooter>
            <Button size='default' onClick={undoCreateIndividualGroups}>
              {t('pages.borer_risk.control_strategy.modal.buttons.undo')}
            </Button>
          </StyledConfirmGroupsFooter>
        </div>
      )
    });
  };
  const createIndividualGroups = () => {
    setShowModal(false);

    const unitaryGroups: ListParameterGroups = options.reduce((newGroups, option, index) => {
      newGroups[index] = {
        id: generateUUID(),
        options: [option.id]
      };
      return newGroups;
    }, {});
    setRiskParameters({
      ...riskParameters,
      [id]: {
        type: ConfigurationParameterType.LIST,
        value: unitaryGroups
      }
    });
    setGroups(unitaryGroups);
    renderConfirmNotification();
  };

  return (
    <StyledListParameters>
      <Button style={{ position: 'absolute', top: -50, right: 24 }} onClick={() => setShowModal(true)}>
        <STTypo fontFamily='Noto Sans' color='#14151C'>
          {t('pages.field_clustering.steps.parameters.group_individually')}
        </STTypo>
      </Button>
      <GroupInputList onAdd={onAdd} containerWidth='300px'>
        {map(groups, (group, index) => (
          <GroupInput
            key={group.id}
            title={t('pages.field_clustering.steps.parameters.group', { index: +index + 1 })}
            onClose={() => handleOnClose(index)}
            hasCloseButton={size(groups) > 1}>
            <MultiSelectCard
              id={`field-clustering-group-${index}`}
              height='220px'
              selectBackgroundColor={ColorOptions.BLUE_60}
              hasBoxShadow
              options={options}
              allowSelectAll
              defaultSelectedOptions={group.options}
              handleSelectedOptions={(selectedOptions: string[]) => handleSelectedOptions(index, selectedOptions)}
            />
          </GroupInput>
        ))}
      </GroupInputList>
      <Modal
        title={t('pages.field_clustering.steps.parameters.group_individually_modal.title')}
        open={showModal}
        onCancel={() => setShowModal(false)}
        onOk={createIndividualGroups}
        okText={
          <STTypo fontFamily='Noto Sans' color='#FFF'>
            {t('pages.field_clustering.steps.parameters.group_individually_modal.ok')}
          </STTypo>
        }
        okButtonProps={{ style: { backgroundColor: '#0071cd', borderColor: '#0071cd' } }}>
        <STTypo fontFamily='Noto Sans'>{t('pages.field_clustering.steps.parameters.group_individually_modal.message')}</STTypo>
      </Modal>
    </StyledListParameters>
  );
};

export default React.memo(ListTypeParameter, isEqual);
