import * as Sentry from '@sentry/react';
import { queryClient } from 'config/react-query';
import { QueryClientProvider, ReactQueryDevtools } from 'core/utils/data-store/tools';
import Infra from 'infra/infra.container';
import type React from 'react';
import { BrowserRouter } from 'react-router-dom';

const toggleButtonRenderProps: React.ButtonHTMLAttributes<HTMLButtonElement> = {
  style: {
    marginBottom: '70px'
  }
};
const App: React.FC = () => {
  return (
    <QueryClientProvider client={queryClient}>
      <ReactQueryDevtools initialIsOpen={false} toggleButtonProps={toggleButtonRenderProps} />
      <BrowserRouter>
        <Infra />
      </BrowserRouter>
    </QueryClientProvider>
  );
};

export default Sentry.withProfiler(App);
