import { selectSystemFlags } from 'core/core.reducer';
import { useGetSugarcaneSeasonPermission, useHasPermissionToAccessInternalRoutesServices } from 'pages/sugarcane/sugarcane.utils';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import type { AppState } from 'redux/app-state';

const useFieldClusteringConfigPermission = () => {
  const { propertyId } = useParams();
  const systemFlags = useSelector((state: AppState) => selectSystemFlags(state));
  const isSugarCane = useGetSugarcaneSeasonPermission();
  const hasUserPermission = useHasPermissionToAccessInternalRoutesServices();

  return !!propertyId && hasUserPermission && isSugarCane && !!systemFlags?.P40_28620_enable_field_cluster;
};

export default useFieldClusteringConfigPermission;
