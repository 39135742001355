import { Popover, Skeleton } from 'antd';
import type { IBreadcrumbPopoverItems } from 'core/components/header/components/breadcrumb/breadcrumb-popover';
import STBreadcrumbPopover from 'core/components/header/components/breadcrumb/breadcrumb-popover';
import { SetCurrentCompany, SetCurrentProperty } from 'core/core.actions';
import STTypo from 'core/shared/typo';
import { ColorOptions, TypeOptions } from 'core/shared/typo/typo.models';
import type { UUID } from 'core/utils/basic.models';
import useSegmentTracking from 'core/utils/segment/useSegmentTracking';
import { getSelectedCompany, selectAllCompanies } from 'entities/company/company.reducer';
import { getLoadingCompany, getSelectedCompanyId } from 'entities/company/company.selector';
import { ClearReports } from 'entities/reports/report.actions';
import useDiseaseRiskStore from 'pages/disease-risk/disease-risk.store';
import type React from 'react';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { generatePath, useMatch, useNavigate } from 'react-router-dom';
import type { AppState } from 'redux/app-state';
import { skeletonParagraph, skeletonTitle } from './constants';

export interface ISTCompanyPopoverProps {
  isEmbedded?: boolean;
}

const STBreadcrumbCompany: React.FC<ISTCompanyPopoverProps> = ({ isEmbedded }) => {
  const segmentTracking = useSegmentTracking();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [t] = useTranslation();
  const companyMatch = useMatch({
    path: '/company/:companyId',
    end: false
  });

  const regionalOverviewMatch = !!useMatch({
    path: '/company/:companyId/regional-overview',
    end: false
  });

  const { resetRegions } = useDiseaseRiskStore();

  const companies = useSelector((state: AppState) => selectAllCompanies(state.entities.company));
  const company = useSelector((state: AppState) => getSelectedCompany(state));
  const selectedCompanyId = useSelector(getSelectedCompanyId);
  const isLoading = useSelector(getLoadingCompany);

  const [isOpen, setIsOpen] = useState(false);
  const [companyLinks, setCompanyLinks] = useState<IBreadcrumbPopoverItems[]>([]);

  const footerClick = () => {
    dispatch(SetCurrentProperty(null));
    dispatch(SetCurrentCompany(null));
    dispatch(ClearReports());
    navigate('/hall-of-companies');
    setIsOpen(false);
    segmentTracking.track('Organization Selector - Tapped to go to Organization Hall');
  };

  const itemClick = (clickedCompanyId: UUID, event?: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    if (companyMatch?.params.companyId === clickedCompanyId) {
      event?.preventDefault();
      setIsOpen(false);
      return;
    }
    resetRegions();
    setIsOpen(false);
    dispatch(SetCurrentProperty(null));
    dispatch(SetCurrentCompany(clickedCompanyId));
    dispatch(ClearReports());
    segmentTracking.track('Organization Selector - Changed Organization');
  };

  const handleIsOpen = (visible: boolean) => {
    if (visible) {
      segmentTracking.track('Organization Selector - Tapped to see options');
    } else {
      segmentTracking.track('Organization Selector - Tapped to hide options');
    }
    setIsOpen(visible);
  };

  useEffect(() => {
    if (!companies.length) return;
    setCompanyLinks(
      companies.map(c => {
        return {
          id: c.id,
          name: c.name,
          path: generatePath(`/company/:companyId`, { companyId: c.id })
        };
      })
    );
  }, [companies]);

  useEffect(() => {
    if (!companyMatch) {
      dispatch(SetCurrentCompany(null));
    } else if (companyMatch?.params?.companyId && companyMatch.params.companyId !== selectedCompanyId) {
      dispatch(SetCurrentCompany(companyMatch.params.companyId));
    }
  }, [dispatch, companyMatch, selectedCompanyId]);

  if (isEmbedded) return null;

  return (
    <Skeleton title={skeletonTitle} paragraph={skeletonParagraph} active loading={isLoading}>
      <Popover
        placement='bottomLeft'
        content={
          <STBreadcrumbPopover
            selected={companyMatch?.params.companyId || undefined}
            items={companyLinks}
            header={t('components.breadcrumb.companies')}
            footer={t('components.breadcrumb.go_to_company_hall')}
            footerClick={footerClick}
            itemClick={itemClick}
            showFooter={companies.length > 1}
          />
        }
        visible={isOpen}
        onVisibleChange={handleIsOpen}
        trigger='click'>
        <div className='sd-header__breadcrumb__item'>
          <div className='st-breadcrumb__text'>
            <div className='sd-header__breadcrumb__item__title'>
              <STTypo type={TypeOptions.C1} color={ColorOptions.SECONDARY}>
                {t('components.breadcrumb.company_title')}
              </STTypo>
            </div>
            <div className='sd-header__breadcrumb__item__name'>
              <STTypo fontWeight={600} maxlength={100}>
                {!regionalOverviewMatch ? company?.name : t('components.breadcrumb.all_organizations')}
              </STTypo>
              <i className='sf sf-chevron-down' />
            </div>
          </div>
        </div>
      </Popover>
    </Skeleton>
  );
};

export default STBreadcrumbCompany;
