import { Tooltip } from 'antd';
import type { Crop } from 'core/utils/basic.models';
import { getCropL10nByCrop } from 'core/utils/crops/crops';
import type React from 'react';
import CropIconUnit from './crop-icon-unit';
import CropIconContainer from './crop-icon.styles';

interface CropIconProps {
  crop?: Crop | Crop[];
  background?: boolean;
  cropEnded?: boolean;
  cropHarvested?: boolean;
  backgroundColor?: string;
  customIcon?: React.ReactElement;
  smallIcon?: boolean;
}

const CropIcon = ({ crop, background = true, cropEnded, cropHarvested, backgroundColor, customIcon, smallIcon }: CropIconProps) => {
  const cropInfo: Crop | undefined = Array.isArray(crop) ? crop[0] : crop;
  const title = getCropL10nByCrop(cropInfo);

  return (
    <Tooltip title={title} data-testid='crop-icon-tooltip'>
      <CropIconContainer
        data-testid='crop-icon-container'
        cropEnded={cropEnded ?? cropHarvested}
        withBackground={background}
        backgroundColor={backgroundColor}
        smallIcon={smallIcon}>
        <CropIconUnit cropInfo={cropInfo} customIcon={customIcon} background={background} />
      </CropIconContainer>
    </Tooltip>
  );
};

export default CropIcon;
