import axios from 'axios';
import { PROTECTOR_API_URL } from 'config/constants';
import { getSelectedSeasons } from 'core/core.selectors';
import type { UUID } from 'core/utils/basic.models';
import { useQuery } from 'core/utils/data-store/tools';
import type { CurrentSeasonArea } from 'entities/property/property.models';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { SeasonQueryType } from './season.query.models';

const protectorApiUrl = PROTECTOR_API_URL;
const TEN_MINUTES = 1000 * 60 * 60;

/** @deprecated v1/season-areas will be deprecated in the future, if possible use POST v2/season-fields/list */
export const getAllSeasonAreasBySeasonsQuery = async (propertyId?: UUID, seasonIds?: UUID[], allowFutureSeasonAreas = false) => {
  if (propertyId && seasonIds && seasonIds.length > 0) {
    const response = await axios.get<CurrentSeasonArea[]>(
      `${protectorApiUrl}/v1/season-areas?seasonIds=${seasonIds.toString()}&propertyId=${propertyId}&allowPastSeasonAreas=true&allowFutureSeasonAreas=${allowFutureSeasonAreas}`
    );
    return response.data;
  }
  return [];
};

/** @deprecated v1/season-areas will be deprecated in the future, if possible use POST v2/season-fields/list */
export const getAllSeasonAreasByProperty = async (propertyId?: string) => {
  if (propertyId) {
    const response = await axios.get<CurrentSeasonArea[]>(`${protectorApiUrl}/v1/season-areas?propertyId=${propertyId}`);
    return response.data;
  }
  return [];
};

export const useGetAllSeasonAreasBySeasons = (allowFutureSeasonAreas?: boolean): CurrentSeasonArea[] | undefined => {
  const { propertyId } = useParams();
  const selectedSeasons = useSelector(getSelectedSeasons);
  const { data } = useQuery([SeasonQueryType.GET_SEASON_AREAS_FROM_SELECTED_SEASONS, selectedSeasons, propertyId], () => {
    return getAllSeasonAreasBySeasonsQuery(propertyId, selectedSeasons, allowFutureSeasonAreas);
  });
  return data;
};

export const useGetAllSeasonAreasByProperty = (enabled = true) => {
  const { propertyId } = useParams();
  const { data } = useQuery(['GET_ALL_SEASON_AREAS_BY_PROPERTY', propertyId], () => getAllSeasonAreasByProperty(propertyId), {
    cacheTime: TEN_MINUTES,
    enabled
  });

  return data;
};
