import type { Dictionary } from 'config/types';
import type { Action, Crop, DateISOString, UUID } from 'core/utils/basic.models';
import type { I18nMap } from 'core/utils/language.models';
import type { ChoroplethLimits } from 'core/utils/map/choropleth.models';
import type { Season, SeasonBudget } from 'entities/season/season.models';
import type { GeoJsonProperties } from 'geojson';
import type moment from 'moment';
import type { Moment } from 'moment';
import type { RiskColors } from 'pages/disease-risk/disease-risk.models';
import type { ScoutingScorePerField } from 'querys/scouting-score/scouting-score.model';
import type { SeasonField as NewSeasonField } from 'querys/season-field/season-field.models';
import type { EntityState } from 'redux-ngrx-entity';
import type { ListMultimap, Nullable } from '../../core/core.models';
import type { TrackingInfo } from '../../core/shared/map/map.models';
import type { SprayInfo } from '../../pages/timeline/spray.models';
import type { Monitoring, PhenologySample, TimelineWindowEvents } from '../../pages/timeline/timeline.models';
import type { Variety } from '../crop/crop.models';
import type { AreaVariableType } from './enums/areaVariablesTypes.enum';

export interface RegionDetailedInfoRequestType {
  regionId: UUID;
  propertyId: UUID;
  seasonIds?: UUID[];
}

export interface ReloadRegionDetailedInfoRequestType {
  regionId: UUID;
  propertyId: UUID;
  seasonIds: UUID[];
  date?: string;
  latestScoutingScore?: ScoutingScorePerField;
}

export interface LoadRegionDetailedInfoSuccessActionType {
  regions: Region[];
  choroplethLimits?: Dictionary<ChoroplethLimits>;
  nextSprayRegionId?: UUID[];
  mapLayersColorDictionary?: ColorDictionary;
  currentInfoRegions?: Record<string, CurrentInfo>;
}

export interface ColorDictionary {
  seedsColors?: Record<string, { color: string; area: number; count: number; cropId: string }>;
  phenologicalStageColors?: Record<string, { color: string; area: number; count: number; cropId: string }>;
  daeColors?: Dictionary<string>;
}

export type LoadRegionDetailedInfoType = ({
  regionId,
  propertyId,
  seasonIds
}: RegionDetailedInfoRequestType) => Action<RegionDetailedInfoRequestType>;

export type LoadRegionSuccessType = (regions: Region[]) => Action<Region[]>;

export enum AreaVariableMassiveStages {
  LOADING_SEASON_AREAS = 'components.area_variable_info.loading-seasons',
  LOADING_AREA_VARIABLES = 'components.area_variable_info.loading-area-variables',
  LOADING_VARIETIES = 'components.area_variable_info.loading-varieties',
  EDITING_AREA_VARIABLES = 'components.area_variable_info.updating-area-variables',
  CREATING_AREA_VARIABLES = 'components.area_variable_info.saving-area-variables',
  DELETING_AREA_VARIABLES = 'components.area_variable_info.deleting-area-variables',
  SAVING_VARIETIES = 'components.area_variable_info.saving-varieties',
  SAVING_DATES = 'components.area_variable_info.saving-dates',
  LOADING_REGION_INFO = 'components.area_variable_info.loading-region-info'
}
export interface RegionState extends EntityState<Region> {
  isLoading: boolean;
  isTableInfoLoading: boolean;
  isLoadingGeometry: boolean;
  isCurrentInfoLoaded: boolean;
  isCurrentInfoLoading: boolean;
  isRegionsLoaded: boolean;
  error: string | null;
  trackings: TrackingInfo | null;
  isTrackingsLoaded: boolean;
  choroplethLimits: Dictionary<ChoroplethLimits>;
  mapLayersColorDictionary?: ColorDictionary;
  latestWindowEvents: TimelineWindowEvents | null;
  isLoadingEvents: boolean;
  /** @deprecated use recursiveRegionsAndSeasonFields instead */
  recursiveRegionsAndSeasonAreas?: AreaInfo;
  isMethodologyLoaded: boolean;
  customVariables: CustomVariable[];
  isLoadingCustomVariables: boolean;
  areaVariables: AreaVariable[];
  areaVariablesPerSeason: Record<string, AreaVariable[]>;
  isLoadingAreaVariables: boolean;
  areaVariableLoadingStage?: AreaVariableMassiveStages;
  isLoadingRegionData: boolean;
  regionData: IRegionDataResponse[];
  fieldsRepresentativeness: ListMultimap<AreaDayRepresentativeness>;
  isLoadingCoverageGroup: boolean;
  seasonAreas: SeasonArea[];
  /** @deprecated use seasonFieldsCropsVarieties instead */
  seasonAreasCropsVarieties: Record<string, Variety[]>;
  severityIndicatorSelected?: UUID;
  nextSprayCurrentInfoFields: UUID[];
  hasPressureIndicator?: boolean;
  shouldStoreScoutingScores?: boolean;
  shouldStoreNematodeDamage?: boolean;
  shouldUpdateColors?: boolean;
  useNewMapColors: boolean;
  currentInfoRegions?: Record<string, CurrentInfo>;
  timelineMapHighlightEnabled: boolean;

  // Season Fields
  recursiveRegionsAndSeasonFields: AreaInfo | null;
  seasonFieldsCropsVarieties: Record<string, Variety[]> | null;
}

export interface SeasonArea {
  seasonAreaId: string;
  areaId: string;
  nameArea: string;
  cropName: string;
  cropId: string;
  areaInHectares: number;
  varieties: VarietySeasonArea[];
  plantingDate: string | null;
  emergencyDate: string | null;
  harvestingDate: string | null;
}

interface VarietySeasonArea {
  name: string;
  id: string;
}

export interface AreaDayRepresentativeness {
  id: UUID;
  day: string;
  coverageGroup: Set<UUID>;
}

export interface LoadWindowEventsRequest {
  property_id: UUID;
  area_id: UUID;
  season_ids: UUID[];
  start: Moment;
  end: Moment;
}

export interface ReloadRegionData {
  regionId: UUID;
  seasonIds?: UUID[];
  ignoreCurrentInfo?: boolean;
}

interface Assignee {
  id: UUID;
  name: string;
  point_ids: UUID[];
}

export interface MonitoringInfo {
  id: UUID;
  coordinates: {
    lat: number;
    lon: number;
  };
  date: DateISOString;
  image: string | null;
  scouter: Assignee;
  comments: string;
  characteristics: any[] | null;
}

export type RegionGeometry = GeoJSON.Feature | GeoJSON.FeatureCollection<null, { current_info?: CurrentInfo; id: UUID }>;

export interface CurrentInfo {
  phenological_stage?: string;
  area_in_hectares: number;
  severity_level: number | null;
  last_spray?: SprayInfo | null;
  next_spray?: SprayInfo | null;
  planting_day?: DateISOString;
  emergence_day?: DateISOString;
  damage_day?: DateISOString;
  harvest_day?: DateISOString;
  last_monitoring?: MonitoringInfo | null;
  last_visit?: DateISOString;
  seeds?: any[] | null;
  crops?: Crop[] | null;
  custom_fields?: any[] | null;
  crop_ended?: boolean;
  season_area_id?: UUID;
}

export enum RegionType {
  REGION = 'REGION',
  AREA = 'AREA'
}

export interface GeometryProperties {
  id: UUID;
  name: string;
  parent_id: UUID | null;
  parents: UUID[];
  type: RegionType;
  total_area: number;
  is_season_active: boolean;
  current_info?: CurrentInfo;
  severityLevelColor?: string;
  indicatorPressureColor?: string;
  indicator_pressure?: string;
  noData?: boolean;
  riskColor?: RiskColors;
  daysWithoutSprayColor?: string;
  daysWithoutMonitoringColor?: string;
  seedColor?: string;
  phenologicalStageColor?: string;
  phenologicalStage?: string;
  dae?: number;
  daeColor?: string;
  hasSprayAfterMonitoring?: boolean;
  scoutingScore?: number;
  scoutingScoreColor?: string;
  scouters?: UUID[];
  seed?: string;
  highlight?: boolean;
}

export type RegionWithParents = Region & { parents: UUID[] };

export interface Region {
  id: UUID;
  name: string;
  parent_id: UUID | null;
  type: RegionType;
  children: UUID[] | Region[];
  total_area: number;
  calculated_area: number;
  seasons: Season[];

  current_info?: CurrentInfo;
  geometry?: RegionGeometry;
  seasonBudget?: SeasonBudget[];
  color?: string;
  seed?: string[];
  varieties?: Variety[];
  json_extended_attributes: Record<string, unknown>;
}
export interface AreaInfo {
  key: string;
  name: string;
  area: number;
  crop?: string;
  variety?: string;
  planting_date?: DateISOString;
  emergency_date?: DateISOString;
  harvest_date?: DateISOString;
  areaVariables?: AreaVariable[];
  children?: AreaInfo[];
  [key: `area_variable_${string}`]: number;
}

export enum SeasonAreaUpdateType {
  EMERGENCY_DATE = 'EmergencyDate',
  VARIETIES = 'Varieties',
  HARVESTING_DATE = 'HarvestingDate',
  PLANTING_DATE = 'PlantingDate'
}

export interface RegionMonitoringReport {
  start_date: DateISOString;
  end_date: DateISOString;
  monitoring_window: Monitoring;
}

export interface AreaVariable {
  class_name: AreaVariableType;
  description: I18nMap;
  id: UUID;
  key: string;
  name: I18nMap;
  season_area_id: UUID;
  uom: string;
  value: number | string;
}

export interface CustomVariable {
  class_name: AreaVariableType;
  company_id: UUID;
  created_at: string;
  crop_id: UUID;
  deleted_at: string | null;
  description: {
    localized_strings: Dictionary<string>;
  };
  id: UUID;
  key: string;
  modified_at: string;
  name: {
    localized_strings: Dictionary<string>;
  };
  value: number;
}

/** @deprecated use SeasonField from querys/season-field/season-field.models instead */
export interface SeasonField {
  id: UUID;
  season_id: UUID;
  property_id: UUID;
  deleted: boolean;
  varieties_ids: UUID[];
  planting_date: string;
  harvesting_date: string;
  emergency_date: string;
  start_date: string;
  end_date: string;
  created_at: string;
  updated_at: string;
}

export interface Field {
  id: string;
  name: string;
  property_id: string;
  parent_region_id: string;
  geometry: RegionGeometry;
  declared_area: number;
  calculated_area: number;
  tags: string[];
  updated_at: Nullable<string>;
  deleted_at: Nullable<string>;
  json_extended_attributes: Record<string, unknown>;
}

export interface V1Region {
  createdAt: string;
  deletedAt: string;
  id: UUID;
  name: string;
  parentId: UUID | null;
  propertyId: string;
}

export interface IPayloadRegionData {
  propertyId: UUID;
  companyId: UUID;
  regionsId: UUID[];
}

export interface IRegionDataResponse {
  region_id: UUID;
  last_event: string;
}

export interface GetAreaDayRepresentativenessRequest {
  propertyId: UUID;
  areaId: UUID;
  representedPointsIds: UUID[];
  day: moment.Moment;
  areaPointsIds: UUID[];
}

export interface UpdateFeaturePropertiesParams {
  feature: GeoJSON.Feature;
  regionUpdatesIds: Set<string>;
  regionGeometryPropertiesMap: Record<string, GeoJsonProperties>;
}

export interface LoadRegionSeasonFieldsPayload {
  propertyId: UUID;
  companyId: UUID;
  update?: boolean;
}

export interface LoadRegionSeasonFieldsPayloadSuccess {
  seasonFieldsCropsVarieties: Record<string, Variety[]> | null;
  areaInfo: AreaInfo | null;
}

export interface GetVarietiesNamesFromSeasonFieldByRegionSeasonsParams {
  varieties: Record<string, Variety[]>;
  seasons?: Dictionary<Season>;
  seasonField?: NewSeasonField;
}

export interface BuildAreaInfoBySeasonFieldProps {
  regionChildrenMapping: Record<string, Region[]>;
  areaSeasonFieldMap: Record<string, NewSeasonField>;
  varieties: Record<string, Variety[]>;
  seasons: Dictionary<Season>;
  region: Region;
}

export enum AreaVariableInputType {
  NUMERIC = 'numeric',
  TEXT = 'text',
  DATE = 'date'
}

export interface DeletePhenologyPayloadType {
  sample: PhenologySample;
}
