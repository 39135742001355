import type { Feature } from '@turf/turf';
import { notification } from 'antd';
import i18n from 'config/i18n';
import type { Dictionary } from 'config/types';
import { Threshold } from 'core/core.models';
import { selectSelectedProperty, selectSelectedSeasons } from 'core/core.reducer';
import { getSelectedSeasons, getSystemFlags } from 'core/core.selectors';
import {
  addNematodeDamageToRegionProperties,
  addScoutingScoreToRegionProperties,
  getRegionAreasWithParents,
  getTrackingTotalDistanceAndTime,
  sliceTrackingRoute,
  updateRegionsHighlightStatus,
  updateSeedAndPhenologyColorsToRegionProperties
} from 'core/shared/map/map.functions';
import type { TrackingInfo } from 'core/shared/map/map.models';
import type { Action, Indicator, UUID } from 'core/utils/basic.models';
import { ColorManager } from 'core/utils/functions';
import {
  getLimitsChoropleth,
  getPhenologicalStageColor,
  getSeedsChoropleth,
  getSortedPhenologicalStageColor,
  getSortedSeedsChoropleth,
  getStoregeDaysLimit
} from 'core/utils/map/choropleth';
import type { Variety } from 'entities/crop/crop.models';
import { getCropVarieties } from 'entities/crop/crop.service';
import { extractActiveSeasonsIds, filterSeasonsActive } from 'entities/season/season.functions';
import { getSelectedInactiveSeasonEndDate } from 'entities/season/season.reducer';
import { getSeasonEntitiesInEntities } from 'entities/season/season.selectors';
import _ from 'lodash';
import { normalize, schema } from 'normalizr';
import { filterCropIdsFromSeasonBySeasonFields } from 'pages/timeline/area-info/hooks/use-area-info-drawer-variety-data.functions';
import type { AnnotationStage, Monitoring, RequestTracking, Sample, TimelineWindowEvents } from 'pages/timeline/timeline.models';
import { EventType } from 'pages/timeline/timeline.models';
import { deletePhenology, getTrackings, getWindowEvents } from 'pages/timeline/timeline.service';
import type { SeasonField } from 'querys/season-field/season-field.models';
import { getSeasonFieldFromPropertyBySeasonIdsObservable } from 'querys/season-field/season-field.service';
import type { ActionsObservable, Epic, StateObservable } from 'redux-observable';
import { ofType } from 'redux-observable';
import type { AppState } from 'redux/app-state';
import type { Observable } from 'rxjs';
import { concat, forkJoin, of } from 'rxjs';
import { catchError, concatMap, exhaustMap, map, mergeMap, takeUntil, tap, withLatestFrom } from 'rxjs/operators';
import { GetAreasDiffJDOCFailure } from '../integrations/integrations.actions';
import { getSelectedProperty } from '../property/property.reducer';
import type { DeletePhenology, EditSeasonAreaDatesMassively, EditSeasonAreaVarietiesMassively } from './region.actions';
import {
  CreateAreaVariablesMassivelyFailure,
  DeleteAreaVariableFailure,
  DeleteCustomVariableFailure,
  DeleteCustomVariableSuccess,
  DeletePhenologyError,
  DeletePhenologySuccess,
  EditAreaVariablesMassivelyFailure,
  EditSeasonAreaDatesMassivelyFailure,
  EditSeasonAreaVarietiesMassivelyFailure,
  GetAreaDayRepresentativenessSuccess,
  GetAreaVariableFailure,
  GetAreaVariableSuccess,
  GetAreaVariables,
  GetAreaVariablesSuccess,
  GetCustomVariableFailure,
  GetCustomVariableSuccess,
  GetRegionDataSuccess,
  LoadCurrentInfosByFieldsFailure,
  LoadCurrentInfosByFieldsSuccess,
  LoadRegion,
  LoadRegionDetailedInfo,
  LoadRegionDetailedInfoFailure,
  LoadRegionDetailedInfoSuccess,
  LoadRegionFailure,
  LoadRegionSeasonAreas,
  LoadRegionSeasonAreasSuccess,
  LoadRegionSeasonFields,
  LoadRegionSeasonFieldsFailed,
  LoadRegionSuccess,
  LoadTrackingFailure,
  LoadTrackingSuccess,
  LoadWindowEventsFailure,
  LoadWindowEventsSuccess,
  PostAreaVariableFailure,
  PostAreaVariableSuccess,
  PostCustomVariableFailure,
  PostCustomVariableSuccess,
  RegionActionsTypes,
  RegionAlreadyLoaded,
  ReloadRegionDetailedInfo,
  SetTimelineMapHighlightFilterSuccess,
  UpdateAreaVariableFailure,
  UpdateAreaVariableSuccess,
  UpdateCustomVariableFailure,
  UpdateCustomVariableSuccess,
  UpdateRegionNematodeDemageSuccess,
  UpdateRegionScoutingScoreFailure,
  UpdateRegionScoutingScoreSuccess,
  UpdateRegionSeedAndPhenologyColorsSucess
} from './region.actions';
import { buildGeometries, getCropsByIds, isSetAreaSeasonField, loadRegionSeasonFieldsSuccess } from './region.epics.utils';
import type {
  AreaInfo,
  AreaVariable,
  ColorDictionary,
  CurrentInfo,
  DeletePhenologyPayloadType,
  GetAreaDayRepresentativenessRequest,
  IPayloadRegionData,
  LoadWindowEventsRequest,
  Region,
  RegionDetailedInfoRequestType,
  ReloadRegionData,
  ReloadRegionDetailedInfoRequestType,
  SeasonArea
} from './region.models';
import { SeasonAreaUpdateType } from './region.models';
import { selectRegionDictionaryByCurrentSeason, selectRegionEntities, selectRootRegion } from './region.reducer';
import { getParentRegion, getRegions } from './region.selectors';
import type { UpdateSeasonAreasMassivelyPayload } from './region.service';
import {
  createAreaVariablesMassively,
  deleteAreaVariable,
  deleteCustomVariable,
  editAreaVariablesMassively,
  getAreaVariable,
  getCurrentInfosByFieldIdsPaginated,
  getCustomVariable,
  getPropertyCurrentInfo,
  getRegion,
  getRegionData,
  getRegionSeasonAreas,
  postAreaVariable,
  postCustomVariable,
  updateAreaVariable,
  updateCustomVariable,
  updateSeasonAreasMassively
} from './region.service';
import RegionsUtil, {
  buildAreaInfo,
  castToFourDecimalPlaces,
  fillAreaValues,
  getDateEditPayloadFromEditField,
  getRegionChildrenMapping,
  rootRegionWithChildren
} from './region.utils';

const normalizeRegion = (region: Region): Dictionary<Region> => {
  const regionEntity = new schema.Entity('regions');
  const children = new schema.Array(regionEntity);
  regionEntity.define({ children });
  const normalizedData = normalize(region, regionEntity) as any;
  return normalizedData.entities.regions;
};

export const handleLoadRegionSeasonAreas: Epic = (
  action$: ActionsObservable<ReturnType<typeof LoadRegionSeasonAreas>>,
  state$: StateObservable<AppState>
) =>
  action$.pipe(
    ofType(RegionActionsTypes.LOAD_REGION_SEASON_AREA),
    map(action => action.payload),
    withLatestFrom(state$.pipe(map(getSelectedSeasons)), state$.pipe(map(getRegions)), state$.pipe(map(getParentRegion))),
    concatMap(([actionPayload, selectedSeasons, regions, parentRegion]) => {
      if (!parentRegion) {
        throw new Error('No parent region.');
      }

      const regionChildrenMapping = getRegionChildrenMapping(regions);

      return getRegionSeasonAreas(parentRegion.id).pipe(
        mergeMap(seasonAreas => {
          const areaSeasonAreaMap: Record<string, SeasonArea> = {};
          const cropIds: string[] = [];
          seasonAreas.forEach(seasonArea => {
            areaSeasonAreaMap[seasonArea.areaId] = seasonArea;
            if (!cropIds.includes(seasonArea.cropId)) {
              cropIds.push(seasonArea.cropId);
            }
          });
          let areaInfo: AreaInfo = buildAreaInfo(parentRegion, regionChildrenMapping, areaSeasonAreaMap);

          areaInfo = fillAreaValues(areaInfo);
          areaInfo = castToFourDecimalPlaces(areaInfo);

          return forkJoin(
            cropIds.map(crop =>
              getCropVarieties({
                companyId: actionPayload.companyId,
                cropId: crop
              })
            )
          ).pipe(
            mergeMap(varieties => {
              const cropVarietiesMap: Record<string, Variety[]> = {};
              cropIds.forEach((crop, idx) => {
                const filteredVarieties = varieties[idx].content;
                cropVarietiesMap[crop] = filteredVarieties;
              });

              return of(LoadRegionSeasonAreasSuccess(areaInfo, seasonAreas, cropVarietiesMap), GetAreaVariables(selectedSeasons));
            })
          );
        })
      );
    })
  );

export const handleLoadRegionSeasonFields = (
  action$: ActionsObservable<ReturnType<typeof LoadRegionSeasonFields>>,
  state$: StateObservable<AppState>
) =>
  action$.pipe(
    ofType(RegionActionsTypes.LOAD_REGION_SEASON_FIELD),
    map(action => action.payload),
    withLatestFrom(
      state$.pipe(map(getRegions)),
      state$.pipe(map(getParentRegion)),
      state$.pipe(map(getSeasonEntitiesInEntities)),
      state$.pipe(map(getSelectedSeasons))
    ),
    concatMap(([payload, regions, parentRegion, seasons, selectedSeasons]) => {
      if (!parentRegion) {
        return of(LoadRegionSeasonFieldsFailed());
      }

      return getSeasonFieldFromPropertyBySeasonIdsObservable({
        propertyIds: [payload.propertyId],
        seasonIds: selectedSeasons,
        update: payload.update
      }).pipe(
        mergeMap(seasonFields => {
          const regionChildrenMapping = getRegionChildrenMapping(regions);

          const areaSeasonFieldMap: Record<string, SeasonField> = {};
          seasonFields?.forEach(seasonField => {
            const isSetFieldInRecord = isSetAreaSeasonField(areaSeasonFieldMap, seasonField);
            if (isSetFieldInRecord) {
              areaSeasonFieldMap[seasonField.field_id] = seasonField;
            }
          });

          const cropIds = filterCropIdsFromSeasonBySeasonFields(seasons, seasonFields);

          return forkJoin(getCropsByIds(cropIds, payload.companyId)).pipe(
            mergeMap(
              loadRegionSeasonFieldsSuccess({
                regionChildrenMapping,
                region: parentRegion,
                areaSeasonFieldMap,
                selectedSeasons,
                seasons,
                cropIds
              })
            )
          );
        }),
        catchError(() => of(LoadRegionSeasonFieldsFailed()))
      );
    })
  );

export const handleEditAreaVariablesMassively: Epic = (action$, state$) =>
  action$.pipe(
    ofType(RegionActionsTypes.EDIT_AREA_VARIABLES_MASSIVELY),
    map(action => action.payload),
    withLatestFrom(state$.pipe(map(getSelectedSeasons))),
    mergeMap(([action, selectedSeasons]) => {
      const areaVariables = action.seasonAreas.map(
        (el): AreaVariable => ({
          ...action.areaVariable,
          season_area_id: el
        })
      );
      return editAreaVariablesMassively(areaVariables, action.propertyId).pipe(
        tap(() => notification.success({ message: i18n.t('components.area_variable_info.save-success') })),
        map(() => GetAreaVariables(selectedSeasons)),
        catchError(() => {
          notification.error({ message: i18n.t('components.area_variable_info.error-save') });
          return of(EditAreaVariablesMassivelyFailure());
        })
      );
    })
  );

export const handleSaveAreaVariablesMassively: Epic = (action$, state$) =>
  action$.pipe(
    ofType(RegionActionsTypes.CREATE_AREA_VARIABLES_MASSIVELY),
    map(action => action.payload),
    withLatestFrom(state$.pipe(map(getSelectedSeasons))),
    mergeMap(([action, selectedSeasons]) => {
      const areaVariables = action.seasonAreas.map(
        (el): AreaVariable => ({
          ...action.areaVariable,
          season_area_id: el
        })
      );
      return createAreaVariablesMassively(areaVariables, action.propertyId).pipe(
        tap(() => notification.success({ message: i18n.t('components.area_variable_info.save-success') })),
        map(() => GetAreaVariables(selectedSeasons)),
        catchError(() => {
          notification.error({ message: i18n.t('components.area_variable_info.error-save') });
          return of(CreateAreaVariablesMassivelyFailure());
        })
      );
    })
  );

export const handleEditDatesMassively = (action$: ActionsObservable<ReturnType<typeof EditSeasonAreaDatesMassively>>) =>
  action$.pipe(
    ofType(RegionActionsTypes.EDIT_SEASON_AREA_DATES_MASSIVELY),
    map(action => action.payload),
    mergeMap(payload => {
      const { seasonAreas, field, value, companyId } = payload;
      const formattedDate = value.format('YYYY-MM-DDTHH:mm:ssZZ');

      const payloadEditField = getDateEditPayloadFromEditField(field, formattedDate, seasonAreas);

      return updateSeasonAreasMassively(payloadEditField).pipe(
        tap(() => notification.success({ message: i18n.t('components.area_variable_info.save-success') })),
        map(() => {
          return LoadRegionSeasonAreas(companyId);
        }),
        catchError(() => {
          notification.error({ message: i18n.t('components.area_variable_info.error-save') });
          return of(EditSeasonAreaDatesMassivelyFailure());
        })
      );
    })
  );

export const handleEditVarietiesMassively = (
  action$: ActionsObservable<ReturnType<typeof EditSeasonAreaVarietiesMassively>>,
  state$: StateObservable<AppState>
) =>
  action$.pipe(
    ofType(RegionActionsTypes.EDIT_SEASON_AREA_VARIETIES_MASSIVELY),
    map(action => action.payload),
    withLatestFrom(state$.pipe(map(getSelectedProperty)), state$.pipe(map(getSystemFlags))),
    mergeMap(([payload, property, flags]) => {
      const { seasonAreas, varieties, companyId, selectedSeasons } = payload;

      const requestPayload: UpdateSeasonAreasMassivelyPayload = {
        model: {
          varieties
        },
        seasonIds: seasonAreas,
        fieldToUpdate: SeasonAreaUpdateType.VARIETIES
      };

      return updateSeasonAreasMassively(requestPayload).pipe(
        tap(() => notification.success({ message: i18n.t('components.area_variable_info.save-success') })),
        map(() => {
          if (!flags?.P40_34492_REGION_EPIC_SEASON_FIELDS) {
            return LoadRegionSeasonAreas(companyId);
          }

          if (!property || !companyId || !selectedSeasons?.length) {
            return LoadRegionSeasonFieldsFailed();
          }

          return LoadRegionSeasonFields({ propertyId: property.id, companyId, update: true });
        }),
        catchError(() => {
          notification.error({ message: i18n.t('components.area_variable_info.error-save') });
          return of(EditSeasonAreaVarietiesMassivelyFailure());
        })
      );
    })
  );

export const handleLoadRegion = (action$: Observable<Action<UUID>>, state$: Observable<AppState>) =>
  action$.pipe(
    ofType(RegionActionsTypes.LOAD_REGION),
    map(action => action.payload),
    withLatestFrom(
      state$.pipe(map(getSelectedProperty)),
      state$.pipe(map(state => selectRegionEntities(state.entities.region))),
      state$.pipe(map(state => state.entities.property)),
      state$.pipe(map(state => state.uiState.global)),
      state$.pipe(map(getSelectedInactiveSeasonEndDate))
    ),
    exhaustMap(
      ([
        props,
        selectedProperty,
        regionsEntities,
        { currentAreaSeasons, seasonAreasFromActiveSeasons },
        { selectedSeasons, systemFlags },
        inactiveSeasonEndDate
      ]) => {
        const propertyId = props.propertyId ? props.propertyId : selectedProperty.id;
        const seasons = selectedProperty?.seasons || [];
        const activeSeasonIds = filterSeasonsActive(seasons).map(s => s.id);
        const seasonIds = props?.seasonIds || activeSeasonIds;
        const useCurrentSeasonAreas =
          _.isEqual(new Set(activeSeasonIds), new Set(selectedSeasons)) && seasonAreasFromActiveSeasons[propertyId]?.length;
        if (!regionsEntities[props.regionId]) {
          return getRegion(
            propertyId,
            seasons.filter(s => seasonIds.includes(s.id)),
            inactiveSeasonEndDate,
            seasonAreasFromActiveSeasons[propertyId],
            useCurrentSeasonAreas
          ).pipe(
            takeUntil(action$.pipe(ofType(RegionActionsTypes.RELOAD_REGION))),
            concatMap(recursiveRegion => {
              const recursiveRegionWithGeometry = buildGeometries(
                recursiveRegion,
                currentAreaSeasons,
                selectedSeasons,
                undefined,
                systemFlags
              );
              const normalizdRegions = normalizeRegion(recursiveRegionWithGeometry);
              return concat([
                LoadRegionSuccess(Object.values(normalizdRegions)),
                LoadRegionDetailedInfo({ regionId: props.regionId, propertyId, seasonIds })
              ]);
            }),
            catchError(error => of(LoadRegionFailure(error)))
          );
        }
        return of(RegionAlreadyLoaded());
      }
    )
  );

export const handleReloadRegion = (action$: Observable<Action<ReloadRegionData>>, state$: Observable<AppState>) =>
  action$.pipe(
    ofType(RegionActionsTypes.RELOAD_REGION),
    map(action => {
      return action.payload;
    }),
    withLatestFrom(
      state$.pipe(map((state: AppState) => selectSelectedProperty(state.uiState.global))),
      state$.pipe(map((state: AppState) => selectRegionEntities(state.entities.region))),
      state$.pipe(map((state: AppState) => state.entities.property)),
      state$.pipe(map((state: AppState) => state.uiState.global)),
      state$.pipe(map(getSelectedInactiveSeasonEndDate))
    ),
    concatMap(
      ([
        reloadRegionData,
        selectedPropertyId,
        regionsEntities,
        { currentAreaSeasons, seasonAreasFromActiveSeasons, entities: properties },
        { selectedSeasons, systemFlags },
        inactiveSeasonEndDate
      ]) => {
        if (!selectedPropertyId) return of();
        if (!reloadRegionData?.seasonIds) of(LoadRegion(reloadRegionData));
        if (!regionsEntities[reloadRegionData?.regionId]) {
          const { seasonIds } = reloadRegionData;
          const seasons = properties[selectedPropertyId]?.seasons || [];
          const activeSeasonIds = extractActiveSeasonsIds(seasons);
          const useSeasonAreasFromActiveSeasons =
            _.isEqual(new Set(activeSeasonIds), new Set(seasonIds)) && seasonAreasFromActiveSeasons[selectedPropertyId]?.length;
          const getGeometriesBySeasonFieldEndDate = systemFlags?.P40_23669_geometriesBySeasonFieldEndDate;

          return getRegion(
            selectedPropertyId,
            seasons.filter(s => seasonIds.includes(s.id)),
            inactiveSeasonEndDate,
            seasonAreasFromActiveSeasons[selectedPropertyId],
            useSeasonAreasFromActiveSeasons,
            getGeometriesBySeasonFieldEndDate
          ).pipe(
            takeUntil(action$.pipe(ofType(RegionActionsTypes.RELOAD_REGION))),
            concatMap(recursiveRegion => {
              const recursiveRegionWithGeometry = buildGeometries(
                recursiveRegion,
                currentAreaSeasons,
                selectedSeasons,
                undefined,
                systemFlags
              );
              const normalizdRegions = normalizeRegion(recursiveRegionWithGeometry);
              return !reloadRegionData.ignoreCurrentInfo
                ? concat([
                    LoadRegionSuccess(Object.values(normalizdRegions)),
                    ReloadRegionDetailedInfo({
                      regionId: reloadRegionData.regionId,
                      propertyId: selectedPropertyId,
                      seasonIds: reloadRegionData.seasonIds
                    })
                  ])
                : of(LoadRegionSuccess(Object.values(normalizdRegions)));
            }),
            catchError(error => of(LoadRegionFailure(error)))
          );
        }
        return of(RegionAlreadyLoaded());
      }
    )
  );

export const handleReloadRegionDetailedInfo = (action$, state$) =>
  action$.pipe(
    ofType(RegionActionsTypes.RELOAD_REGION_DETAILED_INFO),
    map((action: Action<ReloadRegionDetailedInfoRequestType>) => action.payload),
    withLatestFrom(
      state$.pipe(map((state: AppState) => state.entities.property.currentAreaSeasons)),
      state$.pipe(map((state: AppState) => state.uiState.global.selectedSeasons)),
      state$.pipe(map((state: AppState) => state.entities.property.propertyData)),
      state$.pipe(map((state: AppState) => state.entities.region.entities)),
      state$.pipe(map((state: AppState) => selectRootRegion(state)?.id)),
      state$.pipe(map((state: AppState) => state.uiState.global.systemFlags))
    ),
    concatMap(([reloadRegionData, currentAreaSeasons, selectedSeasonsIds, propertyData, regions, rootRegionId, systemFlags]) => {
      return getPropertyCurrentInfo(reloadRegionData.regionId, reloadRegionData.propertyId, reloadRegionData.seasonIds).pipe(
        map((currentInfoRegions: Record<string, CurrentInfo>) => {
          const newRegions = Object.keys(currentInfoRegions).reduce<Record<string, Region>>((acc, currentRegionId) => {
            if (acc[currentRegionId]) acc[currentRegionId].current_info = currentInfoRegions[currentRegionId];
            return acc;
          }, regions);
          const rootRegion = rootRegionWithChildren(newRegions, rootRegionId);

          const storageMaxDays = getStoregeDaysLimit();

          const choroplethLimits = getLimitsChoropleth(
            rootRegion.children as Region[],
            propertyData,
            currentAreaSeasons,
            selectedSeasonsIds,
            storageMaxDays
          );
          const seedsColors = systemFlags?.P40_29700_show_statistic_legend
            ? getSortedSeedsChoropleth(currentInfoRegions)
            : getSeedsChoropleth(currentInfoRegions);
          const phenologicalStageColors = systemFlags?.P40_29700_show_statistic_legend
            ? getSortedPhenologicalStageColor(currentInfoRegions, {})
            : getPhenologicalStageColor(currentInfoRegions);
          const mapLayersColorDictionary: ColorDictionary = {
            seedsColors,
            phenologicalStageColors
          };
          const recursiveRegionWithGeometry = buildGeometries(
            rootRegion,
            currentAreaSeasons,
            selectedSeasonsIds,
            choroplethLimits,
            systemFlags,
            mapLayersColorDictionary,
            storageMaxDays
          );
          const normalizedRegions = normalizeRegion(recursiveRegionWithGeometry);

          return LoadRegionDetailedInfoSuccess({
            regions: Object.values(normalizedRegions),
            choroplethLimits: getLimitsChoropleth(normalizedRegions, propertyData, currentAreaSeasons, selectedSeasonsIds, storageMaxDays),
            mapLayersColorDictionary,
            currentInfoRegions
          });
        }),
        catchError(error => of(LoadRegionDetailedInfoFailure(error)))
      );
    })
  );

const sortSamples = (monitoring: Monitoring): Monitoring => {
  // Sort Indicators
  // Just sort by absolute value

  const compareIndicators = (a: Indicator, b: Indicator) => {
    const thresholdValues = status =>
      ({
        [Threshold.DAMAGE]: 3,
        [Threshold.CONTROL]: 2,
        [Threshold.ACCEPTANCE]: 1
      }[status] || 0);

    const statusA = thresholdValues(a.status);
    const statusB = thresholdValues(b.status);

    if (statusA > statusB) return -1;
    if (statusB > statusA) return 1;

    if (a.double_value < b.double_value || a.value < b.value) {
      return 1;
    }
    if (a.double_value > b.double_value || a.value > b.value) {
      return -1;
    }

    return a.name.localeCompare(b.name);
  };
  // Sort Phenomena
  const compareSamples = (a: Sample, b: Sample) => {
    // Get state and max value of a phenomenon
    // state could be: 1 - Acceptance / 2 - Control / 3 - Damage
    const getPriorities = (sample: Sample) => {
      // Starts with Acceptance as state and 0 as max value
      let state = 1;
      let maxValue = 0;
      sample.phenomenon.indicators.forEach((indicator: Indicator) => {
        // If this phenomenon isn't already marked as "Damage" but the current indicator is in "Control"
        if (indicator.status === 'CONTROL' && state < 3) {
          state = 2;
        }
        if (indicator.status === 'DAMAGE') {
          state = 3;
        }
        // This maybe isn't necessary, because indicators are sorted
        if (indicator.value > maxValue || indicator.double_value > maxValue) {
          maxValue = indicator.value ? indicator.value : indicator.double_value;
        }
      });
      return [state, maxValue];
    };
    const [aState, aMaxValue] = getPriorities(a);
    const [bState, bMaxValue] = getPriorities(b);
    // If they're in the same state, sort by value, otherwise sort by state
    // "Damage" < "Control" < "Acceptance"
    if (aState === bState) {
      if (aMaxValue < bMaxValue) {
        return 1;
      }
      if (aMaxValue > bMaxValue) {
        return -1;
      }
      // If they have the same maxValue, sort by name
      return a.phenomenon.name.localeCompare(b.phenomenon.name);
    }
    if (aState > bState) {
      return -1;
    }
    if (aState < bState) {
      return 1;
    }
    return 0;
  };
  // Sort indicators of each phenomenon
  const samplesWithSortedIndicators = monitoring.samples.map((sample: Sample) => {
    return {
      ...sample,
      phenomenon: {
        ...sample.phenomenon,
        indicators: [...sample.phenomenon.indicators].sort(compareIndicators)
      }
    };
  });
  // Sort samples
  const sortedSamples = [...samplesWithSortedIndicators].sort(compareSamples);
  return {
    ...monitoring,
    samples: sortedSamples
  };
};

const parsedDataToAnnotations = (annotation: AnnotationStage, flag): AnnotationStage => {
  return {
    ...annotation,
    ...(flag
      ? {
          medias: annotation.medias
        }
      : {})
  };
};

export const handleLoadWindowEvents = (action$, state$) =>
  action$.pipe(
    ofType(RegionActionsTypes.LOAD_LATEST_WINDOW_EVENTS),
    map((action: Action<LoadWindowEventsRequest>) => action.payload),
    withLatestFrom(state$.pipe(map(getSystemFlags))),
    concatMap(([request, flags]) => {
      return getWindowEvents(request.property_id, request.area_id, request.season_ids, request.start, request.end).pipe(
        map(response => {
          const enableMultiplePhotosNotes = flags?.P40_24588_enableMultiplePhotosNotes;

          if (!response.data) {
            return LoadWindowEventsFailure('Error loading events');
          }

          const windowData: TimelineWindowEvents = {
            startDate: request.start,
            endDate: request.end,
            annotation_window: response.data.annotation_window && {
              ...response.data.annotation_window,
              annotations: response.data.annotation_window.annotations.map(annotation =>
                parsedDataToAnnotations(annotation, enableMultiplePhotosNotes)
              ),
              type: EventType.ANNOTATION
            },
            monitoring_window: response.data.monitoring_window
              ? sortSamples({
                  ...response.data.monitoring_window,
                  type: EventType.MONITORING
                })
              : undefined,
            phenology_window: response.data.phenology_window && {
              ...response.data.phenology_window,
              type: EventType.PHENOLOGY
            },
            spray_window: response.data.spray_window && {
              ...response.data.spray_window,
              type: EventType.APPLICATION
            },
            areaId: request.area_id
          };
          return LoadWindowEventsSuccess(windowData);
        }),
        takeUntil(action$.pipe(ofType(RegionActionsTypes.CANCEL_LOAD_WINDOW_EVENTS))),
        catchError(() => of(LoadWindowEventsFailure('Error loading events')))
      );
    })
  );

export const handleLoadAreaDayRepresentativeness: Epic = action$ => {
  return action$.pipe(
    ofType(RegionActionsTypes.GET_AREA_DAY_REPRESENTATIVENESS),
    map((action: Action<GetAreaDayRepresentativenessRequest>) => action.payload),
    concatMap(({ monitoring, day, areaId, representedPointsIds, areaPointsIds }) => {
      const areasIds = new Set<UUID>(
        monitoring.filter(m => representedPointsIds.includes(m.id) || areaPointsIds.includes(m.represented_by)).map(m => m.area_id)
      );
      return of(GetAreaDayRepresentativenessSuccess({ id: areaId, day, coverageGroup: areasIds }));
    })
  );
};

export const handleLoadRegionDetailedInfo = (action$, state$) =>
  action$.pipe(
    ofType(RegionActionsTypes.LOAD_REGION_DETAILED_INFO),
    map((action: Action<RegionDetailedInfoRequestType>) => action.payload),
    withLatestFrom(
      state$.pipe(map((state: AppState) => selectSelectedSeasons(state.uiState.global))),
      state$.pipe(map((state: AppState) => state.entities.property.currentAreaSeasons)),
      state$.pipe(map((state: AppState) => state.uiState.global.selectedSeasons)),
      state$.pipe(map((state: AppState) => state.entities.property.propertyData)),
      state$.pipe(map((state: AppState) => state.entities.region.entities)),
      state$.pipe(map((state: AppState) => selectRootRegion(state)?.id)),
      state$.pipe(map((state: AppState) => state.uiState.global.systemFlags))
    ),
    concatMap<any, any>(([propss, seasons, currentAreaSeasons, selectedSeasonsIds, propertyData, regions, rootRegionId, systemFlags]) => {
      const { regionId, propertyId, seasonIds } = propss;
      const listSeasons = seasonIds || seasons;

      return getPropertyCurrentInfo(regionId, propertyId, listSeasons).pipe(
        map(currentInfoRegions => {
          const newRegions = Object.keys(currentInfoRegions).reduce<Record<string, Region>>((acc, currentRegionId) => {
            return {
              ...acc,
              [currentRegionId]: {
                ...acc[currentRegionId],
                current_info: currentInfoRegions[currentRegionId]
              }
            };
          }, regions);
          const rootRegion = rootRegionWithChildren(newRegions, rootRegionId);
          const seedsColors = systemFlags?.P40_29700_show_statistic_legend
            ? getSortedSeedsChoropleth(currentInfoRegions)
            : getSeedsChoropleth(currentInfoRegions);
          const phenologicalStageColors = systemFlags?.P40_29700_show_statistic_legend
            ? getSortedPhenologicalStageColor(currentInfoRegions, {})
            : getPhenologicalStageColor(currentInfoRegions);
          const mapLayersColorDictionary: ColorDictionary = {
            seedsColors,
            phenologicalStageColors
          };

          const storageMaxDays = getStoregeDaysLimit();

          const choroplethLimits = getLimitsChoropleth(
            rootRegion.children as Region[],
            propertyData,
            currentAreaSeasons,
            selectedSeasonsIds,
            storageMaxDays
          );
          const recursiveRegionWithGeometry = buildGeometries(
            rootRegion,
            currentAreaSeasons,
            selectedSeasonsIds,
            choroplethLimits,
            null,
            mapLayersColorDictionary
          );
          const normalizedRegions = normalizeRegion(recursiveRegionWithGeometry);

          return LoadRegionDetailedInfoSuccess({
            regions: Object.values(normalizedRegions),
            choroplethLimits: getLimitsChoropleth(normalizedRegions, propertyData, currentAreaSeasons, selectedSeasonsIds, storageMaxDays),
            mapLayersColorDictionary,
            currentInfoRegions
          });
        }),
        catchError(error => of(LoadRegionDetailedInfoFailure(error)))
      );
    })
  );

export const handleLoadFieldsCurrentInfoBatch = (action$, state$) => {
  return action$.pipe(
    ofType(RegionActionsTypes.LOAD_CURRENT_INFOS_BY_FIELD_IDS),
    map((action: any) => action.payload),
    withLatestFrom(
      state$.pipe(map((state: AppState) => selectRegionDictionaryByCurrentSeason(state))),
      state$.pipe(map((state: AppState) => state.entities.property.currentAreaSeasons)),
      state$.pipe(map((state: AppState) => state.uiState.global.selectedSeasons)),
      state$.pipe(map((state: AppState) => state.entities.property.propertyData))
    ),
    concatMap(([{ propertyId, fieldIds, seasonIds, selectedRegion }, regions, currentAreaSeasons, selectedSeasons, propertyData]) => {
      return getCurrentInfosByFieldIdsPaginated(propertyId, fieldIds, seasonIds).pipe(
        map(currentInfos => {
          const currentInfoMap = Object.fromEntries(currentInfos.flat().map(c => [c.field_id, c.current_info]));
          const selectedRegionNormalized = regions[selectedRegion];
          const selectedRegionDeep = RegionsUtil.toDeepRegion(selectedRegionNormalized, currentInfoMap, regions);
          const childAreas = getRegionAreasWithParents(selectedRegionDeep);
          const recursiveRegionWithGeometry = buildGeometries(
            selectedRegionDeep,
            currentAreaSeasons,
            selectedSeasons,
            getLimitsChoropleth(childAreas, propertyData, currentAreaSeasons, selectedSeasons)
          );
          const normalizedRegions = normalizeRegion(recursiveRegionWithGeometry);
          return LoadCurrentInfosByFieldsSuccess(Object.values(normalizedRegions));
        }),
        catchError(error => of(LoadCurrentInfosByFieldsFailure(error)))
      );
    })
  );
};

export const handleUpdateRegionsProperties: Epic = (action$, state$) => {
  return action$.pipe(
    ofType(RegionActionsTypes.UPDATE_REGION_SCOUTING_SCORE),
    map((action: any) => action.payload),
    withLatestFrom(
      state$.pipe(map((state: AppState) => state.entities.region.entities)),
      state$.pipe(map((state: AppState) => state.uiState.global.selectedRegion))
    ),
    concatMap(([latestScoutingScores, regions, selectedRegionId]) => {
      const rootRegion = rootRegionWithChildren(regions, selectedRegionId);
      const newRegions = {
        ...rootRegion,
        geometry: addScoutingScoreToRegionProperties(rootRegion, latestScoutingScores)
      };
      const normalizedRegions = normalizeRegion(newRegions);
      return of(UpdateRegionScoutingScoreSuccess({ regions: Object.values(normalizedRegions) }));
    }),
    catchError(() => of(UpdateRegionScoutingScoreFailure('Error updating scouting score')))
  );
};

export const handleUpdateDaysMapColors = (action$, state$) => {
  return action$.pipe(
    ofType(RegionActionsTypes.UPDATE_REGION_DAYS_MAP_COLORS),
    withLatestFrom(
      state$.pipe(map((state: AppState) => state.entities.region.entities)),
      state$.pipe(map((state: AppState) => state.entities.property.currentAreaSeasons)),
      state$.pipe(map((state: AppState) => state.uiState.global.selectedSeasons)),
      state$.pipe(map((state: AppState) => state.entities.property.propertyData)),
      state$.pipe(map((state: AppState) => selectRootRegion(state)?.id)),
      state$.pipe(map((state: AppState) => state.entities.region.currentInfoRegions)),
      state$.pipe(map((state: AppState) => state.uiState.global.systemFlags))
    ),
    concatMap(([type, regions, currentAreaSeasons, selectedSeasonsIds, propertyData, rootRegionId, currentInfoRegions, systemFlags]) => {
      const newRegions = Object.keys(currentInfoRegions).reduce<Record<string, Region>>((acc, currentRegionId) => {
        if (acc[currentRegionId]) acc[currentRegionId].current_info = currentInfoRegions[currentRegionId];
        return acc;
      }, regions);
      const rootRegion = rootRegionWithChildren(newRegions, rootRegionId);

      const storageMaxDays = getStoregeDaysLimit();

      const choroplethLimits = getLimitsChoropleth(
        rootRegion.children as Region[],
        propertyData,
        currentAreaSeasons,
        selectedSeasonsIds,
        storageMaxDays
      );
      const seedsColors = systemFlags?.P40_29700_show_statistic_legend
        ? getSortedSeedsChoropleth(currentInfoRegions)
        : getSeedsChoropleth(currentInfoRegions);
      const phenologicalStageColors = systemFlags?.P40_29700_show_statistic_legend
        ? getSortedPhenologicalStageColor(currentInfoRegions, {})
        : getPhenologicalStageColor(currentInfoRegions);
      const mapLayersColorDictionary: ColorDictionary = {
        seedsColors,
        phenologicalStageColors
      };
      const recursiveRegionWithGeometry = buildGeometries(
        rootRegion,
        currentAreaSeasons,
        selectedSeasonsIds,
        choroplethLimits,
        systemFlags,
        mapLayersColorDictionary,
        storageMaxDays
      );
      const normalizedRegions = normalizeRegion(recursiveRegionWithGeometry);

      return of(
        LoadRegionDetailedInfoSuccess({
          regions: Object.values(normalizedRegions),
          choroplethLimits: getLimitsChoropleth(normalizedRegions, propertyData, currentAreaSeasons, selectedSeasonsIds, storageMaxDays),
          mapLayersColorDictionary,
          currentInfoRegions
        })
      );
    })
  );
};
export const handleUpdateSeedAndPhenologyColors = (action$, state$) => {
  return action$.pipe(
    ofType(RegionActionsTypes.UPDATE_REGION_SEED_AND_PHENOLOGY_COLORS),
    map((action: any) => action.payload),
    withLatestFrom(
      state$.pipe(map((state: AppState) => state.entities.region.entities)),
      state$.pipe(map((state: AppState) => state.uiState.global.selectedRegion)),
      state$.pipe(map((state: AppState) => selectRootRegion(state)?.id)),
      state$.pipe(map((state: AppState) => state.entities.region.currentInfoRegions))
    ),
    concatMap(([phenologyStagesByCropAndName, regions, selectedRegionId, rootRegionId, currentInfoRegions]) => {
      const rootRegion = rootRegionWithChildren(regions, rootRegionId || selectedRegionId);

      const seedsColors = getSortedSeedsChoropleth(currentInfoRegions);
      const phenologicalStageColors = getSortedPhenologicalStageColor(currentInfoRegions, phenologyStagesByCropAndName);
      const mapLayersColorDictionary: ColorDictionary = {
        seedsColors,
        phenologicalStageColors
      };

      const newRegions = {
        ...rootRegion,
        geometry: updateSeedAndPhenologyColorsToRegionProperties(rootRegion, mapLayersColorDictionary)
      };
      const normalizedRegions = normalizeRegion(newRegions);
      return of(
        UpdateRegionSeedAndPhenologyColorsSucess({
          regions: Object.values(normalizedRegions),
          mapLayersColorDictionary
        })
      );
    })
  );
};

export const handleUpdateRegionsNematodeDamage: Epic = (action$, state$) => {
  return action$.pipe(
    ofType(RegionActionsTypes.UPDATE_REGION_NEMATODE_DAMAGE),
    map((action: any) => action.payload),
    withLatestFrom(
      state$.pipe(map((state: AppState) => state.entities.region.entities)),
      state$.pipe(map((state: AppState) => state.uiState.global.selectedRegion))
    ),
    concatMap(([nemadigitalReport, regions, selectedRegionId]) => {
      const rootRegion = rootRegionWithChildren(regions, selectedRegionId);
      const newRegions = {
        ...rootRegion,
        geometry: addNematodeDamageToRegionProperties(rootRegion, nemadigitalReport)
      };
      const normalizedRegions = normalizeRegion(newRegions);
      return of(UpdateRegionNematodeDemageSuccess({ regions: Object.values(normalizedRegions) }));
    })
  );
};

export const handleSetTimelineFilter = (action$, state$) => {
  return action$.pipe(
    ofType(RegionActionsTypes.TIMELINE_MAP_HIGHLIGHT_FILTER),
    map((action: any) => action.payload),
    withLatestFrom(
      state$.pipe(map((state: AppState) => state.entities.region.entities)),
      state$.pipe(map((state: AppState) => state.uiState.global.selectedRegion))
    ),
    concatMap(([highlightFilter, regions, selectedRegionId]) => {
      const rootRegion = rootRegionWithChildren(regions, selectedRegionId);
      const newRegions = {
        ...rootRegion,
        geometry: updateRegionsHighlightStatus(rootRegion, highlightFilter)
      };
      const normalizedRegions = normalizeRegion(newRegions);
      return of(SetTimelineMapHighlightFilterSuccess({ regions: Object.values(normalizedRegions) }));
    })
  );
};

/**
 * @deprecated dead code to be removed on P40-36388
 */
export const handleLoadTrackingsMapbox = action$ =>
  action$.pipe(
    ofType(RegionActionsTypes.LOAD_TRACKINGS_MAPBOX),
    map((action: Action<RequestTracking>) => action.payload),
    concatMap((requestParams: RequestTracking) => {
      return getTrackings(requestParams).pipe(
        map(assignees => {
          const slicedTrackings = getTrackingTotalDistanceAndTime({ assignees }, requestParams.selectedArea.geometry as Feature);
          const colorManager = new ColorManager();
          const newTrackingData: TrackingInfo = {
            slicedTrackings,
            trackings: assignees.map(assignee => {
              return {
                label: assignee.name,
                startDate: requestParams.query.since,
                endDate: requestParams.query.until,
                mutableVisible: true,
                coordinates: sliceTrackingRoute(assignee.route, assignee.segments, requestParams.selectedArea.geometry, true),
                color: colorManager.getColor(assignee.id)
              };
            })
          };
          return LoadTrackingSuccess(newTrackingData);
        }),
        takeUntil(action$.pipe(ofType(RegionActionsTypes.CANCEL_LOAD_TRACKING))),
        catchError(() => of(LoadTrackingFailure('Error Loading tracking')))
      );
    })
  );

export const handleGetAreaVariable = action$ =>
  action$.pipe(
    ofType(RegionActionsTypes.GET_AREA_VARIABLE),
    map((action: Action) => action.payload),
    concatMap((payload: UUID) => {
      return getAreaVariable(payload).pipe(
        map(response => {
          return GetAreaVariableSuccess(response);
        }),
        catchError(error => of(GetAreaVariableFailure(error)))
      );
    })
  );

export const handleGetAreaVariables: Epic = action$ =>
  action$.pipe(
    ofType(RegionActionsTypes.GET_AREA_VARIABLES),
    map((action: Action) => action.payload),
    concatMap(({ seasonIds }: { seasonIds: string[] }) => {
      return forkJoin(seasonIds.map(seasonId => getAreaVariable(seasonId))).pipe(
        map(responses => {
          const areaVariablesPerSeasonArea: Record<string, AreaVariable[]> = {};
          seasonIds.forEach((seasonId, idx) => {
            areaVariablesPerSeasonArea[seasonId] = responses[idx];
          });
          return GetAreaVariablesSuccess(areaVariablesPerSeasonArea);
        })
      );
    })
  );

export const handlePostAreaVariable = (action$, state$) =>
  action$.pipe(
    ofType(RegionActionsTypes.POST_AREA_VARIABLE),
    map((action: Action) => action.payload),
    withLatestFrom(state$.pipe(map((state: AppState) => selectSelectedProperty(state.uiState.global)))),
    mergeMap(([payload, selectedProperty]) => {
      return postAreaVariable(selectedProperty, payload).pipe(
        map(response => {
          return PostAreaVariableSuccess(response);
        }),
        catchError(error => {
          if (error.response?.data?.message?.includes('already present')) {
            notification.error({ message: i18n.t('components.area_variable_info.error-duplicated-variable') });
          } else {
            notification.error({ message: i18n.t('components.area_variable_info.error-save') });
          }
          return of(PostAreaVariableFailure(error));
        })
      );
    })
  );

export const handleUpdateAreaVariable = (action$, state$) =>
  action$.pipe(
    ofType(RegionActionsTypes.UPDATE_AREA_VARIABLE),
    map((action: Action) => action.payload),
    withLatestFrom(state$.pipe(map((state: AppState) => selectSelectedProperty(state.uiState.global)))),
    mergeMap(([payload, selectedProperty]) => {
      return updateAreaVariable(selectedProperty, payload).pipe(
        map(response => {
          return UpdateAreaVariableSuccess(response);
        }),
        catchError(error => of(UpdateAreaVariableFailure(error)))
      );
    })
  );

export const handleDeleteAreaVariable = (action$, state$) =>
  action$.pipe(
    ofType(RegionActionsTypes.DELETE_AREA_VARIABLE),
    map((action: Action) => action.payload),
    withLatestFrom(state$.pipe(map(getSelectedSeasons))),
    mergeMap(([payload, selectedSeasons]: any) => {
      return deleteAreaVariable(payload.areaVariables, payload.companyId, payload.propertyId).pipe(
        mergeMap(() => {
          notification.success({ message: i18n.t('components.area_variable_info.delete-success') });
          return of(GetAreaVariables(selectedSeasons));
        }),
        catchError(() => {
          notification.error({ message: i18n.t('components.area_variable_info.error-delete') });
          return of(DeleteAreaVariableFailure(''));
        })
      );
    })
  );

export const handleGetCustomVariable = action$ =>
  action$.pipe(
    ofType(RegionActionsTypes.GET_CUSTOM_VARIABLE),
    map((action: Action) => action.payload),
    concatMap((payload: UUID) => {
      return getCustomVariable(payload).pipe(
        map(response => {
          return GetCustomVariableSuccess(response.content);
        }),
        catchError(error => of(GetCustomVariableFailure(error)))
      );
    })
  );

export const handleUpdateCustomVariable = action$ =>
  action$.pipe(
    ofType(RegionActionsTypes.UPDATE_CUSTOM_VARIABLE),
    map((action: Action) => action.payload),
    mergeMap(payload => {
      return updateCustomVariable(payload).pipe(
        map(response => {
          return UpdateCustomVariableSuccess(response);
        }),
        catchError(error => of(UpdateCustomVariableFailure(error)))
      );
    })
  );

export const handlePostCustomVariable = action$ =>
  action$.pipe(
    ofType(RegionActionsTypes.POST_CUSTOM_VARIABLE),
    map((action: Action) => action.payload),
    mergeMap(payload => {
      return postCustomVariable(payload).pipe(
        map(response => {
          return PostCustomVariableSuccess(response);
        }),
        catchError(error => of(PostCustomVariableFailure(error)))
      );
    })
  );

export const handleDeleteCustomVariable = action$ =>
  action$.pipe(
    ofType(RegionActionsTypes.DELETE_CUSTOM_VARIABLE),
    map((action: Action) => action.payload),
    mergeMap((payload: UUID) => {
      return deleteCustomVariable(payload).pipe(
        map(() => {
          return DeleteCustomVariableSuccess(payload);
        }),
        catchError(error => of(DeleteCustomVariableFailure(error)))
      );
    })
  );

export const handleGetRegionData = action$ =>
  action$.pipe(
    ofType(RegionActionsTypes.GET_REGION_DATA),
    map((action: Action) => action.payload),
    mergeMap((payload: IPayloadRegionData) => {
      return getRegionData(payload).pipe(
        map(result => {
          return GetRegionDataSuccess(result);
        }),
        catchError(error => of(GetAreasDiffJDOCFailure(error)))
      );
    })
  );

export const handleDeletePhenology = ($action: Observable<ReturnType<typeof DeletePhenology>>) =>
  $action.pipe(
    ofType(RegionActionsTypes.DELETE_PHENOLOGY),
    map((action: Action<DeletePhenologyPayloadType>) => action.payload),
    mergeMap(payload => {
      if (!payload) {
        return of(DeletePhenologyError('Payload not provided'));
      }

      const { sample } = payload;

      return deletePhenology(sample.id).pipe(
        mergeMap(() => {
          notification.success({
            message: i18n.t('pages.timeline.phenology.delete_sample.message'),
            description: i18n.t('pages.timeline.phenology.delete_sample.success_description')
          });

          return of(DeletePhenologySuccess(sample));
        }),
        catchError((error: string) => {
          notification.error({
            message: i18n.t('pages.timeline.phenology.delete_sample.message'),
            description: i18n.t('pages.timeline.phenology.delete_sample.error_description')
          });

          return of(DeletePhenologyError(error));
        })
      );
    })
  );
