import NumericInput from 'components/SD-numeric-input/numeric-input';
import STTypo from 'core/shared/typo';
import { ColorOptions } from 'core/shared/typo/typo.models';
import type React from 'react';
import { useTranslation } from 'react-i18next';
import { StyledInputWrapper, StyledNumericInput, StyledRangeInputContent } from './range-input.styles';

export interface RangeInputProps {
  defaultMinValue?: number;
  defaultMaxValue?: number;
  rangeUnit?: string;
  min?: number;
  max?: number;
  onChangeMinValue: (val?: number) => void;
  onChangeMaxValue: (val?: number) => void;
}

const RangeInput: React.FC<RangeInputProps> = ({
  rangeUnit,
  min = 0,
  max = 100,
  defaultMinValue,
  defaultMaxValue,
  onChangeMinValue,
  onChangeMaxValue
}: RangeInputProps) => {
  const [t] = useTranslation();

  return (
    <StyledRangeInputContent data-testid='range-input'>
      <STTypo>{t('components.range-input.from')}</STTypo>
      <StyledInputWrapper>
        <StyledNumericInput>
          <NumericInput
            min={min}
            max={max}
            defaultValue={defaultMinValue}
            backgroundColor={ColorOptions.WHITE}
            borderColor={ColorOptions.NEUTRAL_30}
            hoveredBorderColor={ColorOptions.BLUE_60}
            onChange={onChangeMinValue}
            addAfter={rangeUnit}
            size='default'
            placeholder='--'
          />
        </StyledNumericInput>
      </StyledInputWrapper>
      <STTypo>{t('components.range-input.until')}</STTypo>
      <StyledInputWrapper>
        <StyledNumericInput>
          <NumericInput
            min={min}
            max={max}
            defaultValue={defaultMaxValue}
            backgroundColor={ColorOptions.WHITE}
            borderColor={ColorOptions.NEUTRAL_30}
            hoveredBorderColor={ColorOptions.BLUE_60}
            onChange={onChangeMaxValue}
            addAfter={rangeUnit}
            size='default'
            placeholder='--'
          />
        </StyledNumericInput>
      </StyledInputWrapper>
    </StyledRangeInputContent>
  );
};

export default RangeInput;
