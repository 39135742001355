import axios from 'axios-observable';
import { PROTECTOR_API_URL } from 'config/constants';
import type { UUID, V4Page } from 'core/utils/basic.models';
import { chunk } from 'lodash';
import { EMPTY, forkJoin, of, type Observable } from 'rxjs';
import { concatAll, concatMap, expand, map, reduce } from 'rxjs/operators';
import { getObservables } from '../../core/rxjs-utils';
import type { Product } from './product.models';

const protectorApiUrl = PROTECTOR_API_URL;
const productUrl = `${protectorApiUrl}/api/v1/companies`;
const productByIdUrl = `${protectorApiUrl}/api/v1/products/ids`;

export function getProductTransactions(companyId: UUID, productId: UUID, page: number) {
  return axios
    .get(`${protectorApiUrl}/v1/companies/${companyId}/products/${productId}/transactions?page=${page}&size=${99999}`)
    .pipe(map(response => response.data));
}

export const getAllProductsPaginated = (companyId: UUID, pageSize: number, isParallel: boolean): Observable<V4Page<Product>> => {
  const firstPage$ = getProductsByCompanyIdPaged(companyId, 0, pageSize);
  return isParallel ? parallelProductLoad(firstPage$, companyId, pageSize) : serialProductLoad(firstPage$, companyId, pageSize);
};

export const getAllProductsWithMemoization = (() => {
  const cache = new Map<string, V4Page<Product>>();

  return (companyId: UUID, pageSize: number, isParallel: boolean): Observable<V4Page<Product>> => {
    if (cache.has(companyId)) {
      return of(cache.get(companyId)!);
    }

    return getAllProductsPaginated(companyId, pageSize, isParallel).pipe(
      map(response => {
        cache.set(companyId, response);
        return response;
      })
    );
  };
})();

const serialProductLoad = (
  firstProductPage$: Observable<V4Page<Product>>,
  companyId: UUID,
  pageSize: number
): Observable<V4Page<Product>> => {
  return firstProductPage$.pipe(
    expand(response => (!response.last ? getProductsByCompanyIdPaged(companyId, response.number + 1, pageSize) : EMPTY)),
    reduce(reduceProductPages),
    map(response => mapProductPages(response, companyId))
  );
};

const parallelProductLoad = (
  firstProductPage$: Observable<V4Page<Product>>,
  companyId: UUID,
  pageSize: number
): Observable<V4Page<Product>> => {
  return firstProductPage$.pipe(
    concatMap(firstPage => {
      const firstPage$ = of(firstPage);
      if (firstPage.total_pages === 1) {
        return firstPage$;
      }
      const remainingPages$ = new Array(firstPage.total_pages - 1)
        .fill(0)
        .map((_, page) => getProductsByCompanyIdPaged(companyId, page + 1, pageSize));
      return forkJoin([firstPage$, ...remainingPages$]).pipe(concatAll(), reduce(reduceProductPages));
    }),
    map(response => mapProductPages(response, companyId))
  );
};

const reduceProductPages = (acc: V4Page<Product>, current: V4Page<Product>) => {
  acc.content.push(...current.content);
  return acc;
};

const mapProductPages = (response: V4Page<Product>, companyId: string) => {
  return {
    ...response,
    companyId: companyId
  };
};

function getProductsByCompanyIdPaged(companyId: UUID, page = 0, size = 99999): Observable<V4Page<Product>> {
  const url = `${productUrl}/${companyId}/products?size=${size}&page=${page}`;

  return axios.get<V4Page<Product>>(url).pipe(
    map(response => {
      return response.data;
    })
  );
}

export const getProductsByIds = (ids: UUID[]): Observable<Product[]> => {
  const requests = chunk(ids, 1000).map(chunk => {
    return axios.post<Product[]>(productByIdUrl, { ids: chunk }).pipe(
      map(response => {
        return response.data;
      })
    );
  });
  return getObservables(requests, true).pipe(
    map(response => {
      return response.flat(1);
    })
  );
};
