import { useDidMount } from 'beautiful-react-hooks';
import useVersionStore from './version.store';
import rawVersion from 'VERSION?raw';

const useVersion = (): string | undefined => {
  const { setVersion, version } = useVersionStore();

  useDidMount(() => {
    if (!version) setVersion(String(rawVersion));
  });

  return version;
};

export { useVersion as default };
